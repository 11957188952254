import isNumber from 'lodash/isNumber'
import sum from 'lodash/sum'
import take from 'lodash/take'
import React, { forwardRef } from 'react'

const fullWidth = '100%'

const calculateWidth = (widths, factor = 1) => {
  const fixedWidths = widths.filter(isNumber)
  const fixedWidthTotal = sum(fixedWidths) || 0
  const fullWidthCount = widths.filter((width) => width === fullWidth).length
  return `calc(${factor * fullWidthCount * 100}% + ${
    factor * fixedWidthTotal
  }px)`
}

export const Component = forwardRef(
  /**
   * @param {Record<string, any>} props
   */
  function CarouselComponent(
    {
      activeIndex = 0,
      children,
      fade,
      height,
      onTransitionEnd,
      transitionDuration = 500,
      ...props
    },
    ref,
  ) {
    const items = React.Children.toArray(children)

    // Calculate where to place everything
    const widths = items.map((item) => item.props.width || fullWidth)
    const activeWidths = take(widths, activeIndex)

    return (
      <div
        {...props}
        css={{
          overflow: 'hidden',
        }}
        onScroll={(event) => {
          event.target.scrollLeft = 0
        }}
        ref={ref}
        style={{
          height,
        }}
      >
        <div
          css={{
            alignItems: 'start',
            display: 'flex',
            transition: `margin ${transitionDuration}ms ease`,
          }}
          onTransitionEnd={onTransitionEnd}
          style={{
            height: height ? '100%' : undefined,
            marginLeft: calculateWidth(activeWidths, -1),
            width: calculateWidth(widths),
          }}
        >
          {items.map((item, index) =>
            React.cloneElement(item, {
              active: index === activeIndex,
              fade,
              height: height ? '100%' : undefined,
              transitionDuration,
            }),
          )}
        </div>
      </div>
    )
  },
)

export const Item = React.forwardRef(
  /**
   * @param {Record<string, any>} props
   */
  function CarouselItem(
    {
      active,
      fade,
      height,
      transitionDuration,
      width = '100%',
      style,
      ...props
    },
    ref,
  ) {
    return (
      <div
        {...props}
        css={{
          overflow: height ? 'auto' : undefined,
          transition: `opacity ${transitionDuration}ms linear`,
        }}
        ref={ref}
        style={{
          ...style,
          height,
          opacity: active || !fade ? 1 : 0,
          width,
        }}
      />
    )
  },
)
