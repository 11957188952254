import { Waypoint } from 'react-waypoint'
import type { InfiniteProps } from '../InfiniteContext'

export function InfiniteWaypoint({
  infinite,
}: {
  infinite: InfiniteProps
}) {
  const { isLoaded, isLoading, onLoadMore, rows } = infinite
  return (
    !isLoaded &&
    !isLoading &&
    rows.length > 0 && (
      <Waypoint key={rows.length} onEnter={onLoadMore} bottomOffset='-40%' />
    )
  )
}
