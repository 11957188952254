import { gridBreakpoints } from '@blissbook/ui/branding'
import { BackgroundShapeGrid } from '@blissbook/ui/lib'
import type React from 'react'

export const FullScreenBackground = (
  props: Partial<React.ComponentProps<typeof BackgroundShapeGrid>>,
) => (
  <BackgroundShapeGrid
    {...props}
    alignX='right'
    alignY='bottom'
    shapeDefaults={{ fill: true }}
    shapes={[
      { x: 0, y: 0, type: 'wedge', color: 'aqua-500', rotate: 180 },
      { x: 1, y: 0, type: 'square', color: 'red-500' },
      { x: 2, y: 0, type: 'square', color: 'sunshine-500' },
      { x: 3, y: 0, type: 'wedge', color: 'sunshine-300' },

      { x: 0, y: 1, type: 'quotes', color: 'sunshine-500', scale: 0.6 },
      { x: 1, y: 1, type: 'square', color: 'blurple-500' },
      { x: 2, y: 1, type: 'square', color: 'aqua-500' },

      { x: 0, y: 2, type: 'square', color: 'red-500' },
      { x: 1, y: 2, type: 'wedge', color: 'purple-500' },

      { x: 1, y: 3, type: 'square', color: 'purple-300' },

      { x: 2, y: 4, type: 'square', color: 'purple-100' },
    ]}
  />
)

export const FullScreenPage = ({
  children,
  hideBackground,
  ...props
}: {
  children: React.ReactNode
  hideBackground?: boolean
  [key: string]: React.PropsWithChildren<any>
}) => (
  <div
    {...props}
    css={{
      position: 'relative',
      height: '100vh',
      width: '100%',
      background: 'white',
      zIndex: 0,
    }}
  >
    <div
      {...props}
      css={{
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'auto',

        [`@media (min-width: ${gridBreakpoints.md}px)`]: {
          paddingTop: 100,
        },
      }}
    >
      {children}
    </div>

    {!hideBackground && <FullScreenBackground />}
  </div>
)
