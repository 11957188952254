import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import classnames from 'classnames'
import { useField } from 'formik'
import { CodeInput } from '../../input'

export const Field = ({
  className,
  codeType,
  id,
  onChange,
  style,
  ...props
}) => {
  const { name } = props
  const [field, , { setValue }] = useField(name)
  id = id || name
  return (
    <div className={classnames('form-group', className)} style={style}>
      <CodeInput
        {...field}
        {...props}
        id={id}
        onChange={(value) => {
          setValue(value).catch(logUIError)
          if (onChange) onChange(value)
        }}
        type={codeType}
      />
    </div>
  )
}
