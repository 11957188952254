import { isNodeSelection, isTextSelection } from '@blissbook/lib/document'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { Editor } from '../editor'
import { ToolbarButton } from '../toolbar'
import { hasExtension } from '../util'
import type { ToolComponent } from './types'

function canInsertEmbed(editor: Editor | undefined, type: string) {
  if (!editor) return false

  const { selection } = editor.state
  const canInsert = isNodeSelection(selection) || isTextSelection(selection)
  return hasExtension(editor, type) && canInsert
}

export const HorizontalRuleTool: ToolComponent = ({ editor, ...props }) => (
  <ToolbarButton
    {...props}
    disabled={!canInsertEmbed(editor, 'horizontalRule')}
    label='Divider'
    onClick={async () => {
      editor.chain().focus().insertHorizontalRule().run()
    }}
  >
    <FontAwesomeIcon icon='horizontal-rule' />
  </ToolbarButton>
)

export const ImageTool: ToolComponent = ({ editor, ...props }) => (
  <ToolbarButton
    {...props}
    disabled={!canInsertEmbed(editor, 'image')}
    label='Insert Image'
    onClick={async () => {
      const imageUrl = await editor.pickImage()
      if (!imageUrl) return

      editor.chain().focus().insertImage({ src: imageUrl }).run()
    }}
  >
    <FontAwesomeIcon icon={['far', 'image']} />
  </ToolbarButton>
)

export const PdfTool: ToolComponent = ({ editor, ...props }) => (
  <ToolbarButton
    {...props}
    disabled={!canInsertEmbed(editor, 'pdf')}
    label='Insert PDF'
    onClick={async () => {
      const pdfUrl = await editor.pickPdf()
      if (pdfUrl) {
        editor.chain().focus().insertPdf({ src: pdfUrl }).run()
      }
    }}
  >
    <FontAwesomeIcon icon={['far', 'file-pdf']} />
  </ToolbarButton>
)

export const VideoTool: ToolComponent = ({ editor, ...props }) => (
  <ToolbarButton
    {...props}
    disabled={!canInsertEmbed(editor, 'video')}
    label='Insert Video'
    onClick={async () => {
      const videoUrl = await editor.pickVideo()
      if (videoUrl) {
        editor.chain().focus().insertVideo({ src: videoUrl }).run()
      }
    }}
  >
    <FontAwesomeIcon icon={['far', 'video']} />
  </ToolbarButton>
)
