import type { PropertyOption } from '@blissbook/lib/properties'
import { colors } from '@blissbook/ui/branding'
import { Button } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const propertyOptionsColors = [
  {
    label: 'Light Gray',
    value: colors['gray-100'],
  },
  {
    label: 'Gray',
    value: colors['gray-200'],
  },
  {
    label: 'Purple',
    value: colors['purple-50'],
  },
  {
    label: 'Blurple',
    value: colors['blurple-100'],
  },
  {
    label: 'Blue',
    value: colors['aqua-50'],
  },
  {
    label: 'Green',
    value: colors['green-50'],
  },
  {
    label: 'Yellow',
    value: colors['sunshine-50'],
  },
  {
    label: 'Red',
    value: colors['red-50'],
  },
]

export function PropertyOptionView({
  className,
  onRemove,
  option,
}: {
  className?: string
  onRemove?: () => void
  option: Omit<PropertyOption, 'id'>
}) {
  return (
    <div
      className={cx(
        'tw-inline-flex tw-items-center tw-gap-1 tw-px-1 tw-rounded tw-text-black tw-whitespace-nowrap ellipsis',
        className,
      )}
      style={{ backgroundColor: option.color || colors['gray-200'] }}
      title={option.label}
    >
      <div className='tw-flex-1 tw-min-w-0 ellipsis'>{option.label}</div>

      {onRemove && (
        <Button className='btn-icon' onClick={onRemove}>
          <FontAwesomeIcon icon='times' />
        </Button>
      )}
    </div>
  )
}
