import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'

export function Infobox({
  children,
  className,
  iconClassName = 'tw-text-aqua-500',
  ...props
}: {
  children: React.ReactNode
  className?: string
  iconClassName?: string
  style?: React.CSSProperties
}) {
  return (
    <div
      {...props}
      className={cx(
        'tw-flex tw-items-center tw-bg-gray-100 tw-text-sm tw-rounded',
        className,
      )}
      css={{
        padding: '.5rem 1rem',
      }}
    >
      <FontAwesomeIcon
        className={cx('tw-mr-4', iconClassName)}
        css={{
          fontSize: 24,
        }}
        icon={['far', 'info-circle']}
      />
      <div>{children}</div>
    </div>
  )
}
