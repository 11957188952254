import { Tooltip } from '@blissbook/ui/lib'
import { type DocumentPhaseDocument, getDocumentPhase } from './DocumentPhase'

export type DocumentStatusViewProps = {
  compact?: boolean
  document: DocumentPhaseDocument
}

export function DocumentPhaseView({
  compact,
  document,
}: DocumentStatusViewProps) {
  const phase = getDocumentPhase(document)
  return (
    <div className='tw-flex tw-items-center tw-gap-1 ellipsis'>
      <Tooltip content={phase.label} disabled={!compact} placement='right'>
        <span>{phase.icon}</span>
      </Tooltip>

      {!compact && (
        <span className='tw-flex-1 tw-min-w-0 ellipsis'>{phase.label}</span>
      )}
    </div>
  )
}
