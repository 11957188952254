import {
  type OperandValue,
  isOperandSubsetOf,
  isOperandValueValid,
} from '@blissbook/lib/expression'
import isEqual from 'lodash/isEqual'
import { nanoid } from 'nanoid'
import { ExpressionConjunction } from './types'

export type Expression<OperandType = OperandValue> = {
  conjunction: ExpressionConjunction
  operands: OperandType[]
}

export type RootExpression<OperandType = OperandValue> = {
  conjunction: ExpressionConjunction
  expressions: Expression<OperandType>[]
}

export const emptyRootExpression: RootExpression = {
  conjunction: ExpressionConjunction.And,
  expressions: [],
}

export function buildRootExpressionFromExpression<OperandType = OperandValue>(
  expression: Expression<OperandType>,
): RootExpression<OperandType> {
  return {
    conjunction: ExpressionConjunction.And,
    expressions: [expression],
  }
}

export function buildRootExpressionFromOperand<OperandType = OperandValue>(
  operand: OperandType,
): RootExpression<OperandType> {
  return buildRootExpressionFromExpression({
    conjunction: ExpressionConjunction.And,
    operands: [operand],
  })
}

export function isExpressionValid(expression: Expression): boolean {
  const { operands } = expression
  if (!operands.length) return false
  return operands.every(isOperandValueValid)
}

export function isRootExpressionValid(rootExpression: RootExpression): boolean {
  const { expressions } = rootExpression
  if (!expressions.length) return false
  return expressions.every(isExpressionValid)
}

/** Ensure all of operands have an id on them */
export function addIdsToRootExpression<T extends RootExpression>(value: T): T {
  return {
    ...value,
    expressions: value.expressions.map((expression) => ({
      ...expression,
      operands: expression.operands.map((operand) => {
        if (operand.id) return operand
        return { ...operand, id: nanoid() }
      }),
    })),
  }
}

/** Ensure no operands have ids on them */
export function removeIdsFromRootExpression<T extends RootExpression>(
  value: T,
): T {
  return {
    ...value,
    expressions: value.expressions.map((expression) => ({
      ...expression,
      operands: expression.operands.map(({ id, ...operand }) => operand),
    })),
  }
}

// Determine if these two expressions are equal, minus the ids
export function areRootExpressionsEqual(
  lhs: RootExpression | undefined,
  rhs: RootExpression | undefined,
): boolean {
  if (!lhs && !rhs) return true
  if (!lhs || !rhs) return false
  lhs = removeIdsFromRootExpression(lhs)
  rhs = removeIdsFromRootExpression(rhs)
  return isEqual(lhs, rhs)
}

// Determine if the first expression is a subset of the other one
export function isRootExpressionSubsetOf(
  lhs: RootExpression,
  rhs: RootExpression,
) {
  // If rhs is everyone, everything is a subset
  if (!rhs) return true

  // If lhs is everyone, nothing is a subset
  if (!lhs) return false

  // If they are the equal, then true
  const isEqual = areRootExpressionsEqual(lhs, rhs)
  if (isEqual) return true

  // If both are a single operand, compare the operands
  if (lhs.expressions.length === 1 && rhs.expressions.length === 1) {
    const [lhsExpression] = lhs.expressions
    const [rhsExpression] = rhs.expressions
    if (
      lhsExpression.operands.length === 1 &&
      rhsExpression.operands.length === 1
    ) {
      const [lhsOperand] = lhsExpression.operands
      const [rhsOperand] = rhsExpression.operands
      return isOperandSubsetOf(lhsOperand, rhsOperand)
    }
  }

  return false
}
