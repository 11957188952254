import './SearchInput.scss'
import { mergeRefs } from '@blissbook/ui/util'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import without from 'lodash/without'
import pluralize from 'pluralize'
import { Fragment, forwardRef, useCallback, useEffect, useState } from 'react'
import { Badge } from '../badge'
import { Button } from '../buttons'
import { Dropdown } from '../popper'
import { Tooltip } from '../tooltip'
import { Input, useValue } from './Input'

const emptyValue = null

const fetchOptions = async (getOptions, text) => {
  if (!getOptions) return

  if (isArray(getOptions)) {
    const regEx = new RegExp(text, 'i')
    return getOptions.filter((option) => regEx.test(option.label))
  }
  if (isFunction(getOptions)) {
    return getOptions(text)
  }
}

export const SearchInput = forwardRef(
  /**
   * @param {Record<string, any>} props
   */
  function SearchInput(
    {
      backIcon,
      backIconColor,
      chips,
      className,
      clearIcon = 'times',
      clearIconColor,
      debounce = 250,
      dropdownClassName,
      dropdownMaxHeight = 200,
      dropdownOffset = 2,
      dropdownWidth,
      fullScreen,
      getOptions,
      id,
      infoTooltip,
      inputClassName,
      inputRef,
      inputStyle,
      minLength = 2,
      // If true, the search value will not be cleared when an option is selected
      noClearValueOnSelect = false,
      noResults,
      onBlur,
      onBack,
      onChange,
      onChangeChips,
      onClear,
      onFocus,
      onKeyDown,
      onSelect,
      placeholder,
      searchIcon = 'search',
      searchIconClassName,
      searchIconStyle,
      size,
      style,
      inputId,
      ...props
    },
    ref,
  ) {
    const { disabled, onChangeValue } = props
    const [focus, setFocus] = useState(false)
    const [inputNode, setInputNode] = useState()
    const [options, setOptions] = useState()
    const [refNode, setRefNode] = useState()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [search, setSearch] = useState(emptyValue)
    const [value, setValue, resetAt] = useValue(props.value)

    // Reset search when value is reset
    useEffect(() => {
      setSearch(emptyValue)
    }, [resetAt])

    // Run new search for options
    const updateOptions = async (search) => {
      const fetchedOptions = await fetchOptions(getOptions, search)
      setOptions(fetchedOptions)
    }

    // When the search changes, perform a new search
    useEffect(() => {
      // Clear the options
      setOptions()
      setSelectedIndex(0)

      // Determine if search is allowed, defualt false-y values to empty text
      const searchText = search || ''
      if (searchText.length < minLength) return
      if (disabled) return

      // Debounce the search
      const timerId = setTimeout(() => updateOptions(searchText), debounce)
      return () => clearTimeout(timerId)
    }, [disabled, minLength, search])

    const clearValue = useCallback(() => {
      if (onChangeValue) onChangeValue(emptyValue)
      setSearch(emptyValue)
      setValue(emptyValue)
    }, [onChangeValue])

    const moveSelectedIndex = useCallback(
      (delta) => {
        if (!options) return

        const index = selectedIndex + delta
        if (index >= 0 && index < options.length) {
          setSelectedIndex(index)
        }
      },
      [options, selectedIndex],
    )

    const removeChip = useCallback(
      (chip) => {
        const newChips = without(chips, chip)
        onChangeChips(newChips)
      },
      [chips, onChangeChips],
    )

    const handleChange = useCallback(
      (event, value) => {
        if (onChange) onChange(event, value)
        setSearch(value)
        setValue(value)
      },
      [onChange],
    )

    const handleBack = useCallback(() => {
      if (onBack) onBack()
      clearValue()
    }, [clearValue, onBack])

    const handleClear = useCallback(() => {
      if (onChangeChips) onChangeChips([])
      if (onClear) onClear()
      clearValue()
    }, [clearValue, onChangeChips, onClear])

    const handleMouseDown = useCallback(
      (event) => {
        if (event.target !== inputNode) event.preventDefault()
        // SD: I don't think this is necessary. It's causing issues with the dropdown not closing.
        // event.stopPropagation()
      },
      [inputNode],
    )

    const handleSelectOption = useCallback(
      (option) => {
        if (option.url) {
          window.open(option.url, '_blank', 'noopener noreferrer')
          if (!fullScreen) clearValue()
        } else if (onSelect) {
          onSelect(option)

          if (!noClearValueOnSelect) {
            clearValue()
          }
        }
      },
      [clearValue, onSelect],
    )

    const handleKeyDown = useCallback(
      (event) => {
        if (onKeyDown) onKeyDown(event)

        // If we have options, deal with them
        if (options) {
          const option = options[selectedIndex]
          if (event.keyCode === 13 && option) {
            // Enter
            event.preventDefault()
            handleSelectOption(option)
          } else if (event.keyCode === 27) {
            // ESC
            event.preventDefault()
            setSearch(emptyValue)
          } else if (event.keyCode === 40) {
            // Down
            event.preventDefault()
            moveSelectedIndex(1)
          } else if (event.keyCode === 38) {
            // Up
            event.preventDefault()
            moveSelectedIndex(-1)
          }
        }
      },
      [handleSelectOption, moveSelectedIndex, onKeyDown, options],
    )

    const hasChips = chips && chips.length > 0
    const isEmpty = !value && !hasChips
    const hasOptions = !!options && (options.length > 0 || !!noResults)
    const isOpen = hasOptions && focus

    return (
      <Dropdown.Provider isOpen={isOpen} refNode={refNode}>
        <div
          className={cx('rw-search-input', className, {
            'full-screen': fullScreen,
            open: isOpen,
          })}
          id={id}
          ref={ref}
          style={style}
        >
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: should Fix */}
          <div
            className={cx(
              'form-control',
              inputClassName,
              size && `form-control-${size}`,
              { disabled, focus },
            )}
            onClick={(event) => {
              if (event.target !== inputNode) {
                inputNode.focus()
              }
            }}
            onMouseDown={handleMouseDown}
            ref={setRefNode}
            style={inputStyle}
          >
            {backIcon ? (
              <Button
                className='btn-icon back-icon'
                color={backIconColor}
                onClick={handleBack}
              >
                <FontAwesomeIcon icon={backIcon} />
              </Button>
            ) : searchIcon && !hasChips ? (
              <FontAwesomeIcon
                className={cx('search-icon', searchIconClassName)}
                icon={searchIcon}
                style={searchIconStyle}
              />
            ) : null}

            {chips?.map((chip) => (
              <Tooltip
                content={`Remove ${chip.label}`}
                {...chip.tooltipProps}
                key={chip.key}
              >
                <Badge
                  className='tw-uppercase tw-bg-blurple-500'
                  css={{ margin: '-2px 6px -2px 0' }}
                  icon={chip.icon}
                  onRemove={() => removeChip(chip)}
                  style={chip.style}
                  variant='chip'
                >
                  {chip.label}
                </Badge>
              </Tooltip>
            ))}

            <Input
              {...props}
              id={inputId}
              type='text'
              disabled={disabled}
              debounce={debounce}
              emptyValue={emptyValue}
              initialValue={props.value}
              onBlur={(event) => {
                setFocus(false)
                if (onBlur) onBlur(event)
              }}
              onFocus={(event) => {
                setFocus(true)
                if (onFocus) onFocus(event)
              }}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              placeholder={hasChips ? undefined : placeholder}
              title={props.title || placeholder}
              aria-label={props['aria-label'] || placeholder}
              ref={mergeRefs([inputRef, setInputNode])}
              value={value}
            />

            <div className='tw-flex tw-items-center tw-ml-4 tw-mr-1'>
              {disabled ? null : !isEmpty && clearIcon ? (
                <Button
                  className='btn-icon clear-icon'
                  color={clearIconColor}
                  onClick={handleClear}
                >
                  <FontAwesomeIcon className='tw-block' icon={clearIcon} />
                </Button>
              ) : isEmpty && infoTooltip ? (
                <Tooltip content={infoTooltip} maxWidth={200}>
                  <Button className='btn-icon'>
                    <FontAwesomeIcon
                      className='tw-block'
                      icon={['far', 'info-circle']}
                    />
                  </Button>
                </Tooltip>
              ) : null}
            </div>
          </div>

          <SearchDropdownMenu
            className={cx(
              {
                'dropdown-menu-categorized': options?.some(
                  (option) => option.category,
                ),
              },
              dropdownClassName,
            )}
            fullScreen={fullScreen}
            maxHeight={dropdownMaxHeight}
            offset={dropdownOffset}
            onMouseDown={handleMouseDown}
            width={dropdownWidth}
          >
            <SearchDropdownOptions
              noResults={noResults}
              onSelect={handleSelectOption}
              options={options}
              selectedIndex={selectedIndex}
            />
          </SearchDropdownMenu>
        </div>
      </Dropdown.Provider>
    )
  },
)

const SearchDropdownMenu = ({
  className,
  fullScreen,
  maxHeight,
  offset,
  width,
  ...props
}) => {
  if (fullScreen) {
    return <div {...props} className={cx('dropdown-menu', className)} />
  }
  return (
    <Dropdown.Menu
      {...props}
      className={className}
      maxHeight={maxHeight}
      offset={offset}
      sameWidth={width ? undefined : true}
      style={{ width }}
    />
  )
}

const SearchDropdownOptions = ({
  noResults,
  onSelect,
  options = [],
  selectedIndex,
}) => {
  const [activeNode, setActiveNode] = useState()

  useEffect(() => {
    if (!activeNode) return
    activeNode.scrollIntoView({ block: 'nearest' })
  }, [activeNode])

  if (!options.length) {
    return <div className='dropdown-item-text'>{noResults}</div>
  }

  return options.map((option, index) => {
    const { category } = option
    const isActive = index === selectedIndex
    const lastOption = options[index - 1]
    const lastCategory = lastOption?.category

    return (
      <Fragment key={option.key}>
        {category && category !== lastCategory && (
          <div className='dropdown-header'>{pluralize(category)}</div>
        )}

        <SearchDropdownOption
          {...option}
          active={isActive}
          onSelect={() => {
            onSelect(option)
          }}
          ref={isActive ? setActiveNode : undefined}
        />
      </Fragment>
    )
  })
}

const SearchDropdownOption = forwardRef(
  (
    {
      active,
      checked,
      children,
      className,
      Component,
      icon,
      label,
      onSelect,
      title,
      tooltip = '',
      url,
    },
    ref,
  ) => {
    const content = Component ? (
      <Component />
    ) : children ? (
      children
    ) : (
      <>
        {icon && <FontAwesomeIcon className='dropdown-item-icon' icon={icon} />}
        {label}
        {checked && (
          <FontAwesomeIcon className='dropdown-item-icon-check' icon='check' />
        )}
      </>
    )

    const props = {
      children: content,
      className: cx(
        'dropdown-item',
        checked && 'tw-flex tw-items-center tw-justify-between',
        { active },
        className,
      ),
      ref,
      title: title || label,
    }

    const element = url ? (
      <a {...props} href={url} rel='noopener noreferrer' target='_blank' />
    ) : (
      <button {...props} onClick={onSelect} />
    )

    // No tooltip, we can return the element
    if (!tooltip) return element

    // If provided full TooltipProps, use them
    if (typeof tooltip === 'object' && 'content' in tooltip) {
      return <Tooltip {...tooltip}>{element}</Tooltip>
    }

    // Otherwise, assume we were just provided the content
    return (
      <Tooltip content={tooltip} maxWidth={200} placement='right'>
        {element}
      </Tooltip>
    )
  },
)
