import { getInitials } from '@blissbook/lib/name'
import type { ComponentProps } from 'react'
import { ProfileView } from './ProfileView'

export type PersonProfileViewProps = ComponentProps<'div'> & {
  disableTooltip?: boolean
  person: {
    avatarUrl?: string
    fullName: string
  }
  size: number
}

export const PersonProfileView = ({
  className,
  disableTooltip,
  person,
  style,
  ...props
}: PersonProfileViewProps) => {
  return (
    <ProfileView
      {...props}
      avatarURL={person.avatarUrl}
      className={`tw-bg-blurple-500 ${className}`}
      text={getInitials(person.fullName)}
      tooltip={disableTooltip ? undefined : person.fullName}
    />
  )
}

PersonProfileView.displayName = 'PersonProfileView'
