import { useMouseDownOutside } from '@blissbook/ui/hooks/useMouseDownOutside'
import { mergeRefs } from '@blissbook/ui/util'
import { cx } from '@emotion/css'
import type { Modifier, Placement, PositioningStrategy } from '@popperjs/core'
import isFunction from 'lodash/isFunction'
import type React from 'react'
import {
  type ReactNode,
  createContext,
  forwardRef,
  useContext,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import { Button, type ButtonProps } from '../buttons'

export type PopperStateInput = {
  isOpen?: boolean
  refNode?: HTMLElement | null
  setOpen?: (isOpen: boolean) => void
}

const usePopperState = (state: PopperStateInput) => {
  const [isOpen, setOpen] = useState(false)
  const [refNode, setRefNode] = useState<HTMLElement | null>()

  return {
    isOpen,
    setOpen,
    setRefNode,
    refNode,
    ...state,
  }
}

// Context

export type PopperState = {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  setRefNode: (node: HTMLElement | null) => void
  refNode: HTMLElement | null
}

export const Context = createContext<PopperState>({} as PopperState)

// Provider

type ProviderChildrenFn = (state: PopperState) => ReactNode

export type ProviderProps = PopperStateInput & {
  children: ReactNode | ProviderChildrenFn
}

export const Provider = ({ children, ...props }: ProviderProps) => {
  const state = usePopperState(props)
  return (
    <Context.Provider value={state}>
      {isFunction(children) ? children(state) : children}
    </Context.Provider>
  )
}

// Menu

export type MenuProps = React.HTMLAttributes<HTMLDivElement> & {
  children: ReactNode
  className?: string
  forceRender?: boolean
  maxHeight?: number
  offset?: number
  placement?: Placement
  sameWidth?: boolean
  showArrow?: boolean
  strategy?: PositioningStrategy
}

const arrowSize = 5

// https://popper.js.org/docs/v2/modifiers/community-modifiers/
const sameWidthModifier: Modifier<'sameWidth', object> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }: any) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }: any) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
  },
}

export const Menu = ({
  children,
  className,
  forceRender,
  maxHeight,
  offset,
  placement = 'bottom-start',
  sameWidth,
  showArrow,
  style,
  strategy = 'absolute',
  ...props
}: MenuProps) => {
  const [arrowNode, setArrowNode] = useState<HTMLDivElement | null>()
  const [menuNode, setMenuNode] = useState<HTMLDivElement | null>()
  const { isOpen, setOpen, refNode } = useContext(Context)

  if (offset === undefined) offset = showArrow ? arrowSize + 1 : 2

  const { attributes, styles } = usePopper(
    refNode,
    isOpen ? menuNode : undefined,
    {
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowNode,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, offset],
          },
        },
        sameWidth ? sameWidthModifier : undefined,
      ].filter(Boolean),
      placement,
      strategy,
    },
  )

  useMouseDownOutside(() => {
    if (isOpen) setOpen(false)
  }, [refNode, menuNode])

  if (!isOpen && !forceRender) return null

  const menu = (
    <div
      {...attributes.popper}
      {...props}
      className={cx('popper-menu', className)}
      ref={setMenuNode}
      style={{
        ...styles.popper,
        ...style,
        display: isOpen ? undefined : 'none',
        maxHeight,
        overflow: maxHeight ? 'auto' : undefined,
      }}
    >
      {showArrow && (
        <div
          className='popper-menu-arrow'
          ref={setArrowNode}
          style={{
            ...styles.arrow,
            transform: [styles.arrow.transform, 'rotate(45deg)']
              .filter(Boolean)
              .join(' '),
          }}
        />
      )}

      {children}
    </div>
  )

  if (strategy === 'fixed') {
    return createPortal(menu, document.body)
  }
  return menu
}

// ToggleButton

export type ToggleButtonProps = ButtonProps

export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  ({ children, onClick, ...props }, ref) => {
    const { isOpen, setOpen, setRefNode } = useContext(Context)
    return (
      <Button
        {...props}
        onClick={(event) => {
          if (onClick) onClick(event)
          setOpen(!isOpen)
        }}
        ref={mergeRefs([ref, setRefNode])}
      >
        {children}
      </Button>
    )
  },
)

// ToggleButtonBasic sans caret

export type ToggleButtonBasicProps = ButtonProps

export const ToggleButtonBasic = forwardRef<
  HTMLButtonElement,
  ToggleButtonBasicProps
>(({ children, onClick, ...props }, ref) => {
  const { isOpen, setOpen, setRefNode } = useContext(Context)
  return (
    <Button
      {...props}
      onClick={(event) => {
        if (onClick) onClick(event)
        setOpen(!isOpen)
      }}
      ref={mergeRefs([ref, setRefNode])}
    >
      {children}
    </Button>
  )
})
