import { colors } from '@blissbook/ui/branding'
import classnames from 'classnames'
import type React from 'react'
import { type CSSProperties, forwardRef } from 'react'
import Switch from 'react-switch'

const ToggleButtonText: React.FC<{
  className?: string
}> = ({ className, ...props }) => (
  <div
    {...props}
    className={classnames('tw-flex tw-items-center tw-h-full', className)}
  />
)

type OnOffToggleButtonProps = {
  checked: boolean
  className?: string
  disabled?: boolean
  height?: number
  offText?: string
  onChange: (checked: boolean) => void
  onText?: string
  style?: CSSProperties
  width?: number
}

// See: https://www.npmjs.com/package/react-switch
export const OnOffToggleButton = forwardRef<
  HTMLDivElement,
  OnOffToggleButtonProps
>(
  (
    {
      className,
      height = 21,
      offText = 'OFF',
      onText = 'ON',
      style,
      width = 55,
      ...props
    },
    ref,
  ) => {
    const { checked } = props
    return (
      <div className={className} ref={ref} style={style}>
        <Switch
          activeBoxShadow={null}
          css={{
            color: checked ? colors['blurple-500'] : colors['gray-600'],
            fontSize: 12,
            transition: 'color 0.25s ease, opacity 0.25s ease',
          }}
          checkedIcon={
            <ToggleButtonText className='tw-pl-2'>{onText}</ToggleButtonText>
          }
          handleDiameter={height - 6}
          height={height}
          offColor={colors['gray-100']}
          offHandleColor={colors['gray-400']}
          onColor={colors['blurple-200']}
          onHandleColor={colors['blurple-500']}
          width={width}
          uncheckedIcon={<ToggleButtonText>{offText}</ToggleButtonText>}
          {...props}
        />
      </div>
    )
  },
)
