import { cx } from '@emotion/css'
import type React from 'react'
import type { CSSProperties } from 'react'
import type { Editor } from '../editor'

export const ToolbarDivider: React.FC<{
  className?: string
  editor?: Editor
  style?: CSSProperties
}> = ({ className, editor, ...props }) => (
  <span {...props} className={cx('toolbar-divider', className)}>
    |
  </span>
)
