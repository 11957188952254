import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { NodeWithPos } from '@tiptap/core'
import { type CellSelection, TableMap } from 'prosemirror-tables'
import type React from 'react'
import type { CSSProperties } from 'react'
import type { Editor } from '../../editor'
import { getCellSelection } from '../../selection'
import { useDOMRect } from '../util'
import {
  getColumnRects,
  getColumnSelection,
  getRowRects,
  getRowSelection,
  getTableSelection,
  isSelectionActive,
} from './util'

const handleBackgroundColors = [
  'rgba(0, 0, 0, 0.05)',
  'rgba(255, 255, 255, 0.05)',
]

const styles = {
  handleBackground: handleBackgroundColors
    .map((color) => `linear-gradient(${color}, ${color})`)
    .join(','),
  handleSize: 16,
  insertBorderWidth: 3,
  insertButtonOffset: 1,
  insertButtonSize: 16,
}

export type TableEditorProps = {
  className?: string
  editor: Editor
  style?: CSSProperties
  table: NodeWithPos
}

export const TableEditor: React.FC<TableEditorProps> = ({
  className,
  style,
  ...props
}) => {
  const { editor, table } = props
  const { state } = editor
  const selection = getCellSelection(state, table)

  const tableRect = useDOMRect(editor, table.pos)
  const tableMap = TableMap.get(table.node)

  const columnRects = getColumnRects({ editor, table, tableMap })
  const columnInserts = [0, ...columnRects.map((r) => r.left + r.width - 1)]

  const rowRects = getRowRects({ editor, table, tableMap })
  const rowInserts = [0, ...rowRects.map((r) => r.top + r.height - 1)]

  return (
    <div
      className={cx('tw-absolute tw-pointer-events-none', className)}
      style={{
        ...style,
        left: tableRect.left,
        top: tableRect.top,
        width: tableRect.width,
        height: tableRect.height,
      }}
    >
      <TableHandle {...props} selection={selection} tableMap={tableMap} />

      <div
        className='tw-absolute tw-bottom-full tw-pointer-events-auto'
        css={{
          background: styles.handleBackground,
          height: styles.handleSize,
        }}
        style={{
          width: tableRect.width,
        }}
      >
        {columnRects.map((rect, index) => (
          <TableColumnHandle
            {...props}
            col={index}
            key={index}
            selection={selection}
            style={rect}
            tableMap={tableMap}
          />
        ))}
      </div>

      {columnInserts.map((left, col) => (
        <InsertColumnView {...props} col={col} key={col} style={{ left }} />
      ))}

      <div
        className='tw-absolute tw-right-full tw-pointer-events-auto'
        css={{
          background: styles.handleBackground,
          width: styles.handleSize,
        }}
        style={{
          height: tableRect.height,
        }}
      >
        {rowRects.map((rect, index) => (
          <TableRowHandle
            {...props}
            key={index}
            selection={selection}
            row={index}
            style={rect}
            tableMap={tableMap}
          />
        ))}
      </div>

      {rowInserts.map((top, row) => (
        <InsertRowView {...props} key={row} row={row} style={{ top }} />
      ))}
    </div>
  )
}

type TableHandleProps = TableEditorProps & {
  selection?: CellSelection
  tableMap: TableMap
}

const TableHandle = ({
  className,
  editor,
  selection,
  table,
  tableMap,
  ...props
}: TableHandleProps) => {
  const tableSelection = getTableSelection({ editor, table, tableMap })
  const active = isSelectionActive(selection, tableSelection)

  return (
    <div
      {...props}
      className={cx(
        'tw-cursor-pointer tw-pointer-events-auto',
        'tw-absolute tw-bottom-full tw-right-full',
        'tw-flex tw-items-center tw-justify-center',
        'tw-text-xs',
        active ? 'tw-text-aqua-500' : 'tw-text-black/40 hover:tw-text-black/60',
        className,
      )}
      css={{
        height: styles.handleSize,
        width: styles.handleSize,
      }}
      onClick={() => {
        editor.commands.setSelection(tableSelection)
      }}
    >
      <FontAwesomeIcon icon={['far', 'table-cells']} />
    </div>
  )
}

type TableCellsHandleProps = {
  cellsSelection: CellSelection
  className?: string
  editor: Editor
  selection?: CellSelection
  style?: CSSProperties
}

const TableCellsHandle: React.FC<TableCellsHandleProps> = ({
  cellsSelection,
  className,
  editor,
  selection,
  ...props
}) => {
  const active = isSelectionActive(selection, cellsSelection)
  return (
    <div
      {...props}
      className={cx(
        'tw-absolute tw-cursor-pointer',
        'tw-flex tw-items-center tw-justify-center',
        'tw-text-xs',
        active ? 'tw-bg-aqua-500 tw-text-white' : 'tw-text-transparent',
        className,
      )}
      css={{
        '&:hover': {
          background: styles.handleBackground,
        },
      }}
      onClick={() => {
        // @ts-ignore
        editor.commands.setSelection(cellsSelection)
      }}
    />
  )
}

type TableColumnHandleProps = TableHandleProps & {
  col: number
}

const TableColumnHandle = ({
  col,
  className,
  table,
  tableMap,
  ...props
}: TableColumnHandleProps) => {
  const { editor } = props
  const colSelection = getColumnSelection({
    col,
    editor,
    table,
    tableMap,
  })

  return (
    <TableCellsHandle
      {...props}
      cellsSelection={colSelection}
      className='tw-h-full'
    >
      <FontAwesomeIcon icon='grip-dots' />
    </TableCellsHandle>
  )
}

type TableRowHandleProps = TableHandleProps & {
  row: number
}

const TableRowHandle = ({
  className,
  row,
  table,
  tableMap,
  ...props
}: TableRowHandleProps) => {
  const { editor } = props
  const rowSelection = getRowSelection({
    editor,
    row,
    table,
    tableMap,
  })

  return (
    <TableCellsHandle
      {...props}
      cellsSelection={rowSelection}
      className='tw-w-full'
    >
      <FontAwesomeIcon icon='grip-dots-vertical' />
    </TableCellsHandle>
  )
}

const InsertButton: React.FC<{
  className?: string
  style?: CSSProperties
}> = ({ className, ...props }) => (
  <div
    {...props}
    className={cx(
      'tw-absolute tw-h-4 tw-w-4',
      'tw-group tw-cursor-pointer tw-pointer-events-auto',
      className,
    )}
  >
    <div
      className={cx(
        'tw-flex tw-items-center tw-justify-center',
        'tw-h-full tw-w-full tw-transition tw-rounded-full',
        'tw-bg-white/20 tw-text-black/40 tw-text-[8px]',
        'group-hover:tw-bg-aqua-500 group-hover:tw-text-white',
      )}
    >
      <FontAwesomeIcon icon={['far', 'plus']} />
    </div>
  </div>
)

type InsertViewProps = {
  className?: string
  onClick: () => void
  style?: CSSProperties
}

const InsertView: React.FC<InsertViewProps> = ({ className, ...props }) => (
  <div
    {...props}
    className={cx(
      'tw-absolute tw-bg-aqua-500/0 tw-transition tw-z-10',
      'hover:tw-bg-aqua-500',
      className,
    )}
  />
)

type InsertColumnViewProps = TableEditorProps & {
  col: number
}

const InsertColumnView: React.FC<InsertColumnViewProps> = ({
  col,
  className,
  editor,
  table,
  ...props
}) => (
  <InsertView
    {...props}
    className={cx('tw-bottom-0', className)}
    css={{
      marginLeft: -styles.insertBorderWidth / 2,
      top: -styles.handleSize,
      width: styles.insertBorderWidth,
    }}
    onClick={() => {
      editor.chain().focus().insertColumnAt(col).run()
    }}
  >
    <InsertButton
      css={{
        bottom: `calc(100% + ${styles.insertButtonOffset}px)`,
        left: (styles.insertBorderWidth - styles.insertButtonSize) / 2,
      }}
    />
  </InsertView>
)

type InsertRowViewProps = TableEditorProps & {
  row: number
}

const InsertRowView: React.FC<InsertRowViewProps> = ({
  className,
  editor,
  row,
  table,
  ...props
}) => (
  <InsertView
    {...props}
    className={cx('tw-right-0', className)}
    css={{
      height: styles.insertBorderWidth,
      left: -styles.handleSize,
      marginTop: -styles.insertBorderWidth / 2,
    }}
    onClick={() => {
      editor.chain().focus().insertRowAt(row).run()
    }}
  >
    <InsertButton
      css={{
        right: `calc(100% + ${styles.insertButtonOffset}px)`,
        top: (styles.insertBorderWidth - styles.insertButtonSize) / 2,
      }}
    />
  </InsertView>
)
