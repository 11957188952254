import { Portal } from '@blissbook/ui/lib'
import { mergeRefs } from '@blissbook/ui/util'
import { cx } from '@emotion/css'
import {
  type Instance,
  type VirtualElement,
  createPopper,
} from '@popperjs/core'
import { forwardRef, useEffect, useState } from 'react'
import { Toolbar, type ToolbarProps } from './Toolbar'

export function useBubbleToolbarPopper(
  refEl: Element | VirtualElement,
  popperEl: HTMLElement | null,
  options?: {
    offset?: number
    onChangePopper?: (popper: Instance) => void
  },
) {
  const { offset, onChangePopper } = options

  useEffect(() => {
    if (!popperEl) return

    const popper = createPopper(refEl, popperEl, {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, offset],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: [],
          },
        },
      ],
      placement: 'top',
      strategy: 'fixed',
    })
    onChangePopper?.(popper)

    return () => {
      onChangePopper?.(undefined)
      popper.destroy()
    }
  }, [refEl, popperEl])
}

export type BubbleToolbarProps = ToolbarProps & {
  offset: number
  onChangePopper?: (popper: Instance) => void
  refEl: Element | VirtualElement
}

export const BubbleToolbar = forwardRef<HTMLDivElement, BubbleToolbarProps>(
  ({ className, offset, onChangePopper, refEl, ...props }, ref) => {
    const [toolbarEl, setToolbarEl] = useState<HTMLDivElement | null>(null)
    useBubbleToolbarPopper(refEl, toolbarEl, { offset, onChangePopper })

    return (
      <Portal>
        <Toolbar
          {...props}
          className={cx('toolbar-bubble', className)}
          ref={mergeRefs([ref, setToolbarEl])}
        />
      </Portal>
    )
  },
)
