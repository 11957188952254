import {
  type RootExpression,
  buildRootExpressionFromOperand,
} from './Expression'
import { OperandType } from './types'

export function buildReviewRequesterExpression(
  personIds: number[],
): RootExpression {
  return buildRootExpressionFromOperand({
    field: 'reviewRequester',
    isEvery: false,
    isNot: false,
    type: OperandType.People,
    ids: personIds,
  })
}

export function buildReviewerNotReviewedExpression(
  personIds: number[],
): RootExpression {
  return buildRootExpressionFromOperand({
    field: 'reviewers',
    isEvery: false,
    isNot: false,
    type: OperandType.People,
    ids: personIds,
    options: ['notReviewed'],
  })
}

export function buildReviewerExpression(personIds: number[]): RootExpression {
  return buildRootExpressionFromOperand({
    field: 'reviewers',
    isEvery: false,
    isNot: false,
    type: OperandType.People,
    ids: personIds,
    options: [],
  })
}
