import { forwardRef } from 'react'

export const Field = forwardRef(
  /**
   * @param {Record<string, any>} props
   */
  function Field({ type = 'text', ...props }, ref) {
    const Component = require(`./${type}`).Field
    return <Component {...props} ref={ref} />
  },
)
