import './checklist.scss'
import { Link } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'

export const ChecklistNav = ({
  className,
  direction = 'vertical',
  ...props
}) => (
  <ul
    {...props}
    className={classnames(
      `nav nav-checklist-${direction} tw-list-none`,
      className,
    )}
  />
)

ChecklistNav.Item = ({
  active,
  checked,
  children,
  className,
  disabled,
  href,
  onClick,
  to,
  ...props
}) => (
  <li
    {...props}
    className={classnames('nav-item', className, { active, checked, disabled })}
  >
    <Link
      className='nav-link'
      href={href}
      onClick={disabled ? undefined : onClick}
      to={to}
    >
      {checked !== undefined && (
        <FontAwesomeIcon
          className='tw-mr-4'
          icon={checked ? 'check-circle' : ['far', 'check-circle']}
        />
      )}

      {children}
    </Link>
  </li>
)
