import { getColor } from '@blissbook/ui/branding'
import classnames from 'classnames'

export const Shape = ({
  className,
  color,
  colorWhite,
  rotate,
  rotateX,
  rotateY,
  rotateZ,
  scale,
  scaleX,
  scaleY,
  style,
  translateX,
  translateY,
  type,
  ...props
}) => {
  // Calculate transform
  const transforms = []
  if (translateX !== undefined) transforms.push(`translateX(${translateX}px)`)
  if (translateY !== undefined) transforms.push(`translateY(${translateY}px)`)
  if (rotate !== undefined) transforms.push(`rotate(${rotate}deg)`)
  if (rotateX !== undefined) transforms.push(`rotateX(${rotateX}deg)`)
  if (rotateY !== undefined) transforms.push(`rotateY(${rotateY}deg)`)
  if (rotateZ !== undefined) transforms.push(`rotateZ(${rotateZ}deg)`)
  if (scale !== undefined) transforms.push(`scale(${scale})`)
  if (scaleX !== undefined) transforms.push(`scaleX(${scaleX})`)
  if (scaleY !== undefined) transforms.push(`scaleY(${scaleY})`)

  const Component = require(`./${type}`).default
  return (
    <Component
      {...props}
      className={classnames('shape', className)}
      css={{ display: 'block' }}
      color={getColor(color)}
      style={{
        ...style,
        transform: transforms.length ? transforms.join(' ') : undefined,
      }}
    />
  )
}
