import type { IBlissbookTocHandbook } from '@blissbook/lib/blissbook'
import { type Node, contentDescendants } from '@blissbook/lib/document'
import classnames from 'classnames'
import {
  type BlissbookHandbookContent,
  type BlissbookSection,
  handbookContentKeys,
  sectionContentKeys,
  sectionEntryContentKeys,
} from './types'

export function getWysiwygClass(wysiwyg: string) {
  return classnames('rw-wysiwyg', wysiwyg && `rw-wysiwyg-${wysiwyg}`)
}

function handbookContentDescendants(
  fragments: BlissbookHandbookContent,
  // biome-ignore lint/suspicious/noConfusingVoidType: requires some work
  fn: (node: Node) => boolean | void,
) {
  for (const key of handbookContentKeys) {
    const content = fragments[key]
    if (!content) continue
    contentDescendants(content, fn)
  }
}

export function sectionDescendants(
  section: BlissbookSection,
  // biome-ignore lint/suspicious/noConfusingVoidType: requires some work
  fn: (node: Node) => boolean | void,
) {
  for (const key of sectionContentKeys) {
    const content = section.fragments[key]
    if (!content) continue
    contentDescendants(content, fn)
  }

  const { listEntries = [] } = section
  for (const entry of listEntries) {
    for (const key of sectionEntryContentKeys) {
      const content = entry[key]
      if (!content) continue
      contentDescendants(content, fn)
    }
  }
}

export function handbookDescendants(
  handbook: IBlissbookTocHandbook,
  // biome-ignore lint/suspicious/noConfusingVoidType: requires some work
  fn: (node: Node) => boolean | void,
) {
  handbookContentDescendants(handbook.acknowledgementForm, fn)
  handbookContentDescendants(handbook.contactFragments, fn)

  for (const section of handbook.sectionsById.values()) {
    sectionDescendants(section, fn)
  }
}

/** Get the total HTML length from all documents to take into account block level access control */
export function getHandbookHtmlLength(handbook: IBlissbookTocHandbook) {
  let length = 0
  for (const node of handbook.content) {
    if (node.type === 'documentRef') {
      const { document } = node
      if (!document) continue
      length += document.html.length
    }
  }
  return length
}

/** Get the total number of non-text nodes in a handbook */
export function getHandbookTextNodeCount(handbook: IBlissbookTocHandbook) {
  let count = 0
  handbookDescendants(handbook, (node) => {
    if (node.type === 'text') return false
    count++
  })
  return count
}
