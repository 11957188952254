import config from '@blissbook/ui-config'
import { showHelpMessage } from '@blissbook/ui/util/integrations/intercom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import qs from 'query-string'
import type React from 'react'
import { forwardRef, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const getMailtoHref = ({
  body,
  email,
  subject,
}: {
  email: string
  body?: string
  subject?: string
}) => {
  let href = `mailto:${email}`
  const search = qs.stringify({ body, subject })
  if (search.length) href += '?' + search
  return href
}

export interface MailtoLinkProps {
  body?: string
  email?: string
  href?: string
  subject?: string
  children?: React.ReactNode
}

export const MailtoLink = forwardRef<HTMLAnchorElement, MailtoLinkProps>(
  ({ body, children, email, href, subject, ...props }, ref) => {
    // Set flag to show after mounted on client
    const [mounted, setMounted] = useState(false)
    useEffect(() => setMounted(true), [])

    // Calculate href (if not provided e.g. hydrate)
    href = href || getMailtoHref({ body, email, subject })

    // Only render mail after render
    const mailProps = mounted && { href, children }
    return (
      <a
        {...props}
        {...mailProps}
        ref={ref}
        rel='noopener noreferrer'
        target='_blank'
      />
    )
  },
)

export const MailtoSupportLink = (
  props: Omit<MailtoLinkProps, 'email' | 'children'>,
) => (
  <MailtoLink {...props} email='support@blissbook.com'>
    support@blissbook.com
  </MailtoLink>
)

const isExternal = (href: string) => href[0] !== '/'

export interface LinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  disabled?: boolean
  to?: string
  children?: React.ReactNode
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, disabled, ...props }, ref) => {
    const { href, to } = props

    const newProps = {
      ...props,
      className: classnames(className, { disabled }),
    }

    if (to !== undefined) {
      return <RouterLink {...newProps} to={to} ref={ref} />
    }

    if (!href) {
      return (
        <a
          {...newProps}
          href=''
          onClick={(event) => {
            event.preventDefault()
            if (newProps.onClick) newProps.onClick(event)
          }}
          ref={ref}
        />
      )
    }

    if (href.indexOf('mailto:') === 0) {
      return <MailtoLink {...newProps} ref={ref} />
    }

    return (
      <a
        rel={isExternal(href) ? 'noopener noreferrer' : undefined}
        target={isExternal(href) ? '_blank' : undefined}
        {...newProps}
        ref={ref}
      />
    )
  },
)

export const BackLink = ({
  children,
  ...props
}: {
  children?: React.ReactNode
}) => (
  <Link {...props} css={{ fontWeight: 600 }}>
    <FontAwesomeIcon className='tw-mr-2' icon='arrow-left' />
    {children}
  </Link>
)

export const ForwardLink = ({
  children,
  ...props
}: {
  children?: React.ReactNode
}) => (
  <Link {...props} css={{ fontWeight: 600 }}>
    {children}
    <FontAwesomeIcon
      css={{ fontSize: '70%' }}
      className='tw-ml-2'
      icon='chevron-right'
    />
  </Link>
)

export interface ContactLinkProps {
  children?: React.ReactNode
  className?: string
  message?: string
  subject?: string
}

export const ContactLink = forwardRef<HTMLAnchorElement, ContactLinkProps>(
  ({ message, subject = 'Help with Blissbook', ...props }, ref) => {
    if (config.intercom) {
      return (
        <Link
          {...props}
          onClick={(event) => {
            event.preventDefault()
            showHelpMessage(message)
          }}
          ref={ref}
        />
      )
    }

    return (
      <MailtoLink
        {...props}
        body={message}
        email='gethelp@blissbook.com'
        subject={subject}
        ref={ref}
      />
    )
  },
)
