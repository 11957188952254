import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { Button, type ButtonProps } from './Button'

export function CloseButton({ className, children, ...props }: ButtonProps) {
  return (
    <Button {...props} className={classnames('btn-icon', className)}>
      <FontAwesomeIcon icon='times' />
    </Button>
  )
}
