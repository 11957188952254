import { videoAspectRatios } from '@blissbook/lib/aspectRatio'
import type { VideoNodeAttributes } from '@blissbook/lib/document'
import type React from 'react'
import {
  NodeSelectionToolbar,
  type NodeSelectionToolbarProps,
} from '../../selection/node'
import { ToolbarButton, ToolbarDivider } from '../../toolbar'
import {
  EmbedNodeSelectionAlignTool,
  EmbedNodeSelectionRemoveTool,
  EmbedNodeSelectionReplaceTool,
} from '../embed'

export const VideoNodeSelectionToolbar: React.FC<NodeSelectionToolbarProps> = (
  props,
) => {
  const { editor, removeNode, selection, updateNodeAttributes } = props
  const { node } = selection
  const { height, width } = node.attrs as VideoNodeAttributes
  const aspectRatio = videoAspectRatios.findMatch(width, height)
  return (
    <NodeSelectionToolbar {...props}>
      <EmbedNodeSelectionAlignTool {...props} />

      <ToolbarDivider />

      {videoAspectRatios.map((ratio, index) => {
        return (
          <ToolbarButton
            active={ratio === aspectRatio}
            className='tw-text-sm tw-w-auto'
            key={index}
            label={`${ratio.toString()} Aspect Ratio`}
            onClick={() => {
              const height = ratio.getHeightFromWidth(width)
              editor
                .chain()
                .focus()
                .updateAttributes(node.type, { height })
                .run()
            }}
          >
            {ratio.toString()}
          </ToolbarButton>
        )
      })}

      <ToolbarDivider />

      <EmbedNodeSelectionReplaceTool
        label='Video'
        onChange={(src) => updateNodeAttributes({ src })}
        pickSrc={editor.pickVideo}
        value={node.attrs.src}
      />

      <EmbedNodeSelectionRemoveTool label='Video' onRemove={removeNode} />
    </NodeSelectionToolbar>
  )
}
