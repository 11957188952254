import { mergeRefs } from '@blissbook/ui/util'
import type { Instance, VirtualElement } from '@popperjs/core'
import { forwardRef, useEffect, useState } from 'react'
import { useActiveEditorManager } from '../active'
import type { Editor } from '../editor'
import { BubbleToolbar, type BubbleToolbarProps } from './BubbleToolbar'

export type ProseMirrorBubbleToolbarProps = BubbleToolbarProps & {
  editor?: Editor
  offset: number
  refEl: Element | VirtualElement
}

export const ProseMirrorBubbleToolbar = forwardRef<
  HTMLDivElement,
  ProseMirrorBubbleToolbarProps
>(({ editor, ...props }, ref) => {
  const manager = useActiveEditorManager()
  const [popper, setPopper] = useState<Instance | undefined>(undefined)

  useEffect(() => {
    if (!editor || !popper) return

    function onResize() {
      popper.forceUpdate()
    }

    editor.on('resize', onResize)
    return () => {
      editor.off('resize', onResize)
    }
  }, [editor, popper])

  return (
    <BubbleToolbar
      {...props}
      onChangePopper={setPopper}
      ref={mergeRefs([
        ref,
        (el) => {
          manager.bubbleToolbarEl = el
          return el
        },
      ])}
    />
  )
})
