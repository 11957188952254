export default ({ color, size, ...props }) => (
  <svg
    {...props}
    height={size}
    width={size}
    viewBox='0 0 47 85'
    role='img'
    aria-label='a pen'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-160.000000, -1479.000000)'>
        <g transform='translate(0.000000, 1386.000000)'>
          <g transform='translate(160.000000, 20.000000)'>
            <g transform='translate(0.000000, 72.000000)'>
              <polygon
                fill={color}
                points='21.824 45.2408 24.824 45.2408 24.824 2.7208 21.824 2.7208'
              />
              <g id='Group-9' transform='translate(0.000000, 0.717800)'>
                <path
                  d='M23.3238,46.0234 C22.6858,46.0234 22.1658,46.5434 22.1658,47.1804 C22.1658,47.8194 22.6858,48.3384 23.3238,48.3384 C23.9618,48.3384 24.4808,47.8194 24.4808,47.1804 C24.4808,46.5434 23.9618,46.0234 23.3238,46.0234 M23.3238,51.3384 C21.0318,51.3384 19.1658,49.4734 19.1658,47.1804 C19.1658,44.8884 21.0318,43.0234 23.3238,43.0234 C25.6158,43.0234 27.4808,44.8884 27.4808,47.1804 C27.4808,49.4734 25.6158,51.3384 23.3238,51.3384'
                  fill={color}
                />
                <g id='Group-6'>
                  <g id='Clip-5' />
                  <path
                    d='M10.3736,63.1758 L36.2736,63.1758 C36.0506,59.3808 36.4236,49.8038 43.5476,36.6058 C43.7406,36.2478 43.6376,35.8088 43.3066,35.5848 C38.4346,32.2868 27.0686,22.9098 23.3236,5.3218 C19.5796,22.9088 8.2126,32.2868 3.3416,35.5848 C3.0106,35.8088 2.9066,36.2478 3.0996,36.6068 C10.2236,49.8038 10.5966,59.3808 10.3736,63.1758 L10.3736,63.1758 Z M37.9396,66.1758 L6.8916,66.1758 L7.2346,64.3928 C7.2526,64.2948 9.0386,53.9238 0.4596,38.0308 C-0.4684,36.3108 0.0476,34.1918 1.6606,33.1008 C6.4286,29.8718 17.8426,20.4188 20.8206,2.4138 C21.0246,1.1788 22.0786,0.2818 23.3236,0.2818 C24.5706,0.2818 25.6236,1.1798 25.8266,2.4148 C28.8046,20.4198 40.2186,29.8718 44.9876,33.1008 C46.6006,34.1918 47.1166,36.3108 46.1876,38.0298 C37.6086,53.9238 39.3946,64.2948 39.4146,64.3978 L39.7426,66.1708 L37.9396,66.1758 Z'
                    fill={color}
                  />
                </g>
                <g id='Clip-8' />
                <path
                  d='M7.107,82 L39.54,82 L39.54,66.397 L7.107,66.397 L7.107,82 Z M4.107,85 L42.54,85 L42.54,63.397 L4.107,63.397 L4.107,85 Z'
                  fill={color}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
