import last from 'lodash/last'
import { useEffect, useState } from 'react'

type BaseRowType = {
  sortKey?: string
}

/**
 * Hook to manage state for infinite scrolling
 *
 * Make sure to
 * - add notifyOnNetworkStatusChange: true to the query options
 * - add the query type to InMemoryCache typePolicies
 */
export type useInfiniteQueryArgs<RowType extends BaseRowType> = {
  error: Error | undefined
  fetchMore: (after: string) => Promise<RowType[]>
  isLoading: boolean
  pageSize: number
  rows: RowType[] | undefined
}

export function useInfiniteQuery<RowType extends BaseRowType>(
  {
    error,
    fetchMore,
    isLoading,
    pageSize,
    rows = [],
  }: useInfiniteQueryArgs<RowType>,
  deps: any[],
) {
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(false)
  }, deps)

  return {
    error,
    isLoaded,
    isLoading,
    async onLoadMore() {
      // If the initial load was less than a page, we're done
      if (rows.length < pageSize) {
        setLoaded(true)
        return
      }

      // Load more rows from the last row
      const { sortKey } = last(rows)
      const newRows = await fetchMore(sortKey)

      // If we got less than a page, we're done
      if (newRows.length !== pageSize) {
        setLoaded(true)
      }
    },
    rows,
  }
}
