import { formatDate } from '@blissbook/lib/date-fns'
import type {
  DatePropertyValue,
  NumberPropertyValue,
  PersonPropertyValue,
  Property,
  PropertyValue,
  SelectPropertyValue,
  TextPropertyValue,
  UrlPropertyValue,
} from '@blissbook/lib/properties'
import { PersonProfileView } from '@blissbook/ui/lib/profile'
import { Tooltip } from '@blissbook/ui/lib/tooltip'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropertyOptionView } from './PropertyOptionView'

export const NO_PROPERTY_VALUE = <span className='tw-text-gray-400'>-</span>

function NumberPropertyValueView({
  value,
}: {
  value: NumberPropertyValue
}) {
  return <span>{value.number}</span>
}
function PersonPropertyValueView({
  value,
}: {
  value: PersonPropertyValue
}) {
  const { people } = value
  if (!people.length) return NO_PROPERTY_VALUE

  return (
    <div className='-tw-mr-2 -tw-mb-1 tw-inline-flex tw-items-center tw-whitespace-normal tw-flex-wrap tw-max-w-full'>
      {people.map((person) => (
        <div
          className='tw-inline-flex tw-items-center tw-gap-1 tw-mr-2 tw-mb-1 tw-max-w-full'
          key={person.id}
        >
          <PersonProfileView className='tw-text-sm' person={person} size={24} />

          <span className='tw-flex-1 ellipsis' title={person.fullName}>
            {person.fullName}
          </span>
        </div>
      ))}
    </div>
  )
}

function SelectPropertyValueView({
  property,
  value,
}: {
  property: Property
  value: SelectPropertyValue
}) {
  const options = property.options.filter((option) =>
    value.ids.includes(option.id),
  )
  if (!options.length) return NO_PROPERTY_VALUE
  return (
    <div className='-tw-mr-1 -tw-mb-1 tw-whitespace-normal tw-max-w-full'>
      {options.map((option) => (
        <PropertyOptionView
          className='tw-mr-1 tw-mb-1 tw-max-w-full'
          key={option.id}
          option={option}
        />
      ))}
    </div>
  )
}

function TextPropertyValueView({
  value,
}: {
  value: TextPropertyValue
}) {
  return <span>{value.text}</span>
}

function DatePropertyValueView({
  value,
}: {
  value: DatePropertyValue
}) {
  if (!value.date) return NO_PROPERTY_VALUE
  return <span>{formatDate(new Date(value.date), 'YYYY-MM-DD')}</span>
}

function UrlPropertyValueView({
  disabled,
  isEditing,
  value,
}: {
  disabled?: boolean
  isEditing?: boolean
  value: UrlPropertyValue
}) {
  if (!value.url) return NO_PROPERTY_VALUE

  if (isEditing) {
    return <span className='tw-text-gray-700'>{value.url}</span>
  }

  if (disabled) {
    return (
      <Tooltip content={value.url}>
        <a
          href={value.url}
          target='_blank'
          rel='noopener noreferrer'
          className='tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-text-blue-500 hover:tw-underline'
          onClick={(e) => e.stopPropagation()}
        >
          {value.url}
        </a>
      </Tooltip>
    )
  }

  return (
    <div className='tw-inline-flex tw-items-center tw-gap-2'>
      <Tooltip content={value.url}>
        <span className='tw-text-gray-700 tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-max-w-[200px]'>
          {value.url}
        </span>
      </Tooltip>{' '}
      <a
        href={value.url}
        target='_blank'
        rel='noopener noreferrer'
        className='tw-text-blue-500'
        onClick={(e) => e.stopPropagation()}
      >
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='sm' />
      </a>
    </div>
  )
}

export function PropertyValueView({
  disabled,
  isEditing,
  property,
  value,
}: {
  disabled?: boolean
  isEditing?: boolean
  property: Property
  value: PropertyValue | undefined
}) {
  if (!value) return NO_PROPERTY_VALUE

  switch (property.type) {
    case 'date':
      return <DatePropertyValueView value={value as DatePropertyValue} />
    case 'number':
      return <NumberPropertyValueView value={value as NumberPropertyValue} />
    case 'person':
      return <PersonPropertyValueView value={value as PersonPropertyValue} />
    case 'select':
      return (
        <SelectPropertyValueView
          property={property}
          value={value as SelectPropertyValue}
        />
      )
    case 'text':
      return <TextPropertyValueView value={value as TextPropertyValue} />
    case 'url':
      return (
        <UrlPropertyValueView
          disabled={disabled}
          isEditing={isEditing}
          value={value as UrlPropertyValue}
        />
      )
  }

  return null
}
