import {
  DocumentPhaseId,
  type DocumentReviewRound,
} from '@blissbook/ui/application/graph'
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle'
import { faCircleHalfStroke } from '@fortawesome/pro-regular-svg-icons/faCircleHalfStroke'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

export type DocumentPhase = {
  id: DocumentPhaseId
  icon: React.ReactNode
  label: string
  statusClassName?: string
  statusText?: string
}

export const draftingPhase: DocumentPhase = {
  id: DocumentPhaseId.Drafting,
  icon: (
    <FontAwesomeIcon
      className='tw-text-gray-500'
      fixedWidth
      icon={faCircleHalfStroke}
    />
  ),
  label: 'Drafting',
}

export const inReviewPhase: DocumentPhase = {
  id: DocumentPhaseId.InReview,
  icon: (
    <FontAwesomeIcon
      className='tw-text-sunshine-700 tw-rotate-180'
      fixedWidth
      icon={faCircleHalfStroke}
    />
  ),
  label: 'In Review',
  statusClassName: 'tw-bg-sunshine-100 tw-text-sunshine-800',
}

export const approvedPhase: DocumentPhase = {
  id: DocumentPhaseId.Approved,
  icon: (
    <FontAwesomeIcon
      className='tw-text-green-700'
      fixedWidth
      icon={faCircleCheck}
    />
  ),
  label: 'Approved',
  statusClassName: 'tw-bg-green-50 tw-text-green-800',
  statusText: 'This version has been approved!',
}

export const rejectedPhase: DocumentPhase = {
  id: DocumentPhaseId.Rejected,
  icon: (
    <FontAwesomeIcon
      className='tw-text-red-700'
      fixedWidth
      icon={faCircleXmark}
    />
  ),
  label: 'Needs Revision',
  statusClassName: 'tw-bg-red-50 tw-text-red-800',
  statusText: 'This version needs revision.',
}

export const noDecisionPhase: DocumentPhase = {
  id: DocumentPhaseId.NoDecision,
  icon: (
    <FontAwesomeIcon
      className='tw-text-gray-500'
      fixedWidth
      icon={faCircleXmark}
    />
  ),
  label: 'No Decision',
  statusClassName: 'tw-bg-gray-200 tw-text-gray-700',
  statusText: 'This version received feedback without a decision on approval.',
}

export const cleanPhase: DocumentPhase = {
  id: DocumentPhaseId.Clean,
  icon: (
    <FontAwesomeIcon className='tw-text-gray-500' fixedWidth icon={faCircle} />
  ),
  label: 'Clean',
}

export const documentPhases = [
  draftingPhase,
  inReviewPhase,
  approvedPhase,
  rejectedPhase,
  noDecisionPhase,
  cleanPhase,
]

const documentPhaseById = new Map(
  documentPhases.map((phase) => [phase.id, phase]),
)

export type DocumentPhaseDocument = {
  activeReviewRound?: { dueDate: string }
  phaseId: DocumentPhaseId
}

export function hasDocumentReviewRoundEnded(dueDate: string) {
  return moment(dueDate).endOf('day').isBefore(new Date())
}

export function getDocumentPhase(document: DocumentPhaseDocument) {
  const { activeReviewRound, phaseId } = document

  if (
    phaseId === DocumentPhaseId.InReview &&
    activeReviewRound &&
    hasDocumentReviewRoundEnded(activeReviewRound.dueDate)
  ) {
    return noDecisionPhase
  }

  return documentPhaseById.get(phaseId)
}

export function getDocumentsPhases(documents: DocumentPhaseDocument[]) {
  const phases = new Set<DocumentPhase>()
  for (const document of documents) {
    const phase = getDocumentPhase(document)
    phases.add(phase)
  }
  return Array.from(phases)
}

export function getReviewRoundEndedAt(
  reviewRound: Pick<DocumentReviewRound, 'dueDate' | 'phaseId' | 'reviewers'>,
) {
  const { dueDate, phaseId } = reviewRound

  // If in review, we've ended, if we're past due
  if (phaseId === DocumentPhaseId.InReview) {
    if (!hasDocumentReviewRoundEnded(dueDate)) return
    return moment(dueDate).toDate()
  }

  // Otherwise, find the date of the first feedback that ended the review
  const endedAts: string[] = []
  for (const reviewer of reviewRound.reviewers) {
    for (const feedback of reviewer.feedbacks) {
      if (feedback.isApproved === null) continue
      endedAts.push(feedback.createdAt)
    }
  }
  return moment.max(endedAts.map((endedAt) => moment(endedAt))).toDate()
}
