import isString from 'lodash/isString'

export type HandbookSettingOverrides = {
  key: string
  defaultValue?: any
}

export type HandbookSettingType =
  | 'boolean'
  | 'color'
  | 'icon'
  | 'image'
  | 'number'
  | 'string'

export type HandbookSetting = {
  key: string
  label: string
  type: HandbookSettingType
  group?: string
  groupKey?: string
  adminOnly?: boolean

  defaultValue?: any
  min?: number
  max?: number
  options?: {
    label: string
    value: any
  }[]
}

export type HandbookSettingKey = string | HandbookSettingOverrides

export const getDefaultValues = (settings: HandbookSetting[] = []): any =>
  settings.reduce((values, setting) => {
    const { defaultValue, key } = setting
    if (defaultValue !== undefined) values[key] = defaultValue
    return values
  }, {} as any)

export const fromSettingKey = (settingKey: HandbookSettingKey) => {
  // If the key is a string, just do a lookup
  if (isString(settingKey))
    return handbookSettings.find((s) => s.key === settingKey)

  // Otherwise, apply overrides
  const { key, ...overrides } = settingKey
  const setting = handbookSettings.find((s) => s.key === key)
  return { ...setting, ...overrides }
}

export const linkColorSetting: HandbookSetting = {
  key: 'linkColor',
  label: 'Link Color',
  type: 'color',
  defaultValue: 'link',
}

export const handbookSettings: HandbookSetting[] = [
  {
    key: 'animation',
    label: 'Animation',
    type: 'boolean',
    defaultValue: true,
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
  },
  {
    key: 'backgroundAnimation',
    label: 'Animation',
    group: 'background',
    groupKey: 'animation',
    type: 'string',
    defaultValue: null,
    options: [
      { label: 'Off', value: null },
      { label: 'Fixed', value: 'fixed' },
      { label: 'Slow Scroll', value: 'scroll' },
    ],
  },
  {
    key: 'backgroundColor',
    label: 'Color',
    group: 'background',
    groupKey: 'color',
    type: 'color',
    defaultValue: 'white',
  },
  {
    key: 'backgroundImage',
    label: 'Image',
    group: 'background',
    groupKey: 'image',
    type: 'image',
    defaultValue: null,
  },
  {
    key: 'backgroundOverlayColor',
    label: 'Overlay Color',
    group: 'background',
    groupKey: 'overlayColor',
    type: 'color',
    defaultValue: 'white',
  },
  {
    key: 'backgroundOverlayOpacity',
    label: 'Overlay Opacity %',
    group: 'background',
    groupKey: 'overlayOpacity',
    type: 'number',
    min: 0,
    max: 100,
    defaultValue: 0,
  },
  {
    key: 'bottom',
    label: 'Bottom',
    type: 'string',
    defaultValue: null,
    options: [
      { label: 'Flat', value: null },
      { label: 'Chevron', value: 'chevron' },
      { label: 'Gradient', value: 'gradient' },
    ],
  },
  {
    key: 'bottomArrow',
    label: 'Arrow',
    type: 'boolean',
    defaultValue: false,
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
  },
  {
    key: 'headingBackgroundImage',
    label: 'Heading Background',
    type: 'image',
    defaultValue: null,
  },
  {
    key: 'height',
    label: 'Height',
    type: 'string',
    defaultValue: 'tall',
    options: [
      { label: 'Short', value: 'short' },
      { label: 'Medium', value: 'medium' },
      { label: 'Tall', value: 'tall' },
      { label: 'Extra Tall', value: 'extra-tall' },
    ],
  },
  {
    key: 'entryAngle',
    label: 'Item Angle',
    type: 'string',
    defaultValue: null,
    options: [
      { label: 'None', value: null },
      { label: 'Ascending', value: 'asc' },
      { label: 'Descending', value: 'desc' },
    ],
  },
  {
    key: 'entryAnimation',
    label: 'Item Animation',
    type: 'boolean',
    defaultValue: true,
    adminOnly: true,
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
  },
  linkColorSetting,
  {
    key: 'maxColumns',
    label: 'Max Columns',
    type: 'number',
    defaultValue: 3,
    options: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
    ],
  },
  {
    key: 'showChapterIcon',
    label: 'Arrow Icon',
    type: 'icon',
    defaultValue: true,
  },
  {
    key: 'signatureType',
    label: 'Signature',
    type: 'string',
    defaultValue: 'text',
    options: [
      { label: 'Script Text', value: 'text' },
      { label: 'Image', value: 'image' },
    ],
  },
  {
    key: 'textShadow',
    label: 'Text Shadow',
    type: 'boolean',
    defaultValue: false,
    adminOnly: true,
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
  },
  {
    key: 'titleHighlight',
    label: 'Highlight',
    group: 'title',
    groupKey: 'highlight',
    type: 'string',
    defaultValue: 'normal',
    options: [
      { label: 'None', value: 'normal' },
      { label: 'Bordered', value: 'bordered' },
      { label: 'Background Color', value: 'background' },
    ],
  },
  {
    key: 'titleHighlightColor',
    label: 'Highlight Color',
    group: 'title',
    groupKey: 'highlightColor',
    type: 'color',
    defaultValue: 'white',
  },
  {
    key: 'textColor',
    label: 'Text Color',
    type: 'color',
    defaultValue: null,
  },
  {
    key: 'textBackgroundColor',
    label: 'Text Background Color',
    type: 'color',
    defaultValue: null,
  },
  {
    key: 'textBorderColor',
    label: 'Text Border Color',
    type: 'color',
    defaultValue: null,
  },
  {
    key: 'textBorderStyle',
    label: 'Text Border Style',
    type: 'string',
    defaultValue: 'solid',
    options: [
      { label: 'Solid', value: 'solid' },
      { label: 'Dashed', value: 'dashed' },
      { label: 'Dotted', value: 'dotted' },
      { label: 'Double', value: 'double' },
    ],
  },
  {
    key: 'textPadding',
    label: 'Text Content Padding',
    type: 'string',
    defaultValue: 'none',
    options: [
      { label: 'None', value: 'none' },
      { label: 'Small', value: 'sm' },
      { label: 'Medium', value: 'md' },
      { label: 'Large', value: 'lg' },
    ],
  },
  {
    key: 'textBorderWidth',
    label: 'Text Border Width',
    type: 'string',
    defaultValue: 'none',
    options: [
      { label: 'None', value: 'none' },
      { label: 'Extra Thin', value: '1px' },
      { label: 'Thin', value: '2px' },
      { label: 'Medium', value: '4px' },
      { label: 'Thick', value: '8px' },
      { label: 'Extra Thick', value: '16px' },
    ],
  },
  {
    key: 'textBorderRadius',
    label: 'Text Border Radius',
    type: 'string',
    defaultValue: 'none',
    options: [
      { label: 'None', value: 'none' },
      { label: 'Small', value: 'sm' },
      { label: 'Medium', value: 'md' },
      { label: 'Large', value: 'lg' },
    ],
  },
]
