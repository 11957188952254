import { useDimensions } from '@blissbook/ui/util/hooks'
import { detect } from 'detect-browser'
import React, { useState } from 'react'

// Detect the browser
const browser = detect()
const isWindows = browser && browser.os.indexOf('Windows') === 0

// Ensure the height scale to the width of the viewBox
const SvgAutoHeight = ({ transitionDuration, ...props }) => {
  const { viewBox } = props
  const [node, setNode] = useState()
  const { width } = useDimensions(node) || {}
  const [, , svgWidth, svgHeight] = viewBox
    .split(' ')
    .map((v) => Number.parseInt(v, 10))
  const height = width && (width / svgWidth) * svgHeight
  return (
    <svg
      {...props}
      css={{
        'circle, path, rect': {
          transition: `all ${transitionDuration}ms linear`,
        },
      }}
      preserveAspectRatio='none'
      ref={setNode}
      style={{ height }}
    />
  )
}

const MacBrowserBar = ({ darkMode, ...props }) => {
  const mode = darkMode
    ? {
        backgroundColor: '#333333',
        searchBarColor: '#9A9A9A',
        tabColor: '#727272',
      }
    : {
        backgroundColor: '#E3E3E3',
        searchBarColor: '#E3E3E3',
        tabColor: '#F6F6F6',
      }

  return (
    <SvgAutoHeight {...props} preserveAspectRatio='none' viewBox='0 0 461 37'>
      <path
        d='M5,0 L456,0 C458.761424,-5.07265313e-16 461,2.23857625 461,5 L461,20 L0,20 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 Z'
        fill={mode.backgroundColor}
      />
      <circle fill='#FD5D5D' cx='12.5' cy='10.5' r='3.5' />
      <circle fill='#50E3C2' cx='22.5' cy='10.5' r='3.5' />
      <circle fill='#FFBE45' cx='32.5' cy='10.5' r='3.5' />
      <path
        d='M0,18.2270323 L46.4449725,18.2270323 L48.9608405,6.18215237 C49.3480759,4.3282382 50.9824144,3 52.8763386,3 L107.796762,3 C109.690686,3 111.325024,4.3282382 111.71226,6.18215237 L114.228128,18.2270323 L461,18.2270323 L461,37 L0,37 L0,18.2270323 Z'
        fill={mode.tabColor}
      />
      <rect
        fill={mode.searchBarColor}
        x='9'
        y='23'
        width='293'
        height='11'
        rx='5'
      />
    </SvgAutoHeight>
  )
}

const WindowsBrowserBar = ({ darkMode, ...props }) => {
  const mode = darkMode
    ? {
        backgroundColor: '#333333',
        searchBarColor: '#9A9A9A',
        tabColor: '#727272',
      }
    : {
        backgroundColor: '#DADADA',
        searchBarColor: '#DADADA',
        tabColor: '#f2f2f2',
      }

  return (
    <SvgAutoHeight {...props} preserveAspectRatio='none' viewBox='0 0 461 37'>
      <path
        d='M5,0 L456,0 C458.761424,-5.07265313e-16 461,2.23857625 461,5 L461,20 L0,20 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 Z'
        fill={mode.backgroundColor}
      />
      <path
        d='M0,18.2270323 L6.44497247,18.2270323 L8.96084051,6.18215237 C9.34807587,4.3282382 10.9824144,3 12.8763386,3 L67.7967617,3 C69.6906859,3 71.3250244,4.3282382 71.7122597,6.18215237 L74.2281278,18.2270323 L461,18.2270323 L461,37 L0,37 L0,18.2270323 Z'
        fill={mode.tabColor}
      />
      <rect
        fill={mode.searchBarColor}
        x='9'
        y='23'
        width='293'
        height='11'
        rx='5'
      />
      <rect
        stroke='#9A9A9A'
        fill='none'
        x='402.5'
        y='11.5'
        width='7'
        height='1'
      />
      <rect
        stroke='#9A9A9A'
        fill='none'
        x='422.5'
        y='7.5'
        width='7'
        height='5'
      />
      <path
        d='M446.807187,9.50001073 L449.706632,12.399456 C449.779737,12.4725836 449.779737,12.5914188 449.706632,12.6645234 L449.176223,13.1949329 C449.103118,13.2680376 448.984283,13.2680376 448.911155,13.1949329 L446.01171,10.2954877 L443.112036,13.1949329 C443.038908,13.2680376 442.920073,13.2680376 442.846945,13.1949329 L442.316559,12.6645234 C442.243431,12.5914188 442.243431,12.4725836 442.316559,12.399456 L445.216233,9.50001073 L442.316559,6.60033655 C442.243431,6.52720898 442.243431,6.40837381 442.316559,6.33524624 L442.847197,5.80485963 C442.920302,5.73173205 443.039137,5.73173205 443.112265,5.80485963 L446.01171,8.7045338 L448.911155,5.80508851 C448.984283,5.73198382 449.103118,5.73198382 449.176223,5.80508851 L449.706632,6.33549801 C449.779737,6.40860269 449.779737,6.52743786 449.706632,6.60056543 L446.807187,9.50001073 Z'
        fill='#9A9A9A'
        fillRule='nonzero'
      />
    </SvgAutoHeight>
  )
}

// DesktopBrowser
export const DesktopBrowser = React.forwardRef(
  /**
   * @param {Record<string, any>} props
   */
  function DesktopBrowser(
    { children, darkMode, height, transitionDuration = 500, ...props },
    ref,
  ) {
    const borderRadius = 5
    const BrowserBar = isWindows ? WindowsBrowserBar : MacBrowserBar
    return (
      <div
        {...props}
        ref={ref}
        css={{
          borderRadius,
          display: 'flex',
          flexDirection: 'column',
          overfow: 'hidden',
          zIndex: 1,
        }}
      >
        <BrowserBar
          css={{
            borderTopLeftRadius: borderRadius,
            borderTopRightRadius: borderRadius,
          }}
          darkMode={darkMode}
          transitionDuration={transitionDuration}
        />

        <div
          children={children}
          css={{
            background: 'white',
            borderBottomLeftRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            height,
            overflow: height ? 'hidden' : undefined,
            position: 'relative',
            zIndex: 0,
          }}
        />
      </div>
    )
  },
)

export const MobileBrowser = ({
  children,
  darkMode = false,
  height,
  transitionDuration = 500,
  ...props
}) => {
  const mode = darkMode
    ? {
        borderColor: '#333333',
        speakerColor: '#727272',
      }
    : {
        borderColor: '#EBEBEB',
        speakerColor: '#B3B3B3',
      }

  const borderRadius = 5
  const borderWidthX = 5
  const borderWidthY = 25

  const speaker = {
    height: 4,
    width: 40,
  }

  return (
    <div
      {...props}
      css={{
        background: mode.borderColor,
        borderRadius,
        height,
        overflow: height ? 'hidden' : undefined,
        paddingBottom: borderWidthY,
        paddingLeft: borderWidthX,
        paddingRight: borderWidthX,
        paddingTop: borderWidthY,
        position: 'relative',
        transition: `all ${transitionDuration}ms linear`,
        zIndex: 0,
      }}
    >
      <div
        css={{
          backgroundColor: mode.speakerColor,
          borderRadius,
          height: speaker.height,
          left: `calc(50% - ${speaker.width / 2}px)`,
          position: 'absolute',
          top: borderWidthY / 2 - speaker.height / 2,
          transition: `all ${transitionDuration}ms linear`,
          width: speaker.width,
        }}
      />

      <div
        children={children}
        css={{
          background: 'white',
          height: '100%',
          overflow: 'hidden',
        }}
      />
    </div>
  )
}
