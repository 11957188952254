import type {
  Property,
  PropertyValueInput,
  TextPropertyValueInput,
} from '@blissbook/lib/properties'
import { Input } from '@blissbook/ui/lib'
import { useState } from 'react'
import type { PropertyValueEditorProps } from './PropertyValueEditor'

export function TextPropertyValueEditor({
  initialValue,
  onChange,
  property,
}: PropertyValueEditorProps & {
  initialValue: TextPropertyValueInput | null
  onChange: (value: PropertyValueInput | null) => void
  property: Property
}) {
  const [value, setValue] = useState(initialValue)

  function handleChange(text: string) {
    const value = text ? { text } : null
    onChange(value)
    setValue(value)
  }

  return (
    <Input
      autoFocus
      className='tw-w-full'
      debounce={500}
      onChangeValue={handleChange}
      placeholder={`Enter ${property.label}...`}
      value={value ? value.text : ''}
    />
  )
}
