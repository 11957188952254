import { Dropdown, Tooltip } from '@blissbook/ui/lib'
import classnames from 'classnames'
import type { Selection } from 'prosemirror-state'
import { useEffect, useState } from 'react'
import type { Editor } from '../editor'
import { getSelectedFontSize } from '../selection'
import { hasExtension } from '../util'
import { canSelectionMarkText } from './text'
import type { ToolComponent } from './types'

const fontSizes = [
  8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96, 120,
]

type FontSizeToolState = {
  editor: Editor
  selection: Selection
  value: number
}

export const FontSizeTool: ToolComponent = ({
  className,
  editor,
  ...props
}) => {
  const [inputNode, setInputNode] = useState<HTMLInputElement | null>()
  const [refNode, setRefNode] = useState<HTMLDivElement | null>()
  const [state, setState] = useState<FontSizeToolState>()
  const selection = state ? state.selection : editor?.state.selection
  const value = state ? state.value : getSelectedFontSize(editor)
  const canMark =
    hasExtension(editor, 'fontSize') && canSelectionMarkText(selection)

  useEffect(() => {
    if (inputNode) inputNode.select()
  }, [inputNode])

  const setOpen = (isOpen: boolean) => {
    if (isOpen) {
      setState({ editor, selection, value })
    } else {
      setState(undefined)
    }
  }

  const setFontSize = (value: number) => {
    const { editor } = state
    const fontSize = value + 'px'
    editor.chain().focus().setFontSize(fontSize).run()
    setOpen(false)
  }

  return (
    <Dropdown.Provider isOpen={!!state} refNode={refNode} setOpen={setOpen}>
      <div
        {...props}
        className={classnames('tw-flex', className)}
        ref={setRefNode}
        style={{ width: 50 }}
      >
        <Choose>
          <When condition={!state}>
            <Tooltip content='Font size' disabled={!canMark}>
              <button
                type='button'
                className='dropdown-toggle btn btn-sm btn-tool tw-flex tw-justify-between tw-py-0.5 tw-pl-1.5 tw-pr-0.5 tw-text-sm tw-w-full'
                disabled={!canMark}
                onClick={() => {
                  setOpen(true)
                }}
              >
                {value}
                <Dropdown.Caret
                  className='tw-transform-none'
                  style={{ fontSize: 10 }}
                />
              </button>
            </Tooltip>
          </When>
          <Otherwise>
            <input
              type='number'
              className='form-control form-control-sm hide-spinner tw-flex-1 tw-rounded-none'
              onChange={(event) => {
                const node = event.target as HTMLInputElement
                const value = Number.parseInt(node.value, 10)
                setState({ ...state, value })
              }}
              onKeyDown={(event) => {
                const node = event.target as HTMLInputElement
                if (event.keyCode === 13) {
                  event.preventDefault()
                  const value = Number.parseInt(node.value, 10)
                  setFontSize(value)
                }
              }}
              value={value}
              ref={setInputNode}
            />

            <button
              type='button'
              className='dropdown-toggle btn btn-sm btn-tool tw-bg-gray-300 tw-border-l-0 tw-min-w-0 tw-px-0 tw-rounded-none tw-text-sm'
              onClick={() => {
                setOpen(false)
              }}
              style={{ width: 15 }}
            >
              <Dropdown.Caret
                className='tw-transform-none'
                style={{ fontSize: 10 }}
              />
            </button>
          </Otherwise>
        </Choose>
      </div>

      <Dropdown.Menu>
        {fontSizes.map((size) => (
          <Dropdown.Item
            key={size}
            onClick={() => {
              setOpen(false)
              setFontSize(size)
            }}
          >
            {size}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
