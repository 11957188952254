import {
  type Property,
  type PropertyTargetType,
  type PropertyType,
  propertyTypes,
} from '@blissbook/lib/properties'
import { Tooltip } from '@blissbook/ui/lib'
import { handleError } from '@blissbook/ui/util/errors'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faRotateLeft } from '@fortawesome/pro-regular-svg-icons/faRotateLeft'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import { Button } from '../buttons'
import { Dropdown } from '../popper'
import { type ColumnState, pickColumnState } from './ColumnState'
import { PropertiesList } from './PropertiesList'
import {
  type ArchivePropertyFunction,
  type CreatePropertyFunction,
  type DeletePropertyFunction,
  PropertyEditor,
  type UnarchivePropertyFunction,
  type UpdatePropertyFunction,
} from './PropertyEditor'

export type PropertyColumn = ColumnState & {
  icon?: IconProp
  label: string
  property?: Property
  required?: boolean
}

export function PropertiesEditor({
  archiveProperty,
  columns,
  createProperty,
  deleteProperty,
  isSourceOrganization,
  onChangeColumns,
  properties = [],
  resetColumns,
  targetType,
  unarchiveProperty,
  updateProperty,
}: {
  archiveProperty?: ArchivePropertyFunction
  columns: PropertyColumn[]
  createProperty?: CreatePropertyFunction
  deleteProperty?: DeletePropertyFunction
  isSourceOrganization?: boolean
  onChangeColumns: (columnStates: ColumnState[]) => void
  properties?: Property[]
  resetColumns?: () => void
  targetType?: PropertyTargetType
  unarchiveProperty?: UnarchivePropertyFunction
  updateProperty?: UpdatePropertyFunction
}) {
  const [activePropertyId, setActivePropertyId] = useState<string | undefined>()
  const [showArchivedProperties, setShowArchivedProperties] = useState(false)
  const [showNewProperty, setShowNewProperty] = useState(false)

  const activeProperty = useMemo(() => {
    for (const column of columns) {
      if (column.property?.id === activePropertyId) {
        return column.property
      }
    }
  }, [columns, activePropertyId])

  if (activePropertyId) {
    if (!activeProperty) {
      return (
        <div className='tw-flex tw-items-center tw-gap-2 tw-mb-2'>
          <Button
            className='btn-icon tw-p-1'
            onClick={() => setActivePropertyId(undefined)}
          >
            <FontAwesomeIcon icon='arrow-left' />
          </Button>
          <div className='tw-font-semibold'>Edit Property</div>
        </div>
      )
    }

    return (
      <PropertyEditor
        archiveProperty={archiveProperty}
        isSourceOrganization={isSourceOrganization}
        onBack={() => setActivePropertyId(undefined)}
        property={activeProperty}
        updateProperty={updateProperty}
      />
    )
  }

  const archivedProperties = properties.filter(
    (property) => property.archivedAt,
  )

  async function handleUnarchiveProperty(property: Property) {
    try {
      const isLast = archivedProperties.length === 1
      await unarchiveProperty(property.id)
      setShowArchivedProperties(!isLast)
    } catch (error) {
      handleError(error)
    }
  }

  async function handleDeleteProperty(property: Property) {
    try {
      const isLast = archivedProperties.length === 1
      await deleteProperty(property.id)
      setShowArchivedProperties(!isLast)
    } catch (error) {
      handleError(error)
    }
  }

  function renderArchivedProperty(property: Property) {
    const meta = propertyTypes[property.type]

    return (
      <div
        className='tw-flex tw-items-center tw-justify-between tw-gap-2 dropdown-item-text'
        key={property.id}
      >
        <div>
          <FontAwesomeIcon className='dropdown-item-icon' icon={meta.icon} />
          {property.label}
        </div>

        <div className='tw-flex tw-items-center tw-gap-1'>
          {unarchiveProperty && (
            <Tooltip content='Restore Property'>
              <Button
                className='btn-icon tw-p-1'
                onClick={() => handleUnarchiveProperty(property)}
              >
                <FontAwesomeIcon icon={['far', 'recycle']} />
              </Button>
            </Tooltip>
          )}
          {deleteProperty && (
            <Tooltip content='Permanently Delete Property'>
              <Button
                className='btn-icon tw-p-1'
                onClick={() => handleDeleteProperty(property)}
              >
                <FontAwesomeIcon icon={['far', 'trash-alt']} />
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    )
  }

  if (showArchivedProperties) {
    return (
      <div>
        <div className='tw-flex tw-items-center tw-gap-2 tw-mb-2'>
          <Button
            className='btn-icon tw-p-1'
            onClick={() => setShowArchivedProperties(false)}
          >
            <FontAwesomeIcon icon='arrow-left' />
          </Button>
          <div className='tw-font-semibold'>Archived Properties</div>
        </div>

        {archivedProperties.map(renderArchivedProperty)}
      </div>
    )
  }

  async function handleCreateProperty(type: PropertyType) {
    try {
      // Create the property
      const meta = propertyTypes[type]
      const property = await createProperty({
        type,
        label: meta.label,
        allowMultiple: false,
        targetType,
        options: meta.hasOptions ? [] : undefined,
      })

      // Add to columns keys so it shows up in the table
      onChangeColumns([
        ...columns.map(pickColumnState),
        { field: 'property', path: property.id },
      ])

      // Update the UI
      setActivePropertyId(property.id)
      setShowNewProperty(false)
    } catch (error) {
      handleError(error)
    }
  }

  // Create a dropdown to select the type of property to create
  if (showNewProperty) {
    return (
      <div>
        <div className='tw-flex tw-items-center tw-gap-2 tw-mb-2'>
          <Button
            className='btn-icon tw-p-1'
            onClick={() => setShowNewProperty(false)}
          >
            <FontAwesomeIcon icon='arrow-left' />
          </Button>
          <div className='tw-font-semibold'>Add Property</div>
        </div>

        {Object.entries(propertyTypes).map(([type, meta]) => (
          <Dropdown.Item
            key={type}
            onClick={async (event) => {
              event.stopPropagation()
              await handleCreateProperty(type as PropertyType)
            }}
          >
            <FontAwesomeIcon className='dropdown-item-icon' icon={meta.icon} />
            {meta.label}
          </Dropdown.Item>
        ))}
      </div>
    )
  }

  return (
    <div>
      <PropertiesList
        columns={columns}
        onChangeColumns={onChangeColumns}
        onSelectPropertyId={setActivePropertyId}
      />

      {archivedProperties.length > 0 && deleteProperty && (
        <Dropdown.Item
          className='tw-flex tw-items-center tw-justify-between tw-gap-2 tw-text-gray-500 tw-my-1'
          onClick={(event) => {
            event.stopPropagation()
            setShowArchivedProperties(true)
          }}
        >
          <div className='tw-flex tw-items-center tw-gap-2'>
            <FontAwesomeIcon icon={['far', 'trash-alt']} />
            Archived Properties
          </div>

          <div className='tw-flex tw-items-center tw-gap-4'>
            {archivedProperties.length}

            <FontAwesomeIcon icon='angle-right' />
          </div>
        </Dropdown.Item>
      )}

      {createProperty && (
        <>
          <div className='dropdown-divider' />
          <Dropdown.Item
            className='tw-text-gray-500'
            onClick={(event) => {
              event.stopPropagation()
              setShowNewProperty(true)
            }}
          >
            <FontAwesomeIcon className='dropdown-item-icon' icon='plus' />
            Add Property
          </Dropdown.Item>
        </>
      )}

      {resetColumns && (
        <>
          <div className='dropdown-divider' />
          <Dropdown.Item
            className='tw-text-gray-500'
            onClick={(event) => {
              event.stopPropagation()
              resetColumns()
            }}
          >
            <FontAwesomeIcon className='tw-mr-2' icon={faRotateLeft} />
            Reset Columns
          </Dropdown.Item>
        </>
      )}
    </div>
  )
}
