import config from '@blissbook/ui-config'
import { cx } from '@emotion/css'
import type React from 'react'

export function Environment({
  fixed,
  style,
}: {
  fixed?: boolean
  style?: React.CSSProperties
}) {
  if (config.env.production) {
    return null
  }

  return (
    <div
      className={cx(
        'tw-h-1 tw-bg-red-500',
        fixed && 'tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-z-10',
      )}
      style={style}
    />
  )
}
