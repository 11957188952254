import type {
  NumberPropertyValueInput,
  Property,
  PropertyValueInput,
} from '@blissbook/lib/properties'
import { Input } from '@blissbook/ui/lib'
import { useState } from 'react'
import type { PropertyValueEditorProps } from './PropertyValueEditor'

export function NumberPropertyValueEditor({
  initialValue,
  onChange,
  property,
}: PropertyValueEditorProps & {
  initialValue: NumberPropertyValueInput | null
  onChange: (value: PropertyValueInput | null) => void
  property: Property
}) {
  const [value, setValue] = useState(initialValue)

  function handleChange(text: string) {
    const value = text ? { number: Number.parseFloat(text) } : null
    onChange(value)
    setValue(value)
  }

  return (
    <Input
      autoFocus
      className='tw-w-full'
      debounce={500}
      onChangeValue={handleChange}
      placeholder={`Enter ${property.label}...`}
      type='number'
      value={value ? value.number.toString() : ''}
    />
  )
}
