import { colors } from '@blissbook/ui/branding'
import { css } from '@emotion/react'
import range from 'lodash/range'
import type React from 'react'

interface DotStepProps {
  isSelected: boolean
  onClick: () => void
}

const DotStep = ({ isSelected, ...props }: DotStepProps) => (
  <div
    css={css`
      cursor: pointer;
      margin: 3px;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      display: inline-block;
      transition: 0.3s ease-in-out;
      transform: scale(0.85);
      background-color: #dadada;

      ${
        isSelected &&
        css`
        transform: scale(1);
        background-color: ${colors['sunshine-500']};
      `
      }
    `}
    {...props}
  />
)

export interface DotsStepperProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  currentStep: number
  stepCount: number
  onClickStep?: (step: number) => void
}

export const DotsStepper = ({
  currentStep,
  stepCount,
  onClickStep,
  ...props
}: DotsStepperProps) => (
  <div {...props}>
    {range(stepCount).map((index) => (
      <DotStep
        key={index}
        isSelected={index === currentStep}
        onClick={() => {
          if (onClickStep) {
            onClickStep(index)
          }
        }}
      />
    ))}
  </div>
)
