import type {
  DatePropertyValue,
  PropertyValueInput,
} from '@blissbook/lib/properties'
import { DateInput } from '@blissbook/ui/lib'
import type { PropertyValueEditorProps } from './PropertyValueEditor'

export function DatePropertyValueEditor({
  onChange,
  value,
}: PropertyValueEditorProps & {
  onChange: (value: PropertyValueInput | null) => void
  value: DatePropertyValue | null
}) {
  return (
    <DateInput
      autoFocus
      className='tw-w-full'
      onChangeValue={(date) => onChange(date ? { date } : null)}
      value={value?.date}
    />
  )
}
