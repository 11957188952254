import {
  type PdfNodeAttributes,
  embedAlignsByType,
} from '@blissbook/lib/document'
import { PdfNode } from '@blissbook/lib/document/schema/nodes'
import { type Size, bindResize, resizeEdges } from '@blissbook/ui/editor/resize'
import { PdfViewer } from '@blissbook/ui/lib/pdf'
import ReactDOM from 'react-dom'
import type { Editor } from '../../editor'
import { wrapSelectableNodeView } from '../selection'
import { getNodeViewPos } from '../util'

export const PdfEditorNode = PdfNode.extend({
  addNodeView() {
    return (props) => {
      const { editor, getPos, node } = props
      const attrs = node.attrs as PdfNodeAttributes
      const { expression, height, showThumbnail, src, width } = attrs
      const align = embedAlignsByType[attrs.align]
      const thumbnailUrl = src + '/thumbnail.png'

      const dom = document.createElement('div')
      dom.classList.add('rw-embed')
      if (align) dom.classList.add(align.className)
      if (expression) dom.dataset.expression = expression

      const pdfEl = document.createElement('rw-pdf')
      pdfEl.className = 'rw-embed-asset tw-h-full tw-w-full'
      dom.appendChild(pdfEl)

      function renderPdf({ height, width }: Size) {
        dom.style.height = height + 'px'
        dom.style.width = width + 'px'

        ReactDOM.render(
          <PdfViewer
            style={{ height }}
            thumbnailUrl={showThumbnail ? thumbnailUrl : undefined}
            url={src}
            width={width}
          />,
          pdfEl,
        )
      }

      renderPdf({ height, width })

      bindResize(dom, {
        edges: resizeEdges,
        onResize(resizeEl) {
          ;(editor as Editor).onResize(resizeEl)
        },
        onSubmit(size) {
          const pos = getNodeViewPos(getPos)
          editor
            .chain()
            .updateNodeAttributes(pos, size)
            .setNodeSelection(pos)
            .run()
        },
        setSize(size) {
          renderPdf(size)

          size.height = dom.offsetHeight
          size.width = dom.offsetWidth
          renderPdf(size)
          return size
        },
        showSize: true,
      })

      return wrapSelectableNodeView(dom, props)
    }
  },
})
