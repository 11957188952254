import {
  type CreatePropertyInput,
  type Property,
  type PropertyOption,
  type UpdatePropertyInput,
  propertyTypes,
} from '@blissbook/lib/properties'
import { type DragItem, useSortable } from '@blissbook/ui/hooks/useSortable'
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  ScrollContainer,
} from '@blissbook/ui/lib'
import { omitTypename } from '@blissbook/ui/lib/graphql'
import { handleError } from '@blissbook/ui/util/errors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import debounce from 'lodash/debounce'
import { nanoid } from 'nanoid'
import type React from 'react'
import { useCallback, useState } from 'react'
import { PropertyOptionView, propertyOptionsColors } from './PropertyOptionView'

export type ArchivePropertyFunction = (propertyId: string) => Promise<void>

export type CreatePropertyFunction = (
  property: CreatePropertyInput,
) => Promise<Property>

export type DeletePropertyFunction = (propertyId: string) => Promise<void>

export type UnarchivePropertyFunction = (propertyId: string) => Promise<void>

export type UpdatePropertyFunction = (
  propertyId: string,
  property: UpdatePropertyInput,
) => Promise<void>

function PropertyOptionItem({
  canEdit,
  index,
  onChange,
  onDelete,
  onDrop,
  onMove,
  option,
}: {
  canEdit?: boolean
  index: number
  onChange: (changes: Partial<PropertyOption>) => Promise<void>
  onDelete: () => Promise<void>
  onDrop: (item: DragItem) => void
  onMove: (dragIndex: number, hoverIndex: number) => Promise<void>
  option: PropertyOption
}) {
  const [isEditing, setEditing] = useState(false)
  const [label, setLabel] = useState(option.label)

  const { ref, isDragging } = useSortable({
    disabled: !canEdit,
    id: option.id,
    index,
    onDrop,
    onMove,
    type: 'option',
  })

  async function onChangeLabel(label: string) {
    if (!label) return
    setLabel(label)
    await onChange({ label })
  }

  async function onChangeColor(color: string) {
    await onChange({ color })
  }

  function onSetEditing(isEditing: boolean) {
    if (!canEdit) return
    setEditing(isEditing)
  }

  return (
    <Dropdown.Provider isOpen={isEditing} setOpen={onSetEditing}>
      <Dropdown.ToggleButton
        caret={false}
        className='dropdown-item tw-flex-1 tw-flex tw-items-center tw-px-1'
        disabled={!canEdit}
        onClick={(event) => {
          event.stopPropagation()
        }}
        ref={ref as unknown as React.RefObject<HTMLButtonElement>}
        style={{
          opacity: isDragging ? 0 : 1,
        }}
      >
        {canEdit && (
          <FontAwesomeIcon
            className='dropdown-item-icon'
            icon={['far', 'grip-dots-vertical']}
          />
        )}

        <div className='tw-flex-1 tw-min-w-0'>
          <PropertyOptionView className='tw-max-w-full' option={option} />
        </div>
      </Dropdown.ToggleButton>
      <Dropdown.Menu className='tw-p-3' sameWidth>
        <Input
          autoFocus
          className='tw-flex-1 form-control form-control-sm tw-mb-2'
          onChangeValue={onChangeLabel}
          placeholder='Label'
          value={label}
        />

        <Dropdown.Header>Colors</Dropdown.Header>

        {propertyOptionsColors.map((color) => (
          <Dropdown.Item
            active={option.color === color.value}
            checked={option.color === color.value}
            className='tw-flex tw-items-center tw-justify-between'
            key={color.value}
            onClick={async (event) => {
              event.stopPropagation()
              await onChangeColor(color.value)
            }}
          >
            <div className='tw-flex-1 tw-flex tw-items-center'>
              <FontAwesomeIcon
                className='dropdown-item-icon tw-text-lg'
                icon='square'
                style={{ color: color.value }}
              />

              <div className='tw-flex-1 ellipsis'>{color.label}</div>
            </div>
          </Dropdown.Item>
        ))}

        <Dropdown.Divider />

        <Dropdown.Item onClick={onDelete}>
          <FontAwesomeIcon
            className='dropdown-item-icon'
            icon={['far', 'trash-alt']}
          />
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}

export function PropertyEditor({
  archiveProperty,
  isSourceOrganization,
  onBack,
  property,
  updateProperty,
}: {
  archiveProperty?: ArchivePropertyFunction
  isSourceOrganization?: boolean
  onBack?: () => void
  property: Property | undefined
  updateProperty?: UpdatePropertyFunction | undefined
}) {
  const canArchive = !!archiveProperty
  const canEdit = !!updateProperty
  const [allowMultiple, setAllowMultiple] = useState(property.allowMultiple)
  const [copyToLinkedOrganization, setCopyToLinkedOrganization] = useState(
    property.copyToLinkedOrganization,
  )
  const [label, setLabel] = useState(property.label)
  const [newOptionLabel, setNewOptionLabel] = useState('')
  const [showNewOption, setShowNewOption] = useState(false)
  const propertyType = propertyTypes[property.type]

  // Sorting options
  const options = property.options || []
  const [sortedOptions = options, setSortedOptions] =
    useState<PropertyOption[]>()

  async function handleArchiveProperty() {
    try {
      await archiveProperty(property.id)
      onBack?.()
    } catch (error) {
      handleError(error)
    }
  }

  async function handleUpdateProperty(changes: Partial<UpdatePropertyInput>) {
    const { allowMultiple, label, options, copyToLinkedOrganization } = property
    try {
      await updateProperty(property.id, {
        allowMultiple,
        copyToLinkedOrganization,
        label,
        options: options?.map(omitTypename),
        ...changes,
      })
    } catch (error) {
      handleError(error)
    }
  }

  async function setOptions(options: PropertyOption[]) {
    try {
      await handleUpdateProperty({
        options: options.map(omitTypename),
      })
    } catch (error) {
      handleError(error)
    }
  }

  async function handleAddOption(label: string) {
    await setOptions([...property.options, { id: nanoid(), label }])
    setNewOptionLabel('')
  }

  async function handleDeleteOption(id: string) {
    await setOptions(property.options.filter((option) => option.id !== id))
  }

  async function handleMoveOption(dragIndex: number, hoverIndex: number) {
    const options = [...sortedOptions]
    const dragOption = options[dragIndex]
    options.splice(dragIndex, 1)
    options.splice(hoverIndex, 0, dragOption)
    await setSortedOptions(options)
  }

  async function handleDropOption() {
    await setOptions(sortedOptions)
    setSortedOptions(undefined)
  }

  async function handleUpdateOption(
    id: string,
    changes: Partial<PropertyOption>,
  ) {
    await setOptions(
      property.options.map((option) =>
        option.id === id ? { ...option, ...changes } : option,
      ),
    )
  }

  const handleUpdatePropertyDebounced = useCallback(
    debounce((changes: Partial<UpdatePropertyInput>) => {
      handleUpdateProperty(changes)
    }, 200),
    [handleUpdateProperty],
  )

  return (
    <div>
      <div className='tw-flex tw-items-center tw-gap-2 tw-mb-2'>
        {onBack && (
          <Button className='btn-icon tw-p-1' onClick={onBack}>
            <FontAwesomeIcon icon='arrow-left' />
          </Button>
        )}
        <div className='tw-font-semibold'>
          {canEdit ? 'Edit Property' : 'Property'}
        </div>
      </div>

      <div className='tw-flex tw-flex-col tw-gap-1 tw-px-1'>
        <Input
          autoFocus
          className='form-control tw-mb-1'
          debounce={200}
          disabled={!canEdit}
          onChangeValue={async (label: string) => {
            if (!label) return
            setLabel(label)
            await handleUpdatePropertyDebounced({ label })
          }}
          placeholder='Label'
          value={label}
        />

        <div className='tw-flex tw-items-center tw-justify-between tw-gap-2'>
          <div>Type</div>
          <div className='tw-flex tw-items-center tw-gap-2'>
            <FontAwesomeIcon icon={propertyType.icon} />
            {propertyType.label}
          </div>
        </div>

        {propertyType.allowMultiple && (
          <div className='tw-flex tw-items-center tw-justify-between tw-gap-2'>
            <div>Allow Multiple</div>
            <div className='tw-flex tw-items-center tw-gap-2'>
              <Checkbox
                disabled={!canEdit}
                onChangeValue={async (allowMultiple) => {
                  setAllowMultiple(allowMultiple)
                  await handleUpdateProperty({ allowMultiple })
                }}
                value={allowMultiple}
              />
            </div>
          </div>
        )}

        {isSourceOrganization && (
          <div className='tw-flex tw-items-center tw-justify-between tw-gap-2'>
            <div>Copy to Client</div>
            <div className='tw-flex tw-items-center tw-gap-2'>
              <Checkbox
                disabled={!canEdit}
                onChangeValue={async (copyToLinkedOrganization) => {
                  setCopyToLinkedOrganization(copyToLinkedOrganization)
                  await handleUpdateProperty({ copyToLinkedOrganization })
                }}
                value={copyToLinkedOrganization}
              />
            </div>
          </div>
        )}
      </div>

      {propertyType.hasOptions && (
        <>
          <Dropdown.Divider />

          <Dropdown.Header className='tw-flex tw-items-center tw-justify-between tw-gap-2 tw-px-1'>
            Options
            {canEdit && (
              <Button
                className='btn-icon tw-p-1'
                onClick={() => setShowNewOption(true)}
              >
                <FontAwesomeIcon icon='plus' />
              </Button>
            )}
          </Dropdown.Header>

          {showNewOption && (
            <Input
              autoFocus
              className='form-control tw-my-1'
              onBlur={(event: React.KeyboardEvent<HTMLInputElement>) => {
                const { value } = event.target as HTMLInputElement
                if (value) return
                setShowNewOption(false)
              }}
              onChangeValue={setNewOptionLabel}
              onEnterKey={async (
                event: React.KeyboardEvent<HTMLInputElement>,
              ) => {
                const { value } = event.target as HTMLInputElement
                if (!value) return
                await handleAddOption(value)
              }}
              placeholder='Label'
              value={newOptionLabel}
            />
          )}

          <ScrollContainer className='tw-my-1' innerClassName='tw-max-h-96'>
            {sortedOptions.map((option, index) => (
              <PropertyOptionItem
                canEdit={canEdit}
                key={option.id}
                index={index}
                onChange={(changes) => handleUpdateOption(option.id, changes)}
                onDelete={() => handleDeleteOption(option.id)}
                onDrop={handleDropOption}
                onMove={handleMoveOption}
                option={option}
              />
            ))}
          </ScrollContainer>
        </>
      )}

      {canArchive && (
        <>
          <Dropdown.Divider />

          <Dropdown.Item
            onClick={async (event) => {
              event.stopPropagation()
              await handleArchiveProperty()
            }}
          >
            <FontAwesomeIcon
              className='dropdown-item-icon'
              icon={['far', 'trash-alt']}
            />
            Archive Property
          </Dropdown.Item>
        </>
      )}
    </div>
  )
}
