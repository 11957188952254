import isFunction from 'lodash/isFunction'
import { useRef } from 'react'
import { useMouseDown } from './useMouseDown'

export type MouseEventFunction = (ev: MouseEvent) => void

export type HTMLElementFunction = () => HTMLElement[]
export type HTMLElementInput = HTMLElement[] | HTMLElementFunction

// Hook to handle mousedown outside
export const useMouseDownOutside = (
  _fn: MouseEventFunction,
  _nodesInput: HTMLElementInput,
) => {
  const fnRef = useRef(_fn)
  const nodesInputRef = useRef(_nodesInput)
  fnRef.current = _fn
  nodesInputRef.current = _nodesInput

  useMouseDown((event) => {
    const fn = fnRef.current
    const nodesInput = nodesInputRef.current
    const nodes = isFunction(nodesInput) ? nodesInput() : nodesInput

    const isOutside = !nodes
      .filter(Boolean)
      .some((node) => node.contains(event.target as Node))
    if (isOutside) fn(event)
  })
}
