import { cx } from '@emotion/css'
import type { ComponentProps } from 'react'
import { Tooltip } from '../tooltip'

export type ProfileViewProps = ComponentProps<'div'> & {
  avatarURL?: string
  size: number
  text: string
  tooltip?: string
}

export const ProfileView = ({
  avatarURL,
  className,
  size,
  style,
  text,
  tooltip,
  ...props
}: ProfileViewProps) => {
  return (
    <Tooltip content={tooltip} disabled={!tooltip}>
      <div
        {...props}
        className={cx(
          'tw-relative tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-full tw-bg-cover tw-bg-center tw-bg-no-repeat tw-uppercase tw-text-white tw-text-sm tw-p-0.5',
          className,
        )}
        style={{
          ...style,
          height: size,
          width: size,
        }}
      >
        {avatarURL ? (
          <div
            className='tw-bg-cover tw-bg-no-repeat tw-w-full tw-h-full tw-rounded-full'
            style={{
              backgroundImage: `url(${avatarURL})`,
              backgroundPosition: 'center',
            }}
          />
        ) : (
          text
        )}
      </div>
    </Tooltip>
  )
}

ProfileView.displayName = 'ProfileView'
