import type { BlissbookHandbookBranding } from '../types'

export const defaultBranding: BlissbookHandbookBranding = {
  blackColor: '#333333',
  bodyFont: {
    type: 'google',
    family: 'Open Sans',
  },
  headingFont: {
    type: 'google',
    family: 'Open Sans',
  },
  lessButtonText: 'Collapse',
  moreButtonText: 'Read More',
  primaryColor: '#3B80E5',
  scriptFont: {
    type: 'google',
    family: 'Nothing You Could Do',
  },
  whiteColor: '#ffffff',
  linkColor: '#28aae2',
}
