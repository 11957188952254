export default ({ color, size, ...props }) => (
  <svg
    {...props}
    height={size}
    width={size}
    fill={color}
    viewBox='0 0 70 55'
    role='img'
    aria-label='quotes'
  >
    <g>
      <g transform='translate(-1345.000000, -817.000000)'>
        <g transform='translate(960.000000, 424.000000)'>
          <path d='M385.859375,442.296875 C391.744821,439.432277 396.35415,436.098977 399.6875,432.296875 C403.02085,428.494773 404.947914,423.885444 405.46875,418.46875 L401.09375,418.46875 C397.864567,418.46875 395.234385,418.10417 393.203125,417.375 C391.171865,416.64583 389.531256,415.630215 388.28125,414.328125 C387.083327,413.078119 386.236982,411.697924 385.742188,410.1875 C385.247393,408.677076 385,407.114591 385,405.5 C385,402.16665 386.341132,399.250013 389.023438,396.75 C391.705743,394.249987 394.869773,393 398.515625,393 C404.24482,393 408.645818,394.901023 411.71875,398.703125 C414.791682,402.505227 416.328125,407.765591 416.328125,414.484375 C416.328125,420.734406 413.684922,427.036427 408.398438,433.390625 C403.111953,439.744823 396.744829,444.562484 389.296875,447.84375 L385.859375,442.296875 Z M424.375,442.296875 C430.260446,439.432277 434.869775,436.098977 438.203125,432.296875 C441.536475,428.494773 443.463539,423.885444 443.984375,418.46875 L439.609375,418.46875 C436.380192,418.46875 433.75001,418.10417 431.71875,417.375 C429.68749,416.64583 428.046881,415.630215 426.796875,414.328125 C425.598952,413.078119 424.752607,411.697924 424.257812,410.1875 C423.763018,408.677076 423.515625,407.114591 423.515625,405.5 C423.515625,402.16665 424.856757,399.250013 427.539062,396.75 C430.221368,394.249987 433.385398,393 437.03125,393 C442.760445,393 447.161443,394.901023 450.234375,398.703125 C453.307307,402.505227 454.84375,407.765591 454.84375,414.484375 C454.84375,420.734406 452.200547,427.036427 446.914062,433.390625 C441.627578,439.744823 435.260454,444.562484 427.8125,447.84375 L424.375,442.296875 Z' />
        </g>
      </g>
    </g>
  </svg>
)
