import {
  type Node,
  acknowledgementBodySchema,
  acknowledgementTitleSchema,
  bodySchema,
  flatBodySchema,
  titleSchema,
} from '@blissbook/lib/document'
import { getRandomBackgroundImage } from '../images'
import type { BlissbookSection } from '../types'
import { HandbookSectionSchema } from './schema'

const chapterSettings = [
  { key: 'backgroundColor', defaultValue: 'black' },
  'backgroundImage',
  'backgroundOverlayColor',
  'backgroundOverlayOpacity',
  'bottom',
  'linkColor',
  'textShadow',
]

const defaultContentCentered: Node[] = [
  {
    type: 'paragraph',
    attrs: {
      textAlign: 'center',
    },
    content: [],
  },
]

export const sectionSchemas = [
  {
    type: 'acknowledgement',
    isUnique: true,
    content: {
      title: {
        type: 'wysiwyg',
        schema: acknowledgementTitleSchema,
        tagName: 'h1',
      },
      body: {
        type: 'wysiwyg',
        schema: acknowledgementBodySchema,
      },
    },
  },
  {
    type: 'beliefs',
    label: 'Beliefs',
    iconUrl: '/images/sections/chapter.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h1',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
    },
    settings: chapterSettings,
  },
  {
    type: 'chapter',
    childTypes: ['text'],
    label: 'Header',
    iconUrl: '/images/sections/chapter.svg',
    reason:
      'Organize your policies: Chapter Headers and Text Sections have a parent-child relationship.',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h1',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: titleSchema,
      },
    },
    settings: ['showChapterIcon', ...chapterSettings],
  },
  {
    type: 'contact',
    isUnique: true,
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h1',
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
    },
  },
  {
    type: 'cover',
    isCover: true,
    label: 'Cover',
    iconUrl: '/images/sections/hero.svg',
    content: {
      image: {
        type: 'image',
      },
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h1',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: titleSchema,
      },
    },
    settings: ['backgroundImage', 'bottom', 'linkColor', 'textShadow'],
  },
  {
    type: 'credo',
    label: 'Credo',
    iconUrl: '/images/sections/text.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      description: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
    },
    settings: ['headingBackgroundImage'],
    entry: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h4',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: titleSchema,
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
      image: { type: 'image' },
    },
    entryLabel: 'Quote',
  },
  {
    type: 'credoh',
    label: 'Credo Header',
    iconUrl: '/images/sections/chapter.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h1',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: titleSchema,
      },
    },
    settings: chapterSettings,
  },
  {
    type: 'goal',
    label: 'Goal',
    iconUrl: '/images/sections/text.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      introduction: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
      goal: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'div',
        className: 'h2',
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
    },
    settings: [{ key: 'bottom', defaultValue: 'gradient' }, 'linkColor'],
  },
  {
    type: 'hero',
    isCover: true,
    label: 'Hero',
    iconUrl: '/images/sections/hero.svg',
    reason:
      'Use for a single idea or topic you want to stand out, like a mission statement, vision, purpose, guiding goal, etc.',
    content: {
      image: {
        type: 'image',
      },
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      headline: {
        type: 'wysiwyg',
        className: 'heading-font',
        schema: flatBodySchema,
        defaultContent: defaultContentCentered,
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
        defaultContent: defaultContentCentered,
      },
    },
    buildAttrs: () => ({
      backgroundImage: getRandomBackgroundImage(),
      backgroundOverlayColor: 'white',
      backgroundOverlayOpacity: 50,
    }),
    settings: [
      'backgroundAnimation',
      'backgroundColor',
      'backgroundImage',
      'backgroundOverlayColor',
      'backgroundOverlayOpacity',
      'bottom',
      'bottomArrow',
      'height',
      'linkColor',
      'titleHighlight',
      'titleHighlightColor',
    ],
  },
  {
    type: 'history',
    label: 'History',
    iconUrl: '/images/sections/chapter.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h1',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: titleSchema,
      },
    },
    settings: chapterSettings,
  },
  {
    type: 'leaders',
    label: 'Leaders',
    iconUrl: '/images/sections/list.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      introduction: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
    },
    settings: ['linkColor', 'maxColumns'],
    entry: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h4',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: titleSchema,
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
      image: {
        type: 'image',
        background: 'cover',
        shape: 'circle',
      },
    },
    entryLabel: 'List Item',
  },
  {
    type: 'letter',
    label: 'Letter',
    iconUrl: '/images/sections/letter.svg',
    reason: 'Create a welcome letter from your CEO, HR team or anyone else.',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: titleSchema,
      },
      body: {
        type: 'wysiwyg',
        autofocus: true,
        schema: flatBodySchema,
      },
      photo: {
        type: 'image',
        background: 'cover',
        shape: 'circle',
      },
      signature: {
        type: 'wysiwyg',
        schema: titleSchema,
      },
      signatureImage: {
        type: 'image',
      },
      video: {
        type: 'video',
        width: 640,
        height: 360,
      },
    },
    settings: [
      'animation',
      'backgroundColor',
      'backgroundImage',
      'backgroundOverlayColor',
      'backgroundOverlayOpacity',
      'linkColor',
      { key: 'bottom', defaultValue: 'gradient' },
      'signatureType',
    ],
  },
  {
    type: 'list',
    label: 'List',
    iconUrl: '/images/sections/list.svg',
    reason:
      'Create a list you want to stand out, like core values, principles, virtues, beliefs, behaviors, etc.',
    content: {
      image: {
        type: 'image',
      },
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      introduction: {
        type: 'wysiwyg',
        schema: flatBodySchema,
        defaultContent: defaultContentCentered,
      },
    },
    settings: ['backgroundColor', 'linkColor'],
    defaultThemeId: 'cards',
    themes: [
      {
        id: 'box',
        label: 'Box',
        settings: [
          'backgroundImage',
          'backgroundOverlayColor',
          'backgroundOverlayOpacity',
          'bottom',
          'entryAnimation',
          'maxColumns',
        ],
        defaultValues: {
          backgroundColor: 'primary',
        },
      },
      {
        id: 'cards',
        label: 'Cards',
        settings: [
          'backgroundImage',
          'backgroundOverlayColor',
          'backgroundOverlayOpacity',
          'bottom',
          'entryAnimation',
          'maxColumns',
        ],
        defaultValues: {
          backgroundColor: 'primary',
        },
      },
      {
        id: 'profiles',
        label: 'Profiles',
        settings: [
          'backgroundImage',
          'backgroundOverlayColor',
          'backgroundOverlayOpacity',
          'bottom',
          'entryAnimation',
          'maxColumns',
        ],
        defaultValues: {
          backgroundColor: 'white',
        },
      },
      {
        id: 'stripes',
        label: 'Stripes',
        settings: ['entryAngle'],
        adminOnly: true,
        defaultValues: {
          backgroundColor: 'white',
          bottom: null,
        },
      },
      {
        id: 'tabs',
        label: 'Tabs',
        settings: [
          'backgroundImage',
          'backgroundOverlayColor',
          'backgroundOverlayOpacity',
          'bottom',
          'entryAnimation',
        ],
        defaultValues: {
          backgroundColor: 'black',
        },
      },
    ],
    entry: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h4',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: titleSchema,
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
      image: {
        type: 'image',
      },
    },
    entryLabel: 'List Item',
  },
  {
    type: 'mission',
    label: 'Mission',
    iconUrl: '/images/sections/hero.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      statement: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'div',
        className: 'h2',
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
    },
    settings: ['backgroundImage', 'linkColor'],
  },
  {
    type: 'origin',
    label: 'Origin',
    iconUrl: '/images/sections/text.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
    },
    settings: [{ key: 'bottom', defaultValue: 'gradient' }, 'linkColor'],
  },
  {
    type: 'overview',
    label: 'Overview',
    iconUrl: '/images/sections/text.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
    },
    settings: [{ key: 'bottom', defaultValue: 'gradient' }, 'linkColor'],
  },
  {
    type: 'principles',
    label: 'Principles',
    iconUrl: '/images/sections/list.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      introduction: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
    },
    settings: ['backgroundImage', 'linkColor'],
    entry: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h4',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: titleSchema,
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
      image: {
        type: 'image',
        background: 'contain',
        shape: 'circle',
      },
    },
    entryLabel: 'List Item',
  },
  {
    type: 'text',
    label: 'Text',
    iconUrl: '/images/sections/text.svg',
    reason: 'Use for Policies or any other freeform text.',
    canPrint: true,
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      body: {
        type: 'wysiwyg',
        schema: bodySchema,
        autofocus: true,
      },
    },
    settings: [
      'linkColor',
      'textColor',
      'textBackgroundColor',
      'textBorderColor',
      'textPadding',
      'textBorderWidth',
      'textBorderRadius',
      'textBorderStyle',
    ],
  },
  {
    type: 'timeline',
    label: 'Timeline',
    iconUrl: '/images/sections/timeline.svg',
    reason: "A timeline doesn't have to only look into the past!",
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      introduction: {
        type: 'wysiwyg',
        schema: flatBodySchema,
        defaultContent: defaultContentCentered,
      },
    },
    settings: [
      { key: 'backgroundColor', defaultValue: 'primary' },
      'backgroundImage',
      'backgroundOverlayColor',
      'backgroundOverlayOpacity',
      'linkColor',
      'textShadow',
    ],
    entry: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h4',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'div',
        className: 'h5',
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema.reject(['indent', 'textAlign']),
      },
      image: { type: 'image' },
    },
    entryLabel: 'Timeline Item',
  },
  {
    type: 'values',
    label: 'Values',
    iconUrl: '/images/sections/list.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      introduction: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
    },
    settings: ['maxColumns', 'textShadow'],
    entry: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h4',
      },
      subtitle: {
        type: 'wysiwyg',
        schema: titleSchema,
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
      image: { type: 'image', background: 'cover' },
    },
    entryLabel: 'List Item',
  },
  {
    type: 'worthiness',
    label: 'Worthiness',
    iconUrl: '/images/sections/chapter.svg',
    content: {
      title: {
        type: 'wysiwyg',
        schema: titleSchema,
        tagName: 'h2',
      },
      body: {
        type: 'wysiwyg',
        schema: flatBodySchema,
      },
    },
    settings: ['bottom'],
  },
].map(HandbookSectionSchema.fromJSON)

export const sectionSchemasByType = new Map<string, HandbookSectionSchema>()
for (const schema of sectionSchemas) {
  sectionSchemasByType.set(schema.type, schema)
}

export function getSectionChildTypes(section?: BlissbookSection) {
  const schema = sectionSchemasByType.get(section?.type)
  return schema?.childTypes || []
}

export const handbookSectionTypes = sectionSchemas
  .filter((s) => !s.isUnique)
  .map((schema) => schema.type)
