import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'
import type React from 'react'
import type { CSSProperties } from 'react'
import type { InputSize } from './types'

const stylesBySize = {
  xs: {
    handleSize: 9,
    trackHeight: 3,
  },
  sm: {
    handleSize: 16,
    trackHeight: 6,
  },
  md: {
    handleSize: 20,
    trackHeight: 10,
  },
  lg: {
    handleSize: 24,
    trackHeight: 12,
  },
}

const getStyles = (size?: InputSize) =>
  size
    ? stylesBySize[size]
    : {
        handleSize: 20,
        trackHeight: 10,
      }

export type SliderInputProps = {
  className?: string
  handleStyle?: CSSProperties
  max?: number
  min?: number
  onAfterChange?: (value: number) => void
  onChange: (value: number) => void
  railStyle?: CSSProperties
  size?: InputSize
  step?: number
  style?: CSSProperties
  trackStyle?: CSSProperties
  value: number
}

export const SliderInput: React.FC<SliderInputProps> = ({
  handleStyle,
  onAfterChange,
  onChange,
  railStyle,
  size,
  trackStyle,
  ...props
}) => {
  const { handleSize, trackHeight } = getStyles(size)
  return (
    <Slider
      {...props}
      css={{
        height: handleSize,
        '.rc-slider-handle': {
          height: handleSize,
          width: handleSize,
        },
        '.rc-slider-handle-dragging': {
          boxShadow: 'none !important',
        },
        '.rc-slider-rail': {
          height: trackHeight,
          top: `calc(50% - ${trackHeight / 2}px)`,
        },
        '.rc-slider-track': {
          height: trackHeight,
          top: `calc(50% - ${trackHeight / 2}px)`,
        },
      }}
      handleStyle={{
        background: 'var(--color-blurple-500)',
        borderColor: 'var(--color-blurple-500)',
        opacity: 1,
        ...handleStyle,
      }}
      onAfterChange={(value) => {
        if (!onAfterChange) return
        onAfterChange(value as number)
      }}
      onChange={(value) => {
        onChange(value as number)
      }}
      railStyle={{
        background: 'var(--color-gray-100)',
        ...railStyle,
      }}
      trackStyle={{
        background: 'var(--color-black)',
        ...trackStyle,
      }}
    />
  )
}

export type StepsSliderInputProps = {
  className?: string
  onChange: (value: number) => void
  size?: InputSize
  steps: number[]
  style?: CSSProperties
  value: number
}

export const StepsSliderInput: React.FC<StepsSliderInputProps> = ({
  onChange,
  steps,
  value,
  ...props
}) => {
  const index = steps.reduce((index, rhsValue, rhsIndex) => {
    const lhsDelta = Math.abs(steps[index] - value)
    const rhsDelta = Math.abs(rhsValue - value)
    return rhsDelta < lhsDelta ? rhsIndex : index
  }, 0)
  return (
    <SliderInput
      {...props}
      min={0}
      max={steps.length - 1}
      onChange={(index) => {
        onChange(steps[index])
      }}
      value={index}
    />
  )
}
