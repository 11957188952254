import type { Editor } from '@blissbook/ui/editor'
import { LinkToolbar, getLinkEditorState } from '@blissbook/ui/editor/marks'
import type { TextSelection } from 'prosemirror-state'
import { TextSelectionHighlight } from './highlight'
import { TextSelectionToolbar } from './toolbar'

export type TextSelectionViewProps = {
  editor: Editor
  selection: TextSelection
  showBubbleMenu?: boolean
}

function getToolbar({
  editor,
  selection,
  showBubbleMenu,
}: TextSelectionViewProps) {
  const linkEditorState = getLinkEditorState(editor)
  if (linkEditorState) {
    return (
      <LinkToolbar
        editor={editor}
        key={linkEditorState.id}
        state={linkEditorState}
      />
    )
  }
  if (!selection.empty && showBubbleMenu) {
    return <TextSelectionToolbar editor={editor} selection={selection} />
  }
}

export type HighlightProps = {
  editor: Editor
  selection: TextSelection
  showBubbleMenu?: boolean
}

function getHighlight({ editor, selection }: TextSelectionViewProps) {
  const hasFocus = editor.view.hasFocus()
  const linkEditorState = getLinkEditorState(editor)
  if (!selection.empty && !hasFocus) {
    return <TextSelectionHighlight editor={editor} range={selection} />
  }
  if (linkEditorState) {
    return (
      <TextSelectionHighlight editor={editor} range={linkEditorState.range} />
    )
  }
}

export const TextSelectionView = (props: TextSelectionViewProps) => {
  const toolbar = getToolbar(props)
  if (!toolbar) return null
  return (
    <>
      {toolbar}
      {getHighlight(props)}
    </>
  )
}
