import './ScrollContainer.scss'
import { mergeRefs } from '@blissbook/ui/util'
import { useIsomorphicLayoutEffect } from '@blissbook/ui/util/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import isEqual from 'lodash/isEqual'
import throttle from 'lodash/throttle'
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useScrollBarWidth } from './hooks'

export const ScrollContainer = React.forwardRef(
  /**
   * @param {Record<string, any>} props
   */
  function ScrollContainer(
    {
      children,
      className,
      height,
      hideBottomBound,
      hideTopBound,
      innerClassName,
      innerRef,
      maxHeight,
      onChangeScrollBarWidth,
      onScroll,
      showScrollText,
      ...props
    },
    ref,
  ) {
    const [scrollNode, setScrollNode] = useState()
    const scrollBarWidth = useScrollBarWidth(scrollNode, onChangeScrollBarWidth)
    return (
      <div
        {...props}
        className={classnames('scroll-container', className)}
        css={{ height }}
        ref={ref}
      >
        <div
          children={children}
          className={classnames('scroll-container-inner', innerClassName)}
          css={{ maxHeight }}
          onScroll={onScroll}
          ref={mergeRefs([innerRef, setScrollNode])}
        />

        {scrollNode && (
          <ScrollBounds
            hideBottomBound={hideBottomBound}
            hideTopBound={hideTopBound}
            node={scrollNode}
            scrollBarWidth={scrollBarWidth}
            showScrollText={showScrollText}
          />
        )}
      </div>
    )
  },
)

// Get the scroll state
const getScrollState = (node) => {
  const { offsetHeight, scrollHeight, scrollTop } = node
  const fromBottom = Math.ceil(scrollTop) < scrollHeight - offsetHeight
  const fromTop = scrollTop > 0
  return { fromBottom, fromTop }
}

// Hook for scroll state
const useScrollState = (node) => {
  const ref = useRef({})
  const [, forceRender] = useReducer((s) => s + 1, 0)

  // If the node changed, recalculate
  if (node !== ref.current.node) {
    const state = getScrollState(node)
    ref.current = {
      ...state,
      hasScrolled: false,
      node,
    }
  }

  // Change handler
  const updateState = useCallback((changes) => {
    const state = { ...ref.current, ...changes }
    if (isEqual(state, ref.current)) return
    ref.current = state
    forceRender()
  })

  // Update after any render
  useIsomorphicLayoutEffect(() => {
    const changes = getScrollState(node)
    updateState(changes)
  })

  // Update after scrolls
  useEffect(() => {
    // Handle resize
    const onResize = throttle(() => {
      const changes = getScrollState(node)
      updateState(changes)
    }, 100)

    // Handle scroll
    const onScroll = throttle(() => {
      const changes = getScrollState(node)
      changes.hasScrolled = true
      updateState(changes)
    }, 100)

    // Add / Remove handlers
    window.addEventListener('resize', onResize, false)
    node.addEventListener('scroll', onScroll, false)
    return () => {
      window.removeEventListener('resize', onResize, false)
      node.removeEventListener('scroll', onScroll, false)
    }
  }, [node])

  return ref.current
}

const ScrollBounds = ({
  hideBottomBound,
  hideTopBound,
  node,
  scrollBarWidth = 0,
  showScrollText,
}) => {
  const { fromBottom, fromTop, hasScrolled } = useScrollState(node)
  return (
    <>
      {(fromBottom || fromTop) && (
        <>
          {!hideTopBound && (
            <div
              className='scroll-bound scroll-bound-top'
              style={{
                opacity: fromTop ? 1 : 0,
                right: scrollBarWidth,
              }}
            />
          )}

          {!hideBottomBound && (
            <>
              <div
                className='scroll-bound scroll-bound-bottom'
                style={{
                  opacity: fromBottom ? 1 : 0,
                  right: scrollBarWidth,
                }}
              />

              {showScrollText && (
                <div
                  className='scroll-text'
                  style={{
                    opacity: !hasScrolled && fromBottom ? 1 : 0,
                    right: scrollBarWidth,
                  }}
                >
                  <FontAwesomeIcon className='tw-mr-2' icon='arrow-down' />{' '}
                  scroll
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
