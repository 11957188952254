import config from '@blissbook/ui-config'
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'
import { useEffect } from 'react'

const appId = config.intercom?.appId

const defaultSettings = {
  alignment: 'right',
  app_id: appId,
}

// Run an Intercom command
function Intercom(...args) {
  if (!window.Intercom) return
  return window.Intercom(...args)
}

/** Wait for intercvome to load */
export async function waitForIntercom() {
  const maxWaitMs = 10000
  const delay = 100
  let attemptsRemaining = maxWaitMs / delay
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      // Resolve if intercom is loaded
      if (window.Intercom) {
        clearInterval(interval)
        resolve()
      }

      // Reject if we've waited too long
      if (attemptsRemaining-- <= 0) {
        clearInterval(interval)
        reject()
      }
    }, delay)
  })
}

const getUnixTime = (date) => date && moment(date).toDate().getTime() / 1000

const getSessionSettings = ({
  organization,
  session: { isImpersonating, person, superUser },
}) => {
  // Do not send data if logged in as super-admin or become a user as super-admin
  if (superUser || isImpersonating) return
  // Must be a valid person to send data to Intercom
  if (!organization || !person || !person.idHash) return

  return {
    user_id: person.id,
    user_hash: person.idHash,
    email: person.email,
    name: person.fullName,
    created_at: getUnixTime(person.createdAt),
    primary: person.id === organization.createdBy,
    company: {
      id: organization.id,
      name: organization.name,
      subdomain: organization.subdomain,
      created_at: getUnixTime(organization.createdAt),
    },
  }
}

function bootIntercomState(state) {
  const { organization, session } = state
  const { person } = session
  const settings = getSessionSettings(state)
  Intercom('boot', {
    ...defaultSettings,
    ...settings,
    hide_default_launcher: organization ? !person : false,
  })
}

/** Boot intercom once intercom is loaded */
export async function bootIntercom(state) {
  if (!appId) return

  try {
    await waitForIntercom()
    bootIntercomState(state)
  } catch (_error) {
    console.error('Failed to load intercom')
  }
}

export const updateIntercom = (state) => {
  const settings = getSessionSettings(state)
  if (settings) Intercom('update', settings)
}

export const shutdownIntercom = () => Intercom('shutdown')

export const showHelpMessage = (message) => Intercom('showNewMessage', message)

// Submit a marketing form to intercom
export const submitForm = async ({
  body,
  email,
  employeesCount,
  newsletter,
  organizationName,
  tag,
  ...fields
}) => {
  // Update the current vistor's parameters
  Intercom('update', {
    ...fields,
    utm_campaign: Cookies.get('utm_campaign'),
    utm_content: Cookies.get('utm_content'),
    utm_medium: Cookies.get('utm_medium'),
    utm_source: Cookies.get('utm_source'),
    utm_term: Cookies.get('utm_term'),
  })

  // Send the email
  const { name } = fields
  const res = await axios.post('/marketing/email', {
    body,
    email,
    employeesCount,
    name,
    organizationName,
    newsletter,
    tag,
  })
  const { emailHash } = res.data
  Intercom('update', { email, user_hash: emailHash })
}

// Hook to move the intercom alignment
export const useIntercomAlignment = (alignment) =>
  useEffect(() => {
    // Set the alignment on mount
    Intercom('update', { alignment })

    // Return the alignment on unmount
    return () => {
      Intercom('update', { alignment: defaultSettings.alignment })
    }
  }, [alignment])
