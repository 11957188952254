import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { forwardRef } from 'react'
import { BackgroundImage } from '../images'
import { Button } from './Button'

type IntegrationButtonProps = React.HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean
  icon?: React.ReactNode | IconProp
  image?: string
  imageAspectRatio: number
  installed?: boolean
  onClickEdit: React.MouseEventHandler<HTMLButtonElement>
  onClickInstall: React.MouseEventHandler<HTMLDivElement>
}

export const IntegrationButton = forwardRef<
  HTMLDivElement,
  IntegrationButtonProps
>(
  (
    {
      children,
      className,
      disabled,
      icon,
      image,
      imageAspectRatio = 1,
      installed,
      onClickEdit,
      onClickInstall,
      ...props
    },
    ref,
  ) => {
    const badgeSize = 30
    const borderRadius = 4
    const borderWidth = 2
    const paddingX = 16
    const paddingY = 12
    const height = 60
    const imageHeight = height - paddingY * 2 - 4
    const imageWidth = imageHeight * imageAspectRatio
    const canInstall = !disabled && !installed
    return (
      <div
        {...props}
        className={classnames(
          'btn btn-input tw-flex tw-items-center tw-justify-between tw-w-full',
          className,
          {
            disabled: !canInstall,
          },
        )}
        css={{
          cursor: canInstall ? 'pointer' : undefined,
          height,
          padding: `${paddingY}px ${paddingX}px`,
          position: 'relative',
        }}
        onClick={(event) => {
          if (canInstall) onClickInstall(event)
        }}
        ref={ref}
        style={{
          opacity: installed ? 1 : undefined,
        }}
      >
        {installed && (
          <div
            css={{
              borderTopLeftRadius: borderRadius,
              fontSize: 12,
              height: badgeSize,
              left: -borderWidth,
              overflow: 'hidden',
              position: 'absolute',
              top: -borderWidth,
              width: badgeSize,
            }}
          >
            <div
              className='tw-bg-green-500'
              css={{
                height: badgeSize * Math.sqrt(2),
                left: 0,
                position: 'absolute',
                top: 0,
                transform: `rotate(45deg) translate(-${badgeSize}px, 0)`,
                width: badgeSize * Math.sqrt(2),
                zIndex: 0,
              }}
            />

            <FontAwesomeIcon
              className='tw-text-white'
              css={{
                fontSize: 12,
                left: 3,
                position: 'absolute',
                top: 3,
              }}
              icon='check'
            />
          </div>
        )}

        <div className='tw-flex tw-items-center' css={{ flex: 1 }}>
          {React.isValidElement(icon) ? (
            icon
          ) : icon ? (
            <FontAwesomeIcon
              css={{ fontSize: imageHeight }}
              icon={icon as IconProp}
            />
          ) : image ? (
            <BackgroundImage
              css={{
                backgroundPosition: '0 50%',
                maxWidth: 240,
              }}
              height={imageHeight}
              size='contain'
              src={image}
              width={imageWidth}
            />
          ) : null}

          {children && (
            <div
              children={children}
              className='tw-ml-2 tw-my-0'
              css={{
                fontSize: 24,
                fontWeight: 800,
                letterSpacing: 3,
              }}
            />
          )}
        </div>

        {canInstall ? (
          <FontAwesomeIcon
            className='tw-ml-4 tw-text-gray-400'
            css={{ fontSize: 24 }}
            icon={['far', 'chevron-right']}
          />
        ) : installed && onClickEdit ? (
          <Button
            color='primary'
            css={{ pointerEvents: 'all' }}
            onClick={(event) => {
              event.stopPropagation()
              onClickEdit(event)
            }}
            outline
            size='sm'
          >
            Edit
          </Button>
        ) : null}
      </div>
    )
  },
)
