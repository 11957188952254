import classnames from 'classnames'
import type React from 'react'
import { forwardRef } from 'react'

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: string
  outline?: boolean
  selected?: boolean
  size?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, color, outline, selected, size, ...props }, ref) => (
    <button
      type='button'
      {...props}
      className={classnames(
        color && (outline ? `btn btn-outline-${color}` : `btn btn-${color}`),
        size && `btn-${size}`,
        { selected },
        className,
      )}
      ref={ref}
    />
  ),
)
