import type { Node as ProseMirrorNode, ResolvedPos } from 'prosemirror-model'
import { type Node, reduceNode } from './types'

export function isContentEmpty(content: Node[]): boolean {
  if (content.length === 0) return true
  if (content.length > 1) return false

  // Empty if only node is a paragraph with no content
  const [node] = content
  return isNodeEmpty(node)
}

/**
 * Check if a node is empty.
 *
 * @param node - The node to check.
 * @returns True if the node is empty, false otherwise.
 */
export function isNodeEmpty(node: Node): boolean {
  switch (node.type) {
    case 'text':
      return node.text.trim().length === 0
    case 'paragraph':
      return node.content === undefined || node.content.every(isNodeEmpty)
    default:
      return false
  }
}

export function isProseMirrorNodeEmpty(node: ProseMirrorNode) {
  // Not empty if > 1 nodes
  if (node.childCount > 1) return false

  const { firstChild } = node

  // Ensure firstChild is not null before checking isTextblock
  if (!firstChild) return true

  // Empty if only child is a text block with no children
  return firstChild.isTextblock && firstChild.childCount === 0
}

export function getContentFromProsemirrorDocNode(doc: ProseMirrorNode) {
  const content = doc.toJSON().content as Node[]
  return content.map(reduceNode)
}

export function getContainerNodeFromPos($pos: ResolvedPos, nodeType: string) {
  for (let depth = $pos.depth; depth >= 0; depth--) {
    const node = $pos.node(depth)
    const pos = $pos.start(depth) - 1
    if (node.type.name === nodeType) return { node, pos }
  }
}
