import { cx } from '@emotion/css'
import type React from 'react'
import { forwardRef } from 'react'

export type ToolbarProps = React.HTMLAttributes<HTMLDivElement>

export const Toolbar = forwardRef<HTMLDivElement, ToolbarProps>(
  ({ className, ...props }, ref) => (
    <div {...props} className={cx('toolbar', className)} ref={ref} />
  ),
)
