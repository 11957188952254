import { BasicExpression } from './BasicExpression'
import { Variant, type VariantJSON } from './Variant'

export type LogicalExpressionRuleJSON = {
  includes: VariantJSON[]
  excludes: VariantJSON[]
}

export type LogicalExpressionRule = {
  includes: Variant[]
  excludes: Variant[]
}

export class LogicalExpression {
  rules: LogicalExpressionRule[]

  constructor(rules: LogicalExpressionRule[] = []) {
    this.rules = rules
  }

  // Go from expression to object
  static fromString(expression: string) {
    // Map the old Basic expression, if necessary
    try {
      const rule = BasicExpression.parse(expression)
      const rules = rule.includes.length
        ? // Has at least 1 include
          rule.includes.map((include) => ({
            includes: [include],
            excludes: rule.excludes,
          }))
        : // Excludes only
          [
            {
              includes: [],
              excludes: rule.excludes,
            },
          ]

      return new LogicalExpression(rules)
    } catch (_error) {
      // continue regardless of error
    }

    // Parse out the ORs
    const ors = expression.split('||')
    const rules = ors.map((exp) => {
      // Remove parantheses
      if (exp[0] === '(' && exp[exp.length - 1] === ')')
        exp = exp.substr(1, exp.length - 2)

      // Create rule by parsing the ANDs
      const includes: Variant[] = []
      const excludes: Variant[] = []
      exp.split('&&').forEach((variable) => {
        // Determine if its an exclude
        const isExclude = variable[0] === '!'
        if (isExclude) variable = variable.substr(1)

        // Parse out the variant
        const variant = Variant.fromString(variable)
        if (!variant) throw new Error('Invalid Expression')

        // Add to rule
        const array = isExclude ? excludes : includes
        array.push(variant)
      })
      return { includes, excludes }
    })

    return new LogicalExpression(rules)
  }
}
