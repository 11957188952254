import './Overlay.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Button } from '../buttons'
import { Carousel, ChecklistNav } from '../nav'
import { AnimatedPortal } from '../portal'
import { ScrollContainer, useScrollLock } from '../scroll'

/**
 * @param {Record<string, any>} props
 */
export const Component = ({ className, ...props }) => {
  useScrollLock()

  return (
    <div
      {...props}
      className={classnames('overlay overlay-content', className)}
    />
  )
}

/**
 * @param {Record<string, any>} props
 */
export const Content = ({ className, ...props }) => (
  <div {...props} className={classnames('overlay-content', className)} />
)

/**
 * @param {Record<string, any>} props
 */
export const Header = ({ className, ...props }) => (
  <div {...props} className={classnames('overlay-header', className)} />
)

/**
 * @param {Record<string, any>} props
 */
export const Title = ({ children, prepend, ...props }) => (
  <div {...props} css={{ fontSize: 18 }}>
    {prepend && <span className='tw-mr-1'>{prepend}</span>}
    <h1
      className='tw-inline-block tw-my-0'
      css={{ transform: 'translateY(1px)' }}
    >
      {children}
    </h1>
  </div>
)

/**
 * @param {Record<string, any>} props
 */
export const CloseButton = ({ className, ...props }) => (
  <button
    type='button'
    {...props}
    className={classnames('btn-icon', className)}
    css={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontSize: 12,
      textTransform: 'uppercase',
    }}
  >
    <FontAwesomeIcon
      className='tw-mb-2'
      css={{ fontSize: 24 }}
      icon={['far', 'times']}
    />
    Close
  </button>
)

/**
 * @param {Record<string, any>} props
 */
export const Body = ({ className, ...props }) => (
  <ScrollContainer
    {...props}
    className={classnames('overlay-body', className)}
    showScrollText
  />
)

/**
 * @param {Record<string, any>} props
 */
export const Text = ({ className, maxWidth, width = 600, ...props }) => (
  <div
    {...props}
    className={classnames('overlay-text overlay-container-y', className)}
    css={{
      maxWidth: maxWidth || width,
      width,
    }}
  />
)

/**
 * @param {Record<string, any>} props
 */
export const Footer = ({ className, ...props }) => (
  <div {...props} className={classnames('overlay-footer', className)} />
)

/**
 * @param {Record<string, any>} props
 */
export const FooterNav = ({ className, ...props }) => (
  <div {...props} className={classnames('overlay-footer-nav', className)} />
)

const StepContext = React.createContext({})

/**
 * @param {Record<string, any>} props
 */
export const Stepper = ({ onChange, steps, ...props }) => {
  const { isSubmitting } = props
  const [currStepIndex, setCurrStepIndexState] = useState(0)
  const currStep = steps[currStepIndex]
  const completedKeys = useRef(new Set())
  const stepCount = steps.length

  const setCurrStepIndex = (stepIndex) => {
    if (stepIndex > currStepIndex) completedKeys.current.add(currStep.key)
    setCurrStepIndexState(stepIndex)
  }

  // Callback when steps change
  useEffect(() => {
    if (onChange) onChange(currStep)
  }, [currStep])

  return (
    <Content>
      <ChecklistNav
        className='overlay-header'
        css={{ height: 60 }}
        direction='horizontal'
      >
        {steps.map((step, stepIndex) => (
          <ChecklistNav.Item
            active={step === currStep}
            checked={completedKeys.current.has(step.key)}
            children={step.title}
            disabled={
              isSubmitting ||
              (stepIndex > currStepIndex + 1 &&
                !completedKeys.current.has(step.key))
            }
            key={step.key}
            onClick={() => setCurrStepIndex(stepIndex)}
          />
        ))}
      </ChecklistNav>

      <Carousel.Component
        activeIndex={currStepIndex}
        className='overlay-body'
        height='100%'
      >
        {steps.map((step, stepIndex) => (
          <Carousel.Item key={step.key}>
            <StepContext.Provider
              value={{ setCurrStepIndex, stepCount, stepIndex }}
            >
              <step.Component {...props} />
            </StepContext.Provider>
          </Carousel.Item>
        ))}
      </Carousel.Component>
    </Content>
  )
}

/**
 * @param {Record<string, any>} props
 */
export const PrevStepButton = ({ children = 'Back', className, ...props }) => {
  const { setCurrStepIndex, stepIndex } = useContext(StepContext)
  return stepIndex > 0 ? (
    <Button
      {...props}
      className={classnames('btn-link', className)}
      onClick={() => setCurrStepIndex(stepIndex - 1)}
    >
      <FontAwesomeIcon className='tw-mr-2' icon='arrow-left' />
      {children}
    </Button>
  ) : null
}

export const NextStepButton = ({
  children = 'Continue',
  color = 'primary',
  ...props
}) => {
  const { setCurrStepIndex, stepCount, stepIndex } = useContext(StepContext)
  return (
    stepIndex < stepCount - 1 && (
      <Button
        {...props}
        children={children}
        color={color}
        onClick={() => setCurrStepIndex(stepIndex + 1)}
      />
    )
  )
}

// Animate the overlay
const wrapAnimated =
  (OverlayComponent) =>
  ({ isOpen, ...props }) => (
    <AnimatedPortal in={isOpen}>
      <OverlayComponent {...props} />
    </AnimatedPortal>
  )

// Create a button for this overlay
const wrapButton = (OverlayComponent) =>
  React.forwardRef(({ overlayProps, ...props }, ref) => {
    const [isOpen, setOpen] = useState(false)

    const onShowOverlay = useCallback(() => {
      setOpen(true)
    })

    const onCloseOverlay = useCallback(() => {
      setOpen(false)
    })

    return (
      <Fragment>
        <Button {...props} onClick={onShowOverlay} ref={ref} />
        <OverlayComponent.Animated
          {...overlayProps}
          isOpen={isOpen}
          onClose={onCloseOverlay}
        />
      </Fragment>
    )
  })

/**
 * Animated and attach a .Button to MyCustomOverlay for MyCustomOverlay.Button
 *
 * @param {Record<string, any>} OverlayComponent
 */
export const wrap = (OverlayComponent) => {
  OverlayComponent.Animated = wrapAnimated(OverlayComponent)
  OverlayComponent.Button = wrapButton(OverlayComponent)
  return OverlayComponent
}
