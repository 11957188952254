import type { DocumentSchema } from '@blissbook/lib/document'
import classnames from 'classnames'
import find from 'lodash/find'
import pick from 'lodash/pick'
// @ts-ignore: no types available
import dasherize from 'underscore.string/dasherize'
import type { BlissbookSection } from '../types'
import {
  type HandbookSetting,
  type HandbookSettingKey,
  fromSettingKey,
  getDefaultValues,
} from './settings'
import { Theme } from './theme'

type HandbookSectionBuildAttrsFunction = () => any

type HandbookSectionContentSchema = {
  type: string
  tagName?: string
  className?: string
  background?: string
  schema?: DocumentSchema
  autofocus?: boolean
}

export interface HandbookSectionSchemaJSON {
  // Schema type
  type: string
  // Label for the schema type
  label?: string
  // Reason for a user to add the section to the handbook
  reason?: string
  // This section can be used as a cover page
  isCover?: boolean
  // Only on handbook level, not in handbook_sections
  isUnique?: boolean
  // Types that are allowed to be children of this section
  childTypes?: string[]
  // Defines schemas for keys on section.content
  content: Record<string, HandbookSectionContentSchema>
  // buildAttrs - Build the initial attrs
  buildAttrs?: HandbookSectionBuildAttrsFunction
  //  Defines settings that the user can edit in the settings tab
  settings?: HandbookSettingKey[]
  // Defines available themes for the section
  themes?: any[]
  // Default theme. If not specified, use the first theme
  defaultThemeId?: string
  // Defines schemas for keys on section.entries
  entry?: Record<string, HandbookSectionContentSchema>
  // Label for an entry (aka List Item) (override)
  entryLabel?: string
  // Whether or not this section can be printed individually
  canPrint?: boolean
}

export class HandbookSectionSchema {
  // Schema type
  type: string
  // Label for the schema type
  label?: string
  // Reason for a user to add the section to the handbook
  reason?: string
  // This section can be used as a cover page
  isCover?: boolean
  // Only on handbook level, not in handbook_sections
  isUnique?: boolean
  // Types that are allowed to be children of this section
  childTypes?: string[]
  // Defines schemas for keys on section.content
  content: Record<string, HandbookSectionContentSchema>
  // buildAttrs - Build the initial attrs
  buildAttrs?: HandbookSectionBuildAttrsFunction
  //  Defines settings that the user can edit in the settings tab
  settings?: HandbookSetting[]
  // Defines available themes for the section
  themes?: Theme[]
  // Default theme. If not specified, use the first theme
  defaultThemeId?: string
  // Defines schemas for keys on section.entries
  entry?: Record<string, HandbookSectionContentSchema>
  // Label for an entry (aka List Item) (override)
  entryLabel?: string
  // Whether or not this section can be printed individually
  canPrint?: boolean
  // Icon for the section
  iconUrl?: string

  static fromJSON({
    settings = [],
    themes,
    ...json
  }: HandbookSectionSchemaJSON) {
    const schema: HandbookSectionSchema = Object.assign(
      new HandbookSectionSchema(),
      json,
    )
    schema.settings = settings.map(fromSettingKey)
    if (themes) schema.themes = themes.map(Theme.fromJSON)
    return schema
  }

  getContentAttr(attr: string, props: any = {}) {
    const schema = this.content[attr]
    const className = classnames(
      dasherize(`section-${attr}`),
      schema.className,
      props.className,
    )
    return { ...schema, ...props, attr, className }
  }

  getEntryAttr(attr: string, props: any = {}) {
    const schema = this.entry[attr]
    const className = classnames(
      dasherize(`entry-${attr}`),
      schema.className,
      props.className,
    )
    return { ...schema, ...props, attr, className }
  }

  getDefaultValues(themeId = '') {
    // Get the default setting values
    const settingValues = getDefaultValues(this.settings)

    // Get the default theme (if none, we're done)
    const theme = this.themes?.find((t) => t.id === themeId)
    const themeValues = theme?.getDefaultValues()

    // Get the defaults, map any that are functions
    return { ...settingValues, ...themeValues }
  }

  getSettings(themeId: string) {
    const { settings } = this
    const theme = find(this.themes, { id: themeId })
    return theme ? settings.concat(theme.settings) : settings
  }

  buildSection(): BlissbookSection {
    const section: any = pick(this, 'type')

    // Add theme?
    if (this.themes) section.theme = this.defaultThemeId || this.themes[0].id

    // Add attrs?
    if (this.buildAttrs) section.attrs = this.buildAttrs()

    // Add empty entries?
    if (this.entry) section.listEntries = [{}, {}, {}]

    return section
  }

  get isChapter() {
    return !!this.childTypes
  }
}
