import { useEffect, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import 'react-code-input/styles/style.scss'

const inputStyle = {
  display: 'inline',
  fontFamily: 'monospace',
  fontSize: 32,
  height: 60,
  padding: 0,
  textAlign: 'center',
  width: 40,
}

export const CodeInput = (props) => {
  const [node, setNode] = useState()

  useEffect(() => {
    if (!node) return

    for (const inputNode of node.textInput) {
      inputNode.className = 'form-control tw-mr-2'
    }
  }, [node])

  return <ReactCodeInput {...props} inputStyle={inputStyle} ref={setNode} />
}
