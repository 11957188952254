import classnames from 'classnames'
import { useField } from 'formik'
import { forwardRef } from 'react'
import { Checkbox } from '../../input/Checkbox'

export const Field = forwardRef(({ className, onChange, ...props }, ref) => {
  const [field, { error, touched, value }] = useField(props.name)
  const showError = touched && error
  return (
    <Checkbox
      {...field}
      {...props}
      className={classnames('form-group', className, {
        'has-error': showError,
      })}
      onChange={(event, value) => {
        field.onChange(event)
        if (onChange) onChange(event, value)
      }}
      ref={ref}
      value={value}
    />
  )
})
