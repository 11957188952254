import { Button, Tooltip } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import type React from 'react'
import { forwardRef } from 'react'
import { renderToolbarTooltip } from './tooltip'

export type ToolbarButtonProps =
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    active?: boolean
    className?: string
    keyboardShortcut?: string
    label: string
  }

export const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  ({ active, className, keyboardShortcut, label, ...props }, ref) => {
    const { disabled } = props
    const tooltip = renderToolbarTooltip(label, keyboardShortcut)
    return (
      <Tooltip content={tooltip} disabled={disabled}>
        <Button
          {...props}
          className={cx('btn-tool', { active }, className)}
          ref={ref}
        />
      </Tooltip>
    )
  },
)
