import { useIsomorphicLayoutEffect } from '@blissbook/ui/util/hooks'
import { useIntercomAlignment } from '@blissbook/ui/util/integrations/intercom'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {
  type ReactNode,
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import type { match } from 'react-router'
import { Button, type ButtonProps } from '../buttons'
import { FormSubmit, type FormSubmitProps } from '../form'
import { Dropdown } from '../popper'
import { AnimatedPortal } from '../portal'
import { ScrollContainer, useScrollLock } from '../scroll'
import { generateModalId, setModalId, useModalMatch } from './router'

export const Context = React.createContext<{
  onClose: () => void
}>({
  onClose: () => {},
})

export type ComponentProps = {
  children?: ReactNode
  className?: string
  height?: number | string
  match?: any
  noOverlay?: boolean
  onClose: () => void
  position?: 'center' | 'full' | 'right'
  shouldCloseOnEsc?: boolean
  shouldCloseOnOverlayClick?: boolean
  width?: number
}

export const Component = ({
  className,
  height,
  match, // Ignore from Modal.Router if it comes through
  noOverlay,
  onClose,
  position = 'right',
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  width,
  ...props
}: ComponentProps) => {
  const [modalNode, setModalNode] = useState<HTMLElement>()
  useIntercomAlignment('left')
  useScrollLock()

  // Focus on new node
  useEffect(() => {
    if (!modalNode || !shouldCloseOnEsc) return

    // Ensure the modal has focus
    const hasFocus = modalNode.contains(document.activeElement)
    if (!hasFocus) modalNode.focus()
  }, [modalNode, shouldCloseOnEsc])

  const handleClick = useCallback(
    (event) => {
      if (!modalNode) return
      const shouldClose =
        !modalNode.contains(event.target) && shouldCloseOnOverlayClick
      if (shouldClose) onClose()
    },
    [modalNode, shouldCloseOnOverlayClick],
  )

  const handleKeyDown = useCallback(
    (event) => {
      const shouldClose = event.keyCode === 27 && shouldCloseOnEsc
      if (shouldClose) onClose()
    },
    [shouldCloseOnEsc],
  )

  return (
    <Context.Provider value={{ onClose }}>
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: requires some work */}
      <div
        className={cx(
          'modal',
          noOverlay && 'tw-bg-transparent tw-pointer-events-none',
          className,
          position,
        )}
        onClick={handleClick}
      >
        <div
          {...props}
          className={cx('modal-dialog', className, position)}
          onKeyDown={handleKeyDown}
          ref={setModalNode}
          style={{ height, width }}
          tabIndex={-1}
        />
      </div>
    </Context.Provider>
  )
}

export type ContentProps = {
  className?: string
  children?: ReactNode
}

export const Content = forwardRef<HTMLDivElement, ContentProps>(
  ({ className, ...props }, ref) => (
    <div {...props} className={cx('modal-content', className)} ref={ref} />
  ),
)

export type CloseProps = {
  children?: ReactNode
  className?: string
}

export const Close = ({ className, children, ...props }: CloseProps) => {
  const { onClose } = useContext(Context)
  return (
    <Button
      {...props}
      className={cx('modal-close btn-icon', className)}
      css={{
        transform: children ? 'translateY(-8px)' : undefined,
      }}
      onClick={onClose}
    >
      <FontAwesomeIcon icon={['far', 'times']} />
      {children && <div className='tw-text-xs tw-uppercase'>{children}</div>}
    </Button>
  )
}

export type HeaderProps = {
  children?: ReactNode
  className?: string
  closeButtonText?: string
  hideCloseButton?: boolean
  title?: string
}

export const Header = ({
  children,
  className,
  closeButtonText,
  hideCloseButton,
  title,
  ...props
}: HeaderProps) => (
  <div {...props} className={cx('modal-header', className)}>
    <div className='tw-flex-1'>{children}</div>
    {!hideCloseButton && <Close>{closeButtonText}</Close>}
  </div>
)

export type TitleProps = {
  children?: ReactNode
  className?: string
}

export const Title = forwardRef<HTMLDivElement, TitleProps>(
  ({ className, ...props }, ref) => (
    <h2
      {...props}
      className={cx('modal-title rw-wysiwyg', className)}
      ref={ref}
    />
  ),
)

export type SubtitleProps = {
  children?: ReactNode
  className?: string
}

export const Subtitle = ({ className, ...props }: SubtitleProps) => (
  <p {...props} className={cx('modal-subtitle p-em', className)} />
)

export type BodyProps = {
  children?: ReactNode
  className?: string
  scrollClassName?: string
  style?: React.CSSProperties
}

export const Body = forwardRef<HTMLDivElement, BodyProps>(
  ({ className, scrollClassName, ...props }, ref) => (
    <ScrollContainer
      {...props}
      className={cx('modal-body', className)}
      innerClassName={cx('modal-body-scroll', scrollClassName)}
      ref={ref}
      showScrollText
    />
  ),
)

export type DividerProps = {
  className?: string
}

export const Divider = ({ className, ...props }: DividerProps) => (
  <div {...props} className={cx('modal-divider', className)} />
)

export type FooterProps = {
  children?: ReactNode
  className?: string
}

export const Footer = ({ className, ...props }: FooterProps) => (
  <div {...props} className={cx('modal-footer', className)} />
)

export type SubmitProps = FormSubmitProps & {
  hideCancel?: boolean
}

export const Submit = ({
  hideCancel, // Hide the cancel button
  onCancel, // Custom cancel handler
  ...props
}: SubmitProps) => {
  const { onClose } = useContext(Context)
  return (
    <FormSubmit
      {...props}
      onCancel={hideCancel ? undefined : onCancel || onClose}
    />
  )
}

// Animated Modals ------------------------------------------------------------

type WrappedComponentProps = {
  onClose: () => void
}

type AnimatedComponentProps = WrappedComponentProps & {
  isOpen: boolean
}

type AnimatedButtonProps<ModalProps> = ButtonProps & {
  modalProps?: ModalProps
  onToggle?: (isOpen: boolean) => void
}

type AnimatedButton<ModalProps> = React.FC<AnimatedButtonProps<ModalProps>>

type WrappedComponent<ModalProps> = React.FC<ModalProps> & {
  Animated: React.FC<ModalProps & AnimatedComponentProps>
  Button: AnimatedButton<ModalProps>
}

// Animate the modal
function wrapAnimated<ModalProps>(ModalComponent: React.FC<ModalProps>) {
  return ({ isOpen, ...props }: ModalProps & AnimatedComponentProps) => (
    <AnimatedPortal in={isOpen}>
      {/* @ts-ignore */}
      <ModalComponent {...props} />
    </AnimatedPortal>
  )
}

// Create a modal button for this modal
function wrapStateButton<ModalProps>(
  ModalComponent: WrappedComponent<ModalProps>,
) {
  return React.forwardRef<HTMLButtonElement, AnimatedButtonProps<ModalProps>>(
    ({ modalProps, onToggle, ...props }, ref) => {
      // Get the modalId
      const [isOpen, setOpen] = useState(false)
      const dropdownContext = useContext(Dropdown.Context)

      // Callback for when the modal is open/closed
      useIsomorphicLayoutEffect(() => {
        if (onToggle) onToggle(isOpen)
      }, [isOpen])

      function handleShowModal() {
        // If we are in a dropdown menu, close the dropdown
        if (dropdownContext.isOpen) {
          dropdownContext.setOpen(false)
        }

        setOpen(true)
      }

      const onCloseModal = useCallback(() => {
        setOpen(false)
      }, [setOpen])

      return (
        <Fragment>
          <Button {...props} onClick={handleShowModal} ref={ref} />
          <ModalComponent.Animated
            {...modalProps}
            isOpen={isOpen}
            onClose={onCloseModal}
          />
        </Fragment>
      )
    },
  )
}

// Animated and attach a .Button to MyCustomModal for MyCustomModal.Button
export function wrap<ModalProps>(
  component: React.FC<ModalProps & WrappedComponentProps>,
) {
  const wrapped = component as WrappedComponent<ModalProps>
  wrapped.Animated = wrapAnimated(component)
  wrapped.Button = wrapStateButton(wrapped)
  return wrapped
}

// Routeable Modals -----------------------------------------------------------

type RouteableComponentProps = {
  match: match
  onClose: () => void
}

type RoutableButtonProps<ModalProps> = ButtonProps & {
  modalProps?: ModalProps
}

type RouteableComponent<ModalProps> = React.FC<
  ModalProps & RouteableComponentProps
> & {
  Button: React.FC<RoutableButtonProps<ModalProps>>
  path: string
}

type ModalRouteProps = {
  // biome-ignore lint/complexity/noBannedTypes: requires work
  component: RouteableComponent<{}>
  path: string
}

export function Router({ routes }: { routes: ModalRouteProps[] }) {
  return (
    <>
      {routes.map((route) => (
        <Route {...route} key={route.path} />
      ))}
    </>
  )
}

// A Modal controlled by the URL query param
export function Route({ component: Component, path }: ModalRouteProps) {
  // Attach path for Component.Button
  Component.path = path

  // Look for match
  const match = useModalMatch({
    path,
    exact: true,
    strict: true,
  })

  return (
    <AnimatedPortal in={!!match}>
      <Component
        match={match}
        onClose={() => {
          setModalId(undefined)
        }}
      />
    </AnimatedPortal>
  )
}

// Create a routable modal button for this modal
function wrapRouteButton<ModalProps>(
  Component: RouteableComponent<ModalProps>,
) {
  return React.forwardRef<HTMLButtonElement, RoutableButtonProps<ModalProps>>(
    ({ modalProps, ...props }, ref) => {
      const dropdownContext = useContext(Dropdown.Context)

      function handleShowModal() {
        // If we are in a dropdown menu, close the dropdown
        if (dropdownContext.isOpen) {
          dropdownContext.setOpen(false)
        }

        const id = generateModalId(Component.path, modalProps)
        setModalId(id)
      }

      return <Button {...props} onClick={handleShowModal} ref={ref} />
    },
  )
}

// Register a modal for the URL based modal-provider
export function wrapRoutable<ModalProps>(
  component: React.FC<ModalProps & RouteableComponentProps>,
) {
  const wrapped = component as RouteableComponent<ModalProps>
  wrapped.Button = wrapRouteButton(wrapped)
  return wrapped
}
