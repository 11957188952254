import {
  emptyUrl,
  formatHref,
  getHrefProtocol,
  isRelativeUrl,
} from '@blissbook/lib/sanitize'
import { Button, SearchInput, Tooltip } from '@blissbook/ui/lib'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import { useEffect, useState } from 'react'
import {
  type Bookmark,
  type BookmarksInput,
  bookmarkIcon,
  findBookmark,
  useBookmarkOptions,
} from './bookmark'

export type LinkToolbarProps = {
  bookmarks: BookmarksInput
  bookmarkLabel: string
  initialValue?: string
  onCancel?: () => void
  onSubmit: (href: string) => void
  onRemove?: () => void
}

export const LinkToolbarForm: React.FC<LinkToolbarProps> = ({
  bookmarks,
  bookmarkLabel,
  initialValue,
  onCancel,
  onSubmit,
  onRemove,
}) => {
  const width = 320
  const [isEditing, setEditing] = useState(!initialValue)
  const [bookmark] = useState(() => findBookmark(bookmarks, initialValue))
  const [inputEl, setInputEl] = useState<HTMLInputElement>()
  const [value, setValue] = useState(() => initialValue || '')
  const searchOptions = useBookmarkOptions(bookmarks)

  const href = formatHref(value)
  const isValid = href !== emptyUrl
  const protocol = getHrefProtocol(href)

  let searchIcon: IconProp | boolean
  if (value && isRelativeUrl(value[0])) {
    searchIcon = bookmarkIcon
  } else if (protocol) {
    searchIcon = protocol.icon
  } else {
    searchIcon = false
  }

  function handleSubmit(value?: string) {
    const isValid = formatHref(value) !== emptyUrl
    if (!isValid) return

    const href = formatHref(value)
    onSubmit(href)
  }

  useEffect(() => {
    if (inputEl && isEditing) {
      inputEl.select()
    }
  }, [inputEl, isEditing])

  const removeButton = onRemove && (
    <Tooltip content='Remove Link' key='remove'>
      <Button
        className='btn-icon'
        onClick={() => {
          onRemove()
        }}
      >
        <FontAwesomeIcon icon={['fad', 'unlink']} />
      </Button>
    </Tooltip>
  )

  const submitButton = (
    <Button
      className={`btn ${isValid ? 'btn-success' : 'btn-secondary'} tw-px-2`}
      key='submit'
      onClick={() => {
        handleSubmit(value)
      }}
    >
      <FontAwesomeIcon icon='check' />
    </Button>
  )

  return (
    <div
      className='tw-flex tw-gap-1.5 tw-items-center tw-p-1.5 tw-font-sans'
      style={{ width }}
    >
      <SearchInput
        clearIcon={false}
        className='tw-flex-1'
        debounce={0}
        dropdownClassName='-tw-ml-1.5 tw-border-0 tw-rounded-t-none'
        dropdownOffset={4}
        dropdownWidth={width}
        getOptions={searchOptions}
        inputClassName={!isEditing ? 'tw-bg-gray-100' : undefined}
        inputRef={setInputEl}
        name='url'
        onChangeValue={setValue}
        onEnterKey={() => {
          handleSubmit(value)
        }}
        onFocus={() => {
          setEditing(true)
        }}
        onKeyDown={(event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            event.preventDefault()
            onCancel?.()
          }
        }}
        onSelect={(option: Bookmark) => {
          handleSubmit(option.href)
        }}
        placeholder={
          bookmarks
            ? `Enter url, email, or ${bookmarkLabel}`
            : 'Enter url or email'
        }
        searchIcon={searchIcon}
        title={isEditing ? undefined : value}
        type='text'
        value={bookmark && !isEditing ? bookmark.label : value}
      />

      {isEditing ? submitButton : removeButton}
    </div>
  )
}
