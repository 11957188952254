import classnames from 'classnames'
import type React from 'react'
import { forwardRef } from 'react'

type ButtonLinkProps = React.ButtonHTMLAttributes<HTMLButtonElement>

export const ButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  ({ className, ...props }, ref) => (
    <button
      {...props}
      className={classnames('btn-link', className)}
      ref={ref}
      type='button'
    />
  ),
)
