import omit from 'lodash/omit'
import { Fragment } from 'react'

const BorderLayout = ({
  borderIndex = 0,
  borders,
  bottom = 0,
  children,
  left = 0,
  position = 'absolute',
  right = 0,
  top = 0,
  transitionDuration = 500,
}) => {
  const [border, ...nextBorders] = borders
  const rect = { bottom, left, right, top }
  const transition = `all ease ${transitionDuration}ms`

  // No borders left, it's the center
  if (!border)
    return (
      <div
        children={children}
        style={{
          ...getBorderedStyle(rect, position),
          transition,
        }}
      />
    )

  // Determine border positioning
  const { offset = 0 } = border
  const sideKey = Object.keys(sidesByKey).find((key) => border[key] > 0)
  const { otherSideKey, sizeKey } = sidesByKey[sideKey]
  const style = {
    ...omit(rect, otherSideKey, sideKey),
    [sideKey]: rect[sideKey] + offset,
    [sizeKey]: border[sideKey],
    position,
    transition,
    zIndex: 1000 - borderIndex,
  }

  // Determine where the next rect is
  const nextRect = {
    ...rect,
    [sideKey]: rect[sideKey] + border[sideKey] + offset,
  }

  // Recursively add more borders
  return (
    <Fragment>
      {border.Component && <border.Component {...border.props} style={style} />}

      <BorderLayout
        {...nextRect}
        borderIndex={borderIndex + 1}
        borders={nextBorders}
        children={children}
        position={position}
        transitionDuration={transitionDuration}
      />
    </Fragment>
  )
}

export const FixedBorderLayout = (props) => (
  <BorderLayout {...props} position='fixed' />
)

export const FullScreenBorderLayout = ({ minWidth = 1000, ...props }) => (
  <div css={{ height: '100vh', overflow: 'auto' }}>
    <div css={{ height: '100%', minWidth, position: 'relative' }}>
      <BorderLayout {...props} />
    </div>
  </div>
)

const sidesByKey = {
  bottom: {
    otherSideKey: 'top',
    sizeKey: 'height',
  },
  left: {
    otherSideKey: 'right',
    sizeKey: 'width',
  },
  right: {
    otherSideKey: 'left',
    sizeKey: 'width',
  },
  top: {
    otherSideKey: 'bottom',
    sizeKey: 'height',
  },
}

const getBorderedStyle = (rect, position) =>
  position === 'fixed'
    ? {
        paddingBottom: rect.bottom,
        paddingLeft: rect.left,
        paddingRight: rect.right,
        paddingTop: rect.top,
      }
    : {
        ...rect,
        overflowX: 'hidden',
        overflowY: 'auto',
        position,
      }
