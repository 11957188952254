import type { DocumentVersion } from '@blissbook/lib/document'
import type { AudienceRootExpression } from '@blissbook/lib/expression'
import type { PersonalizationInput } from './Personalization'
import { evaluateAudienceExpression } from './expression'

export type DocumentAccessControl = {
  languageCode?: string
  personalization?: PersonalizationInput
}

export function canAccessDocument(
  document: DocumentVersion,
  accessControl: DocumentAccessControl,
) {
  const { languageCode, personalization } = accessControl
  // Language mismatch = disallow
  if (languageCode && document.languageCode !== languageCode) return false
  // No personalization  = allow
  if (!personalization) return true
  // No audienceExpression = allow
  if (!document.audienceExpression) return true
  // Personalize
  return evaluateAudienceExpression(
    document.audienceExpression,
    personalization,
  )
}

export type DocumentHtmlBlockNode = {
  audienceExpression?: AudienceRootExpression
  outerHTML: string
  text: string
}

export type DocumentHtmlContainerNode = {
  audienceExpression?: AudienceRootExpression
  closingTagHTML: string
  content: DocumentHtmlNode[]
  openingTagHTML: string
}

export type DocumentHtmlNode = DocumentHtmlBlockNode | DocumentHtmlContainerNode

export type PersonalizedHtmlContent = {
  html: string
  text: string
}

export function personalizeHtmlContent(
  content: DocumentHtmlNode[],
  personalization: PersonalizationInput,
): PersonalizedHtmlContent {
  return (
    content
      .filter((node) => {
        const { audienceExpression } = node
        if (!audienceExpression) return true
        return evaluateAudienceExpression(audienceExpression, personalization)
      })
      .map((node) => {
        // If this is a leaf node, we're done
        if (!('content' in node)) {
          return {
            html: node.outerHTML,
            text: node.text,
          }
        }

        // If this is a container node, we need to personalize the child content
        const personalizedContent = personalizeHtmlContent(
          node.content,
          personalization,
        )
        return {
          html:
            node.openingTagHTML +
            personalizedContent.html +
            node.closingTagHTML,
          text: personalizedContent.text,
        }
      })
      // Join the content together.
      .reduce(
        (acc, curr) => ({
          html: acc.html + curr.html,
          text: acc.text + '\n' + curr.text,
        }),
        { html: '', text: '' },
      )
  )
}
