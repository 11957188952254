import type { ComponentProps } from 'react'
import { CloseButton } from '../buttons'
import { PersonProfileView } from './PersonProfileView'

export type PersonProfileCardProps = ComponentProps<'div'> & {
  disabled?: boolean
  onRemove?: () => void
  person: {
    archivedAt?: string
    avatarUrl?: string
    email?: string
    fullName: string
  }
}

export const PersonProfileCard = ({
  className = '',
  disabled,
  onRemove,
  person,
  ...props
}: PersonProfileCardProps) => {
  return (
    <div
      className={`tw-flex tw-items-center tw-justify-between tw-gap-2 tw-bg-gray-50 tw-border tw-border-gray-200 tw-rounded tw-py-2 tw-px-3 ${className}`}
    >
      <div
        {...props}
        className={`tw-flex tw-items-center tw-gap-2 ${person.archivedAt ? 'tw-opacity-50' : ''}`}
      >
        <PersonProfileView person={person} size={28} />
        <div className='tw-text-sm'>
          <div className='tw-font-semibold'>{person.fullName}</div>
          <div className='tw-text-black-faded -tw-mt-1'>{person.email}</div>
        </div>
      </div>

      {!disabled && onRemove && (
        <CloseButton className='tw-text-gray-500 tw-p-1' onClick={onRemove} />
      )}
    </div>
  )
}

PersonProfileCard.displayName = 'PersonProfileCard'
