import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { isValidEmailAddress } from './email'
import { emptyUrl, isRelativeUrl, sanitizeUrl } from './url'

export type HrefProtocol = {
  icon: IconProp
  value: string
}

const fileProtocol: HrefProtocol = {
  icon: ['fad', 'file-export'],
  value: 'file:',
}

const ftpProtocol: HrefProtocol = {
  ...fileProtocol,
  value: 'ftp:',
}

const httpProtocol: HrefProtocol = {
  icon: ['fad', 'arrow-up-right-from-square'],
  value: 'http:',
}

const httpsProtocol: HrefProtocol = {
  ...httpProtocol,
  value: 'https:',
}

const mailtoProtocol: HrefProtocol = {
  icon: ['fad', 'envelope'],
  value: 'mailto:',
}

const phoneProtocol: HrefProtocol = {
  icon: ['fad', 'phone'],
  value: 'tel:',
}

const httpProtocols = [httpProtocol, httpsProtocol]

export const hrefProtocols: HrefProtocol[] = [
  ...httpProtocols,
  fileProtocol,
  ftpProtocol,
  mailtoProtocol,
  phoneProtocol,
]

export function getHrefProtocol(href: string) {
  try {
    const url = new URL(href)
    return hrefProtocols.find((p) => p.value === url.protocol)
  } catch (_error) {
    return undefined
  }
}

const filePrefix = 'file:///'
const fileRegEx = /^\w:\\/
const httpPrefix = 'http://'

function isValidHrefUrl(url: URL) {
  const hostnameRegex = /^([a-z0-9-]+\.)+[a-z]{2,}$/i
  const isWebProtocol = httpProtocols.some((p) => p.value === url.protocol)
  return (
    hrefProtocols.some((p) => p.value === url.protocol) &&
    (url.protocol === 'file:' ||
      (isWebProtocol ? hostnameRegex.test(url.hostname) : true))
  )
}

function formatHrefUrl(href?: string) {
  if (!href) return emptyUrl

  // Email address?
  if (isValidEmailAddress(href) && !href.startsWith(mailtoProtocol.value)) {
    return mailtoProtocol.value + href
  }

  // File?
  if (fileRegEx.test(href)) {
    return filePrefix + href
  }

  // Relative URL
  if (isRelativeUrl(href)) return href

  // Check if it's already a valid URL
  try {
    const url = new URL(href)
    if (isValidHrefUrl(url)) return href
  } catch (_error) {
    // Ignore
  }

  // If not valid, try prefixing with http://
  try {
    const prefixedHref = httpPrefix + href
    const prefixedUrl = new URL(prefixedHref)
    if (isValidHrefUrl(prefixedUrl)) return prefixedHref
  } catch (_error) {
    // Ignore
  }

  return emptyUrl
}

export function formatHref(href: string) {
  if (href === '') return ''
  const hrefUrl = formatHrefUrl(href)
  return sanitizeUrl(hrefUrl)
}
