import { MailtoSupportLink } from '../links'
import { ErrorPage } from './ErrorPage'

export function UnknownErrorPage() {
  return (
    <ErrorPage heading='Whoops! Something went wrong.'>
      We've logged the error and routinely check our logs to resolve issues. You
      may try refreshing the page or clicking the back button in your browser.
      If that doesn't work and this issue is preventing you from accomplishing a
      task, please contact <MailtoSupportLink />. You can help us resolve the
      issue by describing the action you were taking when it occurred. Thank
      you!
    </ErrorPage>
  )
}
