export default ({ color, fill, size, ...props }) => (
  <div
    {...props}
    css={{
      backgroundColor: fill ? color : undefined,
      border: fill ? undefined : `3px solid ${color}`,
      height: size,
      width: size,
    }}
  />
)
