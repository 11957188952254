import { isTextSelection } from '@blissbook/lib/document'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToolbarButton } from '../toolbar'
import { hasExtension } from '../util'
import type { ToolComponent } from './types'

export const HeadingTool: ToolComponent = ({ editor, ...props }) => {
  const canToggle =
    hasExtension(editor, 'heading') && isTextSelection(editor.state.selection)
  return (
    <ToolbarButton
      {...props}
      active={editor?.isActive('heading')}
      disabled={!canToggle}
      label='Heading 3'
      onClick={async () => {
        editor.chain().focus().toggleHeading().run()
      }}
    >
      <FontAwesomeIcon icon='heading' />
    </ToolbarButton>
  )
}
