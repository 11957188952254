import classnames from 'classnames'
import { useField } from 'formik'
import isString from 'lodash/isString'
import { TextInput } from '../../input'

const InputGroupPrepend = ({ children }) =>
  isString(children) ? (
    <div className='input-group-prepend'>
      <span className='input-group-text'>{children}</span>
    </div>
  ) : (
    children
  )

const InputGroupAppend = ({ children }) =>
  isString(children) ? (
    <div className='input-group-append'>
      <span className='input-group-text'>{children}</span>
    </div>
  ) : (
    children
  )

export const Field = ({
  append,
  className,
  help,
  hideError = false,
  id,
  inputStyle,
  label,
  onChange,
  prepend,
  size,
  style,
  ...props
}) => {
  const { name } = props
  const [field, { error, touched }] = useField(name)
  const showError = touched && error
  id = id || name
  return (
    <div className={classnames('form-group', className)} style={style}>
      {label && (
        <div className='tw-flex tw-items-center tw-mb-2'>
          <label
            children={label}
            className={classnames('tw-mb-0', { 'tw-text-red-700': showError })}
            htmlFor={id}
            style={{ display: 'block', flex: 1 }}
          />
          {help}
        </div>
      )}

      <div className='input-group'>
        {prepend && <InputGroupPrepend>{prepend}</InputGroupPrepend>}

        <TextInput
          {...field}
          {...props}
          id={id}
          className={classnames(
            'form-control',
            size && `form-control-${size}`,
            { 'is-invalid': showError },
          )}
          onChange={(event, value) => {
            field.onChange(event)
            if (onChange) onChange(event, value)
          }}
          style={inputStyle}
        />

        {append && <InputGroupAppend>{append}</InputGroupAppend>}
      </div>

      {showError && field.value && !hideError && (
        <div className='tw-text-red-700 tw-mt-1'>{error}</div>
      )}
    </div>
  )
}
