export default ({ color, size, ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox='0 0 82 68'
    role='img'
    aria-label='binoculars'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-710.000000, -2815.000000)'>
        <g transform='translate(0.000000, 1386.000000)'>
          <g transform='translate(156.000000, 1346.000000)'>
            <g transform='translate(554.000000, 82.000000)'>
              <polygon
                fill={color}
                points='34.578 26.7563 47.253 26.7563 47.253 22.7563 34.578 22.7563'
              />
              <polygon
                fill={color}
                points='34.578 35.7613 47.253 35.7613 47.253 31.7613 34.578 31.7613'
              />
              <g transform='translate(0.000000, 0.154300)'>
                <g transform='translate(47.000000, 0.000000)'>
                  <path
                    d='M1.2451,13.4717 C0.6211,12.2577 0.2921,10.8897 0.2921,9.5167 C0.2921,4.7357 4.1821,0.8457 8.9631,0.8457 C13.7451,0.8457 17.6351,4.7357 17.6351,9.5167 L13.6351,9.5167 C13.6351,6.9417 11.5391,4.8457 8.9631,4.8457 C6.3881,4.8457 4.2921,6.9417 4.2921,9.5167 C4.2921,10.2677 4.4641,10.9837 4.8041,11.6457 L1.2451,13.4717 Z'
                    fill={color}
                  />
                </g>
                <path
                  d='M48.9189,49.7617 L44.9209,49.6227 L45.9589,19.6767 L45.9579,19.2607 C45.9579,12.5777 51.3959,7.1407 58.0789,7.1407 C59.1219,7.1467 60.0869,7.2667 61.0339,7.4967 C63.7769,8.1667 66.1979,9.7137 67.8499,11.8557 C68.8369,13.1357 69.3149,14.4987 69.7779,15.8167 C70.1009,16.7377 70.4359,17.6327 70.7659,18.5137 L74.5429,28.6967 C75.1019,30.2057 76.0589,32.6367 76.9849,34.9877 C77.9149,37.3487 78.8759,39.7897 79.4479,41.3327 L75.6969,42.7237 C75.1399,41.2187 74.1859,38.7957 73.2629,36.4527 C72.3299,34.0837 71.3649,31.6337 70.7919,30.0867 L67.0199,19.9147 C66.6809,19.0097 66.3359,18.0887 66.0159,17.1787 C65.6129,16.0287 65.2759,15.0687 64.6829,14.2987 C63.5839,12.8737 61.9509,11.8387 60.0859,11.3837 C59.4409,11.2267 58.7859,11.1447 58.1379,11.1407 C53.6009,11.1407 49.9579,14.7827 49.9579,19.2607 L49.9579,19.7457 L48.9189,49.7617 Z'
                  fill={color}
                />
                <g transform='translate(44.000000, 31.000000)'>
                  <path
                    d='M19.2275,4.3848 C11.3385,4.3848 4.9195,10.8028 4.9195,18.6928 C4.9195,26.5818 11.3385,32.9998 19.2275,32.9998 C27.1165,32.9998 33.5355,26.5818 33.5355,18.6928 C33.5355,10.8028 27.1165,4.3848 19.2275,4.3848 M19.2275,36.9998 C9.1325,36.9998 0.9195,28.7868 0.9195,18.6928 C0.9195,8.5978 9.1325,0.3848 19.2275,0.3848 C29.3225,0.3848 37.5355,8.5978 37.5355,18.6928 C37.5355,28.7868 29.3225,36.9998 19.2275,36.9998'
                    fill={color}
                  />
                </g>
                <g transform='translate(16.000000, 0.000000)'>
                  <path
                    d='M17.291,13.4717 L13.731,11.6457 C14.071,10.9847 14.243,10.2677 14.243,9.5167 C14.243,6.9417 12.147,4.8457 9.572,4.8457 C6.996,4.8457 4.901,6.9417 4.901,9.5167 L0.901,9.5167 C0.901,4.7357 4.791,0.8457 9.572,0.8457 C14.353,0.8457 18.243,4.7357 18.243,9.5167 C18.243,10.8887 17.914,12.2567 17.291,13.4717'
                    fill={color}
                  />
                </g>
                <path
                  d='M32.6162,49.7617 L31.5772,19.2607 C31.5772,14.7827 27.9342,11.1407 23.4562,11.1407 C22.7492,11.1447 22.0942,11.2267 21.4492,11.3837 C19.5852,11.8387 17.9522,12.8737 16.8522,14.2987 C16.2602,15.0667 15.9242,16.0247 15.5342,17.1327 C15.1992,18.0887 14.8542,19.0097 14.5152,19.9147 L10.7432,30.0867 C10.1702,31.6337 9.2052,34.0837 8.2722,36.4527 C7.3492,38.7957 6.3952,41.2187 5.8382,42.7237 L2.0872,41.3327 C2.6592,39.7897 3.6202,37.3487 4.5502,34.9877 C5.4762,32.6367 6.4332,30.2057 6.9922,28.6967 L10.7692,18.5137 C11.0992,17.6327 11.4342,16.7377 11.7442,15.8527 C12.2222,14.4927 12.6992,13.1327 13.6852,11.8557 C15.3382,9.7137 17.7582,8.1667 20.5012,7.4967 C21.4482,7.2667 22.4132,7.1467 23.3732,7.1407 C30.1402,7.1407 35.5772,12.5777 35.5772,19.2607 L35.5772,19.7457 L36.6142,49.6227 L32.6162,49.7617 Z'
                  fill={color}
                />
                <g transform='translate(0.000000, 31.000000)'>
                  <path
                    d='M18.3076,4.3848 C10.4186,4.3848 3.9996,10.8028 3.9996,18.6928 C3.9996,26.5818 10.4186,32.9998 18.3076,32.9998 C26.1966,32.9998 32.6156,26.5818 32.6156,18.6928 C32.6156,10.8028 26.1966,4.3848 18.3076,4.3848 M18.3076,36.9998 C8.2126,36.9998 -0.0004,28.7868 -0.0004,18.6928 C-0.0004,8.5978 8.2126,0.3848 18.3076,0.3848 C28.4026,0.3848 36.6156,8.5978 36.6156,18.6928 C36.6156,28.7868 28.4026,36.9998 18.3076,36.9998'
                    fill={color}
                  />
                </g>
                <path
                  d='M63.8477,61.5928 C60.5677,61.5928 57.4837,60.3158 55.1637,57.9948 L57.9917,55.1668 C59.5557,56.7318 61.6357,57.5928 63.8477,57.5928 C68.4137,57.5928 72.1287,53.8778 72.1287,49.3118 L76.1287,49.3118 C76.1287,56.0828 70.6187,61.5928 63.8477,61.5928'
                  fill={color}
                />
                <path
                  d='M18.3945,61.5928 C15.1145,61.5928 12.0305,60.3158 9.7105,57.9948 L12.5395,55.1668 C14.1035,56.7318 16.1835,57.5928 18.3945,57.5928 C22.9605,57.5928 26.6755,53.8778 26.6755,49.3118 L30.6755,49.3118 C30.6755,56.0828 25.1665,61.5928 18.3945,61.5928'
                  fill={color}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
