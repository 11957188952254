export type ResizeEdge = {
  className: string
  xFactor?: number
  yFactor?: number
}

export const topSide: ResizeEdge = {
  className: 'resize-side resize-t',
  yFactor: -1,
}

export const rightSide: ResizeEdge = {
  className: 'resize-side resize-r',
  xFactor: 1,
}

export const bottomSide: ResizeEdge = {
  className: 'resize-side resize-b',
  yFactor: 1,
}

export const leftSide: ResizeEdge = {
  className: 'resize-side resize-l',
  xFactor: -1,
}

export const topRightCorner: ResizeEdge = {
  className: 'resize-corner resize-t resize-r',
  xFactor: 1,
  yFactor: -1,
}

export const bottomRightCorner: ResizeEdge = {
  className: 'resize-corner resize-b resize-r',
  xFactor: 1,
  yFactor: 1,
}

export const bottomLeftCorner: ResizeEdge = {
  className: 'resize-corner resize-b resize-l',
  xFactor: -1,
  yFactor: 1,
}

export const topLeftCorner: ResizeEdge = {
  className: 'resize-corner resize-t resize-l',
  xFactor: -1,
  yFactor: -1,
}

export const resizeSides = [topSide, rightSide, bottomSide, leftSide]

export const resizeCorners = [
  topRightCorner,
  bottomRightCorner,
  bottomLeftCorner,
  topLeftCorner,
]

export const resizeEdges = [...resizeSides, ...resizeCorners]

export type Size = {
  height: number
  width: number
}

function createSizeElement(resizeEl: HTMLElement) {
  const sizeEl = document.createElement('div')
  sizeEl.className = 'resize-size tw-whitespace-nowrap'
  sizeEl.contentEditable = 'false'

  setTimeout(() => {
    resizeEl.append(sizeEl)
    renderSize(resizeEl, sizeEl)
  })

  return sizeEl
}

function renderSize(resizeEl: HTMLElement, sizeEl: HTMLElement) {
  const height = resizeEl.offsetHeight
  const width = resizeEl.offsetWidth
  const isSmall = width < 100 || height < 100
  sizeEl.classList.toggle('resize-size-sm', isSmall)
  sizeEl.classList.toggle('resize-size-lg', !isSmall)
  sizeEl.innerText = `${width} × ${height}`
}

export type ResizingOptions = {
  onResize?: (resizeEl: HTMLElement) => void
  onSubmit?: (size: Size, axis: ResizeEdge) => void
  setSize: (size: Size, initialSize: Size, axis: ResizeEdge) => Size
}

export type ResizeOptions = ResizingOptions & {
  edges: ResizeEdge[]
  showSize?: boolean
}

export type ResizingEdgeArgs = ResizingOptions & {
  event: MouseEvent
  edge: ResizeEdge
  edgeEl: HTMLElement
  resizeEl: HTMLElement
  sizeEl?: HTMLElement
}

function onMouseDownEdge({
  event,
  edge,
  edgeEl,
  onResize,
  onSubmit,
  resizeEl,
  setSize,
  sizeEl,
}: ResizingEdgeArgs) {
  let isDragging = false

  const { xFactor = 0, yFactor = 0 } = edge

  const initialPos = {
    x: event.clientX,
    y: event.clientY,
  }

  const initialSize = {
    height: resizeEl.offsetHeight,
    width: resizeEl.offsetWidth,
  }

  let prevSize = initialSize

  let timerId: NodeJS.Timeout | undefined

  // Drag handle
  const onDrag = (event: MouseEvent) => {
    // Stop highlighting anything
    event.stopPropagation()
    event.preventDefault()

    // Set the dragging state
    if (!isDragging) {
      isDragging = true
      edgeEl.classList.add('resizing')
      resizeEl.classList.add('resizing')

      timerId = setInterval(() => {
        if (sizeEl) renderSize(resizeEl, sizeEl)
      }, 100)
    }

    // Set to the desired size
    const deltaX = (event.clientX - initialPos.x) * xFactor
    const deltaY = (event.clientY - initialPos.y) * yFactor
    const size = setSize(
      {
        height: Math.round(initialSize.height + deltaY),
        width: Math.round(initialSize.width + deltaX),
      },
      initialSize,
      edge,
    )

    // Handle changes
    if (size.height !== prevSize.height || size.width !== prevSize.width) {
      onResize?.(resizeEl)
      prevSize = size
    }
  }

  // Drag complete
  const onMouseUp = () => {
    document.removeEventListener('mousemove', onDrag)
    document.removeEventListener('mouseup', onMouseUp)

    edgeEl.classList.remove('resizing')
    resizeEl.classList.remove('resizing')

    clearInterval(timerId)

    const size = {
      height: resizeEl.offsetHeight,
      width: resizeEl.offsetWidth,
    }

    if (
      size.height !== initialSize.height ||
      size.width !== initialSize.width
    ) {
      onSubmit?.(size, edge)
    }
  }

  document.addEventListener('mousemove', onDrag)
  document.addEventListener('mouseup', onMouseUp)
}

export function bindResize(
  resizeEl: HTMLElement,
  { edges, showSize, ...handlers }: ResizeOptions,
) {
  const sizeEl = showSize ? createSizeElement(resizeEl) : undefined

  for (const edge of edges) {
    const edgeEl = document.createElement('div')
    edgeEl.className = edge.className
    edgeEl.contentEditable = 'false'

    edgeEl.addEventListener('mousedown', (event) => {
      onMouseDownEdge({ ...handlers, edge, edgeEl, event, resizeEl, sizeEl })
      event.preventDefault()
      return true
    })

    resizeEl.append(edgeEl)
  }
}
