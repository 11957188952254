import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import {
  NodeSelectionToolbar,
  type NodeSelectionToolbarProps,
} from '../../selection/node'
import { ToolbarButton, ToolbarDivider } from '../../toolbar'
import {
  EmbedNodeSelectionAlignTool,
  EmbedNodeSelectionRemoveTool,
  EmbedNodeSelectionReplaceTool,
} from '../embed'

export const PdfNodeSelectionToolbar: React.FC<NodeSelectionToolbarProps> = (
  props,
) => {
  const { editor, removeNode, selection, updateNodeAttributes } = props
  const { node } = selection
  const { showThumbnail } = node.attrs
  return (
    <NodeSelectionToolbar {...props}>
      <EmbedNodeSelectionAlignTool {...props} />

      <ToolbarDivider />

      <ToolbarButton
        active={showThumbnail}
        label={!showThumbnail ? 'Show Thumbnail' : 'Show Embedded PDF'}
        onClick={() => {
          updateNodeAttributes({ showThumbnail: !showThumbnail })
        }}
      >
        <FontAwesomeIcon
          icon={showThumbnail ? ['fas', 'eye-slash'] : ['fas', 'eye']}
        />
      </ToolbarButton>

      <EmbedNodeSelectionReplaceTool
        label='PDF'
        onChange={(src) => updateNodeAttributes({ src })}
        pickSrc={editor.pickPdf}
        value={node.attrs.src}
      />

      <EmbedNodeSelectionRemoveTool label='PDF' onRemove={removeNode} />
    </NodeSelectionToolbar>
  )
}
