import type { Attrs } from 'prosemirror-model'
import type React from 'react'
import { ProseMirrorBubbleToolbar } from '../../toolbar'
import type { NodeSelectionViewProps } from './view'

export type NodeSelectionToolbarProps = NodeSelectionViewProps & {
  offset?: number
  removeNode: () => void
  updateNodeAttributes: (attrs: Attrs) => void
}

export type NodeSelectionToolbarComponent = React.FC<NodeSelectionToolbarProps>

export const NodeSelectionToolbar: NodeSelectionToolbarComponent = ({
  removeNode,
  selection,
  updateNodeAttributes,
  ...props
}) => {
  const { editor } = props
  const selectedNode = editor.view.nodeDOM(selection.from) as HTMLElement
  return <ProseMirrorBubbleToolbar {...props} offset={6} refEl={selectedNode} />
}
