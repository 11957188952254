import {
  isCellSelection,
  isNodeSelection,
  isTextSelection,
  keyboardShortcuts,
} from '@blissbook/lib/document'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { Selection } from 'prosemirror-state'
import type { Editor } from '../editor'
import { ToolbarButton } from '../toolbar'
import { hasExtension } from '../util'
import type { ToolComponent } from './types'

export function canEditorMarkText(editor: Editor | undefined, type: string) {
  if (!editor) return false
  return (
    hasExtension(editor, type) && canSelectionMarkText(editor.state.selection)
  )
}

export function canSelectionMarkText(selection: Selection | undefined) {
  if (isCellSelection(selection)) {
    return true
  }
  if (isNodeSelection(selection)) {
    const type = selection.node.type.name
    return ['variable'].includes(type)
  }
  if (isTextSelection(selection)) {
    return true
  }
}

export const BoldTool: ToolComponent = ({ editor, ...props }) => (
  <ToolbarButton
    {...props}
    active={editor?.isActive('bold')}
    disabled={!canEditorMarkText(editor, 'bold')}
    keyboardShortcut={keyboardShortcuts.bold}
    label='Bold'
    onClick={() => {
      editor.chain().focus().toggleBold().run()
    }}
  >
    <FontAwesomeIcon icon={['fas', 'bold']} />
  </ToolbarButton>
)

export const ClearFormatsTool: ToolComponent = ({ editor, ...props }) => (
  <ToolbarButton
    {...props}
    disabled={!canSelectionMarkText(editor?.state.selection)}
    label='Clear Formatting'
    onClick={() => {
      editor.chain().focus().clearFormats().run()
    }}
  >
    <FontAwesomeIcon icon={['fas', 'remove-format']} />
  </ToolbarButton>
)

export const LinkTool: ToolComponent = ({ editor, ...props }) => {
  const isActive = editor?.isActive('link')
  const canLink = hasExtension(editor, 'link') && editor?.can().showLinkEditor()
  return (
    <ToolbarButton
      {...props}
      active={isActive}
      disabled={!canLink}
      keyboardShortcut={keyboardShortcuts.link}
      label={isActive ? 'Edit Link' : 'Add Link'}
      onClick={() => {
        editor.commands.showLinkEditor()
      }}
    >
      <FontAwesomeIcon icon={['fas', 'link']} />
    </ToolbarButton>
  )
}

export const ItalicTool: ToolComponent = ({ editor, ...props }) => (
  <ToolbarButton
    {...props}
    active={editor?.isActive('italic')}
    disabled={!canEditorMarkText(editor, 'italic')}
    keyboardShortcut={keyboardShortcuts.italic}
    label='Italic'
    onClick={() => {
      editor.chain().focus().toggleItalic().run()
    }}
  >
    <FontAwesomeIcon icon={['fas', 'italic']} />
  </ToolbarButton>
)

export const UnderlineTool: ToolComponent = ({ editor, ...props }) => (
  <ToolbarButton
    {...props}
    active={editor?.isActive('underline')}
    disabled={!canEditorMarkText(editor, 'underline')}
    keyboardShortcut={keyboardShortcuts.underline}
    label='Underline'
    onClick={() => {
      editor.chain().focus().toggleUnderline().run()
    }}
  >
    <FontAwesomeIcon icon={['fas', 'underline']} />
  </ToolbarButton>
)
