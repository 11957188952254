import type { Editor } from '@blissbook/ui/editor'
import {
  HorizontalRuleNodeSelectionToolbar,
  ImageNodeSelectionToolbar,
  PdfNodeSelectionToolbar,
  ReadMoreNodeSelectionToolbar,
  VideoNodeSelectionToolbar,
} from '@blissbook/ui/editor/nodes'
import type { Attrs } from 'prosemirror-model'
import type { NodeSelection } from 'prosemirror-state'
import type React from 'react'
import type { NodeSelectionToolbarComponent } from './toolbar'

export type NodeSelectionViewProps = {
  editor: Editor
  selection: NodeSelection
}

const toolbarsByType: Record<string, NodeSelectionToolbarComponent> = {
  horizontalRule: HorizontalRuleNodeSelectionToolbar,
  image: ImageNodeSelectionToolbar,
  pdf: PdfNodeSelectionToolbar,
  readMore: ReadMoreNodeSelectionToolbar,
  video: VideoNodeSelectionToolbar,
}

export const NodeSelectionView: React.FC<NodeSelectionViewProps> = (props) => {
  const { editor, selection } = props
  const { node } = selection
  const NodeSelectionToolbar = toolbarsByType[node.type.name]
  if (!NodeSelectionToolbar) return null

  const { from, to } = selection
  return (
    <NodeSelectionToolbar
      {...props}
      selection={selection}
      removeNode={() => {
        editor.chain().focus().deleteRange({ from, to }).run()
      }}
      updateNodeAttributes={(attrs: Attrs) => {
        editor.chain().focus().updateNodeAttributes(from, attrs).run()
      }}
    />
  )
}
