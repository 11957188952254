import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import type React from 'react'
import { type ReactNode, forwardRef } from 'react'

export type BadgeProps = {
  children?: ReactNode
  className?: string
  icon?: IconProp
  onRemove?: () => void
  style?: React.CSSProperties
  title?: string
  variant?: string
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  ({ children, className, icon, onRemove, variant, ...props }, ref) => (
    <div
      {...props}
      className={classnames(
        'badge',
        onRemove && 'badge-removable',
        variant && `badge-${variant}`,
        className,
      )}
      onClick={() => {
        if (onRemove) onRemove()
      }}
      ref={ref}
    >
      {icon && <FontAwesomeIcon className='badge-icon' icon={icon} />}

      <span className='tw-flex-1 tw-min-0 ellipsis'>{children}</span>

      {onRemove && (
        <FontAwesomeIcon className='badge-remove-icon' icon='times-circle' />
      )}
    </div>
  ),
)
