const Wedge = ({ color, size, ...props }) => (
  <svg
    {...props}
    height={size}
    width={size}
    fill={color}
    viewBox='0 0 100 100'
    role='img'
    aria-label='wedge'
  >
    <path
      d={`
        M 0,0
        L 100,0
        A 100 100 0 0 1 0 100
      `}
    />
  </svg>
)

const strokeWidth = 3
const WedgeOutline = ({ color, size, ...props }) => (
  <svg
    {...props}
    height={size}
    width={size}
    stroke={color}
    viewBox={`0 0 ${size} ${size}`}
    role='presentation'
  >
    <path
      d={`
        M 0,${strokeWidth / 2}
        L ${size - strokeWidth / 2},${strokeWidth / 2}
        A ${size - strokeWidth / 2} ${size - strokeWidth / 2} 0 0 1 ${
          strokeWidth / 2
        } ${size - strokeWidth / 2}
        L ${strokeWidth / 2},0`}
      fill='none'
      strokeWidth={strokeWidth}
    />
  </svg>
)

export default ({ fill, ...props }) => {
  const Component = fill ? Wedge : WedgeOutline
  return <Component {...props} />
}
