import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import { useState } from 'react'
import { AlertIcon } from './AlertIcon'
import type { AlertType, AlertVariant } from './AlertType'

export type AlertProps = {
  canDismiss?: boolean
  children?: React.ReactNode
  className?: string
  title?: string
  type: AlertType
  variant: AlertVariant
}

export function Alert({
  canDismiss,
  children,
  className,
  title,
  type = 'warning',
  variant = 'small',
  ...props
}: AlertProps) {
  const [dismissed, setDismissed] = useState(false)
  if (dismissed) return null

  return (
    <div
      {...props}
      className={cx(`alert alert-${type} alert-${variant}`, className)}
    >
      <div
        className={
          variant === 'stacked'
            ? 'tw-flex tw-flex-col tw-items-center'
            : 'tw-flex'
        }
      >
        <AlertIcon type={type} />

        <div className={variant === 'stacked' ? 'tw-w-full' : 'tw-flex-1'}>
          {title && <div className='alert-title'>{title}</div>}

          {children && <div className='alert-body tw-mt-[3px]'>{children}</div>}
        </div>

        {canDismiss && (
          <button
            type='button'
            className='alert-dismiss'
            data-dismiss='alert'
            aria-label='Close'
            onClick={() => {
              setDismissed(true)
            }}
          >
            <FontAwesomeIcon icon='times' />
          </button>
        )}
      </div>
    </div>
  )
}
